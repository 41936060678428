import {CommonModule} from "@angular/common";
import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {SharedDirectivesModule} from "@atlas-workspace/shared/directives";
import {
  FloorDrawVersionModel,
  FloorModel,
  IFloorDrawToSave,
  ISettingsMenu,
  TempChangeRequestGroupModel,
  UnitFloorModel,
} from '@atlas-workspace/shared/models';
import { ConverterService, ModalFacadeService } from '@atlas-workspace/shared/service';
import {ImageMarkComponent, SimpleTabsComponent} from '@atlas-workspace/shared/ui';
import {NgbDropdown, NgbDropdownModule, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { of } from 'rxjs';
import { map, mergeMap, switchMap, take, toArray } from 'rxjs/operators';

import { DrawingModalComponent } from '../drawing-modal/drawing-modal.component';

@UntilDestroy()
@Component({
  selector: 'atl-plan-drawing',
  templateUrl: './drawing-mark.component.html',
  styleUrls: ['./drawing-mark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, SimpleTabsComponent, NgbDropdownModule, SharedDirectivesModule]
})
export class DrawingMarkComponent implements OnChanges, OnInit {
  @ViewChild('imageMark') imageMark!: ImageMarkComponent;
  @Input() floorPlan!: (UnitFloorModel | FloorModel)[];
  @Input() readonly disabled?: boolean;
  @Input() drawVersion = false;
  @Input() client = false;
  @Input() set floorDrawVersions(value: FloorDrawVersionModel[]) {
    this.floorDraw = value;
    if (this.floorDraw?.length) {
      this.tabListVersionInit();
    }
  }
  @Input() unitId!: number;
  @Input() displayFloors = false;
  @Input() savedDrawings: IFloorDrawToSave[] = [];
  @Input() isEditFlow = false;
  @Input() floorType!: string;
  @Input() previewPlan?: string;
  @Input() floorsBlocksDescription = 'Shared.Change_Request.Floor.Description';
  @Input() isAlternative = true;
  @Input() editClickPreview = false;
  @Input() description = '';
  @Input() isGroupCreationScope = false;
  @Input() isReadonlyFromGroupScope = false;
  @Input() set showOnlyWithFloorPlanIndex(i: number | null) {
    this.onlyWithFloorPlanIndex = i;
    if (this.onlyWithFloorPlanIndex !== null) {
      this.initNavList();
      this.activePreviewPlan();
    }
  }
  @Input() initialTempRequest?: TempChangeRequestGroupModel;
  @Input() activeIndex!: number;

  @Output() private readonly updateFloorPlanDraw = new EventEmitter<IFloorDrawToSave[]>();

  public onlyWithFloorPlanIndex: number | null = null;
  public readonly messageTooltip = 'Shared.Mark.Message.Unit_redirect';
  private readonly floorField = 'floorPlan';
  private floorDraw: FloorDrawVersionModel[] = [];
  public isDescriptionView = false;
  public loadImage = false;

  private modalRef!: NgbModalRef;
  private drawSaveData: IFloorDrawToSave[] = [];

  public hasUnitAccess!: boolean;
  @Input() activeId!: number;
  public navList!: Partial<ISettingsMenu[]>;
  public activeTabVersion!: string;
  @Input() activeVersion!: number;
  public lastVersion!: number;
  public tabListVersion!: Partial<ISettingsMenu[]>;
  public loading = true;
  public creator: { name: string; date: string } | null = null;
  public btnDisable = true;

  constructor(
    private modalFacadeService: ModalFacadeService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private readonly converterService: ConverterService
  ) {}

  ngOnInit(): void {
    this.tabListVersionInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.floorField in changes) {
      this.floorPlan = changes.floorPlan.currentValue;
      if (!this.floorPlan) {
        return;
      }
      if (this.floorPlan?.length) {
        this.convertToFile();
      }

      this.isDescriptionView = this.isPlanImage;

      if (changes.floorPlan && changes.floorPlan.currentValue && changes.floorPlan.currentValue.length) {
        const [floor] = changes.floorPlan.currentValue;
        if (floor && floor.plan) {
          this.previewPlan = floor.plan.fullImage.url || '';
        }
      }

      if (this.displayFloors) {
        this.initNavList();
      }
      this.loading = false;
      this.cdr.markForCheck();
    }
  }

  public loadingImage(): void {
    this.loadImage = false;
    this.cdr.detectChanges();
  }

  private convertToFile(): void {
    of(this.floorPlan)
      .pipe(
        take(1),
        map((floors) => floors?.filter((floor) => floor.plan)),
        switchMap((floor) => floor),
        mergeMap((floor) =>
          this.converterService.downloadFileFromAmazon(floor.id, this.unitId, floor.floorType).pipe(
            map((res: HttpResponse<Blob>) => {
              if (res.body) {
                const contentType = res.headers.get('content-type');
                if (contentType) {
                  const file = new File([res.body], 'filename.extension', { type: contentType });
                  floor.fileFullImageUrl = URL.createObjectURL(file);
                }
              }
            }),
            untilDestroyed(this)
          )
        ),
        toArray()
      )
      .subscribe(() => {
        this.btnDisable = false;
        this.cdr.markForCheck();
      });
  }

  private tabListVersionInit(): void {
    if (!this.drawVersion) return;
    const numbers = (this.floorDraw || []).map((item) => item.versionNumber);
    if (!numbers.length) this.lastVersion = this.drawVersion ? 1 : 0;
    const uniqueNumber = Array.from(new Set(numbers));
    if (uniqueNumber.length && !uniqueNumber.includes(1)) {
      uniqueNumber.unshift(1);
    }
    if (uniqueNumber.length) {
      this.tabListVersion = uniqueNumber.map<ISettingsMenu>((v) => ({
        id: v,
        name: `${this.translateService.instant('Shared.Entity.Version')} ${v}`,
        route: '',
        disabled: false,
      }));

      this.lastVersion = this.tabListVersion.length;
    } else {
      this.tabListVersion = [
        {
          id: 1,
          name: `${this.translateService.instant('Shared.Entity.Version')} ${1}`,
          route: '',
          disabled: false,
        },
      ];
    }

    if (this.tabListVersion?.length) {
      this.activeTabVersion = this.client
        ? this.tabListVersion[this.tabListVersion.length - 1]!.name
        : this.tabListVersion[0]!.name;
      this.activeVersion = this.client
        ? (this.tabListVersion[this.tabListVersion.length - 1]!.id as number)
        : (this.tabListVersion[0]!.id as number);
      this.activePreviewPlan();
    }
  }

  private get isPlanImage(): boolean {
    return !!this.floorPlan?.some((floor) => floor.plan);
  }

  private activePreviewPlan(): void {
    this.activeIndex = this.onlyWithFloorPlanIndex !== null
      ? this.onlyWithFloorPlanIndex
      : this.navList?.findIndex((x) => x?.id === this.activeId);
    if (this.activeIndex === undefined || this.activeIndex === -1) return;
    const navFloor = this.navList[this.activeIndex];
    const drawVersion = this.floorDraw?.filter(
      (draw) => draw.versionNumber === this.activeVersion && draw.floorNumber === navFloor?.id
    );

    if (drawVersion?.length) {
      if (this.previewPlan !== drawVersion[0].filename.url || drawVersion[0].filenameRemoteUrl) {
        this.loadImage = true;
      }
      this.previewPlan = drawVersion[0].filename.url || drawVersion[0].filenameRemoteUrl;
      this.creator = { name: drawVersion[0].creator.name, date: drawVersion[0].createdAt };
    } else {
      const url = this.floorPlan.find((floor) => floor.floorNumber === navFloor?.id)?.plan?.fullImage?.url;
      if (this.previewPlan !== url) {
        this.loadImage = true;
      }
      this.previewPlan = url;
      this.creator = null;
    }
    this.cdr.markForCheck();
  }

  public selectTab(event: { itemId: number }): void {
    this.activeId = event.itemId;
    this.activePreviewPlan();
  }

  public selectTabVersion(event: { tabName: string }): void {
    if (this.activeTabVersion === event.tabName) return;
    this.activeTabVersion = event.tabName;
    this.activeVersion = this.tabListVersion.find((item) => item?.name === event.tabName)!.id as number;
    this.activePreviewPlan();
  }

  public floorPlanVersionModal(dropdownRef: NgbDropdown, reuse = false): void {
    dropdownRef.close();
    this.modalRef = this.modalFacadeService.openModal(DrawingModalComponent, {
      centered: true,
      windowClass: 'full-screen-modal floor-plan-modal',
    });

    this.modalRef.componentInstance.previewPlan = this.previewPlan;
    this.modalRef.componentInstance.drawVersion = this.drawVersion;
    this.modalRef.componentInstance.activeIndex = this.onlyWithFloorPlanIndex !== null
      ? this.onlyWithFloorPlanIndex
      : (this.floorPlan[this.activeIndex] ? this.activeIndex : 0);
    this.modalRef.componentInstance.floorPlans = this.floorPlan;
    this.modalRef.componentInstance.floorPlan = this.floorPlan[this.activeIndex] || this.floorPlan[0];
    this.modalRef.componentInstance.isGroupCreationScope = this.isGroupCreationScope;
    this.modalRef.componentInstance.newVersion = this.lastVersion + 1;
    this.modalRef.componentInstance.showTab = true;
    this.modalRef.componentInstance.floorDrawVersions = this.floorDraw;
    this.modalRef.componentInstance.unitId = this.unitId;
    this.modalRef.componentInstance.savedDrawings = cloneDeep(this.savedDrawings);
    this.modalRef.componentInstance.isEditFlow = true;
    this.modalRef.componentInstance.floorType = this.floorType;
    this.modalRef.componentInstance.showMultiplePlans = true;
    this.modalRef.componentInstance.reuse = reuse;

    this.modalRef.result.then((data) => {
      if (data) {
        this.drawSaveData = data;
        this.updateFloorPlanDraw.emit(this.drawSaveData);
        this.activePreviewPlan();
      }
    });
  }

  public selectFloorPlanModal(previewPlan: string): void {
    if (!previewPlan) {
      return;
    }
    this.modalRef = this.modalFacadeService.openModal(DrawingModalComponent, {
      centered: true,
      windowClass: 'full-screen-modal floor-plan-modal',
      ...(this.client && {
        modalDialogClass: 'client'
      })
    });

    this.modalRef.componentInstance.previewPlan = this.isEditFlow
      ? this.floorPlan[this.activeIndex].fileFullImageUrl || this.floorPlan[0].fileFullImageUrl
      : this.previewPlan;
    this.modalRef.componentInstance.client = this.client;
    this.modalRef.componentInstance.activeIndex = this.activeIndex;
    this.modalRef.componentInstance.floorPlans = this.floorPlan;
    this.modalRef.componentInstance.floorPlan = this.floorPlan[this.activeIndex] || this.floorPlan[0];
    this.modalRef.componentInstance.newVersion = this.lastVersion + 1;
    this.modalRef.componentInstance.floorDrawVersions = this.floorDraw;
    this.modalRef.componentInstance.showTab = this.isEditFlow && this.onlyWithFloorPlanIndex === null;
    this.modalRef.componentInstance.unitId = this.unitId;
    this.modalRef.componentInstance.savedDrawings = cloneDeep(this.savedDrawings);
    this.modalRef.componentInstance.isEditFlow = this.editClickPreview;
    this.modalRef.componentInstance.floorType = this.floorType;
    this.modalRef.componentInstance.isGroupCreationScope = this.isGroupCreationScope;
    this.modalRef.componentInstance.onlyWithFloorPlanIndex = this.onlyWithFloorPlanIndex;

    this.modalRef.result.then((res: IFloorDrawToSave[]) => {
      if (res) {
        this.savedDrawings = res;
        this.updateFloorPlanDraw.emit(res);
        this.activePreviewPlan();
      }
    });
  }

  private initNavList(): void {
    this.activeIndex = this.onlyWithFloorPlanIndex === null ? 0 : this.onlyWithFloorPlanIndex;
    this.navList = this.floorPlan.map((p) => {
      return {
        id: p.floorNumber,
        name: p.name,
        route: '',
        disabled: false,
        hasPlan: !!p.plan,
      };
    });

    this.floorDraw?.forEach((floor) => {
      const isFloor = this.navList.some((p) => p?.id === floor.floorNumber);
      if (!isFloor) {
        this.navList.push({
          id: floor.floorNumber,
          name: floor.floorName,
          route: '',
          disabled: false,
        });
      }
    });

    // @ts-ignore
    this.navList.sort((a: ISettingsMenu, b: ISettingsMenu) => a?.id - b?.id);

    if (this.navList.length) {
      if (!this.activeId) {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.activeId = this.activeIndex ? this.navList[this.activeIndex]!.id : this.navList[0]!.id;
      }
      this.activePreviewPlan();
    }
  }
}
