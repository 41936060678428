import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  SharedVirtualScrollTableTbodyRowDirective,
  SharedVirtualScrollTableTheadRowDirective,
} from '@atlas-workspace/shared/directives';
import { SharedTableComponent } from '@atlas-workspace/shared/ui';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';

interface SharedVirtualScrollTableRowTemplateRefProps {
  item: any;
  index: number;
  last: boolean;
}

@Component({
  selector: 'atl-shared-virtual-scroll-table',
  templateUrl: './shared-virtual-scroll-table.component.html',
  styleUrls: ['./shared-virtual-scroll-table.component.scss', '../shared-table/shared-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedVirtualScrollTableComponent extends SharedTableComponent {
  public list: any[] = [];

  @Input() set items(value: any[]) {
    this.list = value;
    setTimeout(() => this.calcVirtualScrollerHeight());
  }
  @Input() bufferAmount = 5;
  @Input() compareItemsByKey = 'id';

  @Output() readonly vsEnd = new EventEmitter<void>();

  @ContentChild(SharedVirtualScrollTableTheadRowDirective, { read: TemplateRef, static: false })
  public readonly theadRowTemplate!: TemplateRef<void>;

  @ContentChild(SharedVirtualScrollTableTbodyRowDirective, { read: TemplateRef, static: false })
  public readonly tbodyRowTemplate!: TemplateRef<SharedVirtualScrollTableRowTemplateRefProps>;

  @ViewChild('table', { static: true }) tableElement!: ElementRef;
  @ViewChild('vsRef', { static: true, read: ElementRef }) virtualScrollerElement!: ElementRef;

  @HostListener('window:resize', ['$event']) resize(): void {
    this.calcVirtualScrollerHeight();
  }

  constructor(private hostElement: ElementRef<HTMLElement>) {
    super();
  }

  public compareItems(item1: any, item2: any): boolean {
    return item1[this.compareItemsByKey] === item2[this.compareItemsByKey];
  }

  public virtualScrollerEnd(props: IPageInfo): void {
    const isLast = props.endIndex + 1 === this.list.length;
    if (isLast) {
      this.vsEnd.emit();
    }
  }

  private calcVirtualScrollerHeight(): void {
    const hostScrollHeight = this.hostElement?.nativeElement?.clientHeight;
    const tableScrollHeight = this.tableElement?.nativeElement?.scrollHeight;
    const vsElement = this.virtualScrollerElement?.nativeElement;

    if (!hostScrollHeight || !tableScrollHeight || !vsElement) {
      return;
    }

    const height = tableScrollHeight < hostScrollHeight ? `${tableScrollHeight}px !important` : '100% !important';
    (<HTMLElement>vsElement).setAttribute('style', `height: ${height}`);
  }
}
