import {NgForOf} from "@angular/common";
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {HeaderIconComponent} from "@atlas-workspace/shared/ui";
import {NgbModalRef, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule, TranslateService} from "@ngx-translate/core";

import {getReportMessageOptions} from "./change-request-messages-report-modal.helper";

@Component({
  selector: 'atl-change-request-messages-report-modal',
  templateUrl: './change-request-messages-report-modal.component.html',
  styleUrls: ['./change-request-messages-report-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgForOf,
    TranslateModule,
    HeaderIconComponent,
    NgbTooltip,
  ]
})
export class ChangeRequestMessagesReportModalComponent {
  @Input() modalRef!: NgbModalRef;
  @Input() isCRUnitScope = false;
  @Input() descriptionIdentifier!: string;

  public messageOptions = getReportMessageOptions(this.translateService);
  public readonly tooltipDelay = 300;

  constructor(private readonly translateService: TranslateService) {}

  public selectOption(id: string): void {
    this.messageOptions = this.messageOptions.map(option => ({
      ...option,
      selected: option.id === id,
    }));
  }

  public closeModal(): void {
    this.modalRef.close(false);
  }

  public downloadPDF(): void {
    const selectedOption = this.messageOptions.find(option => option.selected);
    selectedOption && this.modalRef.close(selectedOption.id);
  }
}
