import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { EFirmRoles, ELocalStorageKeys } from '@atlas-workspace/shared/models';
import { AdminProfileService } from '@atlas-workspace/shared/service';
import { Observable, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class WhiteLabelGuard  {
  constructor(
    private profileService: AdminProfileService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const firmData = JSON.parse(localStorage.getItem(ELocalStorageKeys.Firm) || '')?.value;
    if (!firmData?.firmId) {
      this.redirect();
      return false;
    }

    return this.profileService.profile.pipe(
      switchMap(profile =>
        profile
          ? of(profile)
          : this.profileService.getUserProfile(firmData.firmId).pipe(
            switchMap(() => this.profileService.profile),
            catchError(() => {
              this.redirect();
              return of(null);
            })
          )
      ),
      map(profile => {
        const hasAccess = profile?.role === EFirmRoles.PrimaryOwner || profile?.role === EFirmRoles.Admin;
        if (!hasAccess) this.redirect();
        return hasAccess;
      })
    );
  }

  private redirect(): void {
    void this.router.navigate(['/base-layout/profile-settings/profile']);
  }
}
