import {CommonModule} from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  ClickOutsideDirective,
  DropdownTriggerForDirective,
  IsVisibleDirective,
  SharedDirectivesModule
} from '@atlas-workspace/shared/directives';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule, TranslateService} from '@ngx-translate/core';

import trashIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/delete-sm-thick.svg';
import additionalIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/floor_plan_header_edit.svg';
import pencilIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/pencil-icon-thick.svg';

import {CustomIconComponent} from '../custom-icon/custom-icon.component';
import {FormsModule} from "@angular/forms";
import { take } from 'rxjs';
import { isElementInViewport, isElementToLeft, isElementToRight } from '@atlas-workspace/shared/models';

interface IFloorAdditional {
  isEditMode: boolean;
  isOpenMenu: boolean;
  name: string;
  created: boolean;
}

@Component({
  selector: 'atl-floor-plan-header',
  templateUrl: './floor-plan-header.component.html',
  styleUrls: ['./floor-plan-header.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbTooltipModule, ClickOutsideDirective, CustomIconComponent, SharedDirectivesModule, FormsModule, IsVisibleDirective]
})
// eslint-disable-next-line @typescript-eslint/ban-types
export class FloorPlanHeaderComponent<T extends Object> {
  @ViewChild('tabsContainer') tabsContainer!: ElementRef;
  private readonly exceptionField = 'created';
  public readonly trashIcon = trashIcon;
  public readonly additionalIcon = additionalIcon;
  public readonly pencilIcon = pencilIcon;

  @ViewChildren(DropdownTriggerForDirective)
  set atlDropdownTriggerFor(value: QueryList<DropdownTriggerForDirective>) {
    if (value) {
      this.directives = value.toArray();
    }
  }

  get floors(): (T & IFloorAdditional)[] {
    return this._floors;
  }

  @Input() set floors(value: (T & IFloorAdditional)[]) {
    if (value) {
      this._floors = this.updateInfos(value);
    }
  }

  @Input() readonly maxCountFloor = 9;
  @Input() readonly selectedIndex = 0;
  @Input() readonly offsetBottom = {
    offsetX: -164,
    offsetY: -35,
  };
  @Input() disableEdit = false;
  @Input() canRemoveFloor = true;
  @Input() canAddNewFloor = true;
  @Input() showFloorMenu = true;
  @Input() canEditFloorName = true;
  @Input() readonly isUnitEdit = false;
  @Input() readonly isUnitTypeCreationScope = false;
  @Input() readonly maxNameLength = 30;

  @Output() private readonly addFloorHandler: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() private readonly removeFloorHandler: EventEmitter<number> = new EventEmitter<number>();
  @Output() private readonly selectFloorHandler: EventEmitter<T> = new EventEmitter<T>();
  @Output() private readonly editFloorHandler: EventEmitter<T> = new EventEmitter<T>();

  private _floors: (T | any)[] = [];
  private oldValue!: string;
  private directives: DropdownTriggerForDirective[] = [];

  public visibility = {
    firstCategory: true,
    lastCategory: true,
  };
  public lastScrolledIndex = -1;
  public disabledManualScroll = false;

  public readonly scrollItemClass = 'floor-plan-header__scroll-item';

  constructor(
    private readonly localization: TranslateService,
    private readonly ngZone: NgZone,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public onAddFloor(): void {
    this.addFloorHandler.emit();
  }

  setArrowsVisibility(val: boolean[]): void {
    const element = this.tabsContainer.nativeElement;
    if (!(element.scrollWidth > element.clientWidth)) return;
    if (val[0]) {
      this.visibility.firstCategory = val[1];
    } else {
      this.visibility.lastCategory = val[1];
    }
  }

  onScroll(): void {
    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
      if (this.visibility.firstCategory) {
        this.lastScrolledIndex = 0;
      }
      if (this.visibility.lastCategory) {
        this.lastScrolledIndex = this.floors.length - 1;
      }
      this.cdr.markForCheck();
    });
  }

  public scrollLeft(): void {
    const container = this.tabsContainer.nativeElement;
    const items = container.querySelectorAll(`.${this.scrollItemClass}`);

    if (this.lastScrolledIndex <= 0) this.lastScrolledIndex = 5;
    for (let i = this.lastScrolledIndex - 1; i >= 0; i--) {
      const item = items[i] as HTMLElement;
      if (!isElementInViewport(container, item) && isElementToLeft(container, item)) {
        const scrollPosition = item.offsetLeft - 50;
        container.scrollTo({ left: scrollPosition, behavior: 'smooth' });
        this.lastScrolledIndex = i;
        break;
      }
    }
  }

  public scrollRight(): void {
    const container = this.tabsContainer.nativeElement;
    const items = container.querySelectorAll(`.${this.scrollItemClass}`);

    for (let i = this.lastScrolledIndex + 1; i < items.length; i++) {
      const item = items[i] as HTMLElement;
      if (!isElementInViewport(container, item) && isElementToRight(container, item)) {
        const scrollPosition = item.offsetLeft + item.offsetWidth - container.offsetWidth + 50;
        container.scrollTo({ left: scrollPosition, behavior: 'smooth' });
        this.lastScrolledIndex = i;
        break;
      }
    }
  }

  public onRemoveFloor(floorIndex: number, event: MouseEvent): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();

    if (this.directives[floorIndex]) {
      this.directives[floorIndex].destroyDropdown();
    }

    this.removeFloorHandler.emit(floorIndex);
  }

  public onSelectFloor(floor: T): void {
    this.selectFloorHandler.emit(floor);
  }

  public onEditMode(index: number, event: MouseEvent): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();

    this.closeByIndex(index);

    this.oldValue = this.floors[index].name;
  }

  public closeMenu(index: number): void {
    this.floors[index].isOpenMenu = false;
  }

  public stopEditMode(index: number): void {
    if (this.floors[index].isEditMode) {
      this.onHandleEdit(index);
    }

    this.floors[index].isEditMode = false;
  }

  public onHandleEdit(index: number): void {
    if (!this.floors[index].name) {
      this.floors[index].name = this.oldValue;
    } else {
      if (this.oldValue !== this.floors[index].name) {
        this.editFloorHandler.emit(this.floors[index]);
      }
    }

    this.oldValue = '';
  }

  private closeByIndex(index: number): void {
    this.floors.forEach((target, idx: number) => (target.isEditMode = index === idx));
  }

  public onOpenState(index: number, value: boolean): void {
    this.floors[index].isOpenMenu = value;
  }

  protected updateInfos(source: T[]): (T & IFloorAdditional)[] {
    const floorWord = this.localization.instant(
      this.isUnitTypeCreationScope ? 'Entity.Floor_of_unit_group_creation' : 'Entity.Floor_plan_v2'
    );
    return source.map((target: T | any, index) => {
      return Object.assign(
        {},
        {
          ...target,
          isEditMode: false,
          isOpenMenu: false,
          created: this.isFieldExist(target) ? target.created : true,
          name: target.name
            ? target.name
            : `${floorWord} ${index + 1}`,
        }
      );
    });
  }

  public onSetValue(event: string, index: number): void {
    this.floors[index].name = event?.trim();
  }

  private isFieldExist(target: T): boolean {
    return this.exceptionField in target;
  }
}
