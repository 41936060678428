import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl } from '@angular/forms';
import {DateAdapterService, MessageModalComponent} from '@atlas-workspace/shared/form';
import {ModalHelpersService} from "@atlas-workspace/shared/modals";
import {
  acceptedGlobalExtensions,
  AdditionalCostsDetail,
  CreateCombinedOffer, EChangeRequestStatus,
  FileModel,
  ImageModel,
  IVersionList, SubOffersAttributes,
  UnitModel,
} from '@atlas-workspace/shared/models';
import { ChangeRequestService, ModalFacadeService, SharedUiStorageService } from '@atlas-workspace/shared/service';
import { NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {finalize, take} from 'rxjs/operators';

import { CombinePricingModalComponent } from '../combine-pricing-modal/combine-pricing-modal.component';

@UntilDestroy()
@Component({
  selector: 'atl-change-request-combine-offer',
  templateUrl: './change-request-combine-offer.component.html',
  styleUrls: ['./change-request-combine-offer.component.scss'],
  providers: [ChangeRequestService],
})
export class ChangeRequestCombineOfferComponent implements OnInit {
  @Input() public modalRef!: NgbModalRef;
  @Input() public projectId!: string;
  @Input() public unit!: UnitModel;
  @Input() public combine!: CreateCombinedOffer | null;
  @Input() public groupList: { id: number; title: string }[] = [];

  @Output() readonly updateGroupList = new EventEmitter();

  public title = '';
  public groupControl!: FormControl;

  public readonly mask = 'separator.0';
  public readonly separatorLimit = '100000000000';
  public readonly textMaxLength = 1000;
  public readonly acceptedExtensions = acceptedGlobalExtensions;
  public today: NgbDateStruct = this.dateAdapter.fromModel(this.dateAdapter.currentDate())!;
  public loading = false;

  private isDeclined = false;

  constructor(
    private readonly translateService: TranslateService,
    private readonly changeRequestService: ChangeRequestService,
    private readonly modalFacadeService: ModalFacadeService,
    private readonly sharedUiStorageService: SharedUiStorageService,
    private readonly dateAdapter: DateAdapterService,
    private readonly modalHelpersService: ModalHelpersService,
  ) {}

  ngOnInit(): void {
    this.title = `${this.translateService.instant('Shared.Change_request.Combine_Offer.Title')} ${this.unit.identifier}`;
    if (this.groupList.length > 1) {
      this.initGroupForm();
    }
  }

  public closeModal(): void {
    this.modalRef.dismiss();
  }

  private initGroupForm(): void {
    this.groupControl = new FormControl([]);
    this.groupControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.getGroupData(value);
      }
    });
  }

  private getGroupData(id: number): void {
    this.changeRequestService
      .getGroupChangeRequestsData(this.projectId, this.unit.id, id)
      .pipe(take(1))
      .subscribe((value) => {
        this.combine = new CreateCombinedOffer(id, value);
      });
  }

  public stopEvent(event: Event): void {
    event.stopPropagation();
  }

  public openAddCostModal(index = 0, item?: AdditionalCostsDetail): void {
    const modalRef = this.modalFacadeService.openModal(
      CombinePricingModalComponent,
      this.sharedUiStorageService.modalCreateOffer,
    );

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.title = 'Shared.Change_request.Combine_Offer.Add_costs';
    modalRef.componentInstance.setButton = item ? 'Shared.Title.Save_changes' : 'Shared.Button.Add';
    modalRef.componentInstance.priceLabel = 'Entity.Price';
    modalRef.componentInstance.mask = this.mask;
    if (item) {
      modalRef.componentInstance.name = item.name;
      modalRef.componentInstance.price = item.value;
    }

    modalRef.dismissed.pipe(take(1)).subscribe(({ name, price }) => {
      if (!name) return;
      if (item) {
        this.combine!.combinedOffer.pricingComponents.updateAdditionalCosts(name, +price.replace(/\s/g, ''), index);
        return;
      }
      this.combine!.combinedOffer.pricingComponents.addAdditionalCosts(name, +price.replace(/\s/g, ''));
    });
  }

  public editPriceSystemModal(vat: boolean, percent: number): void {
    const modalRef = this.modalFacadeService.openModal(
      CombinePricingModalComponent,
      this.sharedUiStorageService.modalCreateOffer,
    );

    const title = `${this.translateService.instant('Shared.Button.Edit')} ${this.translateService.instant(vat ? 'Auth.Create.Price.Mark-up.Input.VAT.Title' : 'Input.Mark-up')}`;
    const priceLabel = `${this.translateService.instant(vat ? 'Auth.Create.Price.Mark-up.Input.VAT.Title' : 'Input.Mark-up')} %`;
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.description = vat
      ? 'Shared.Change_request.Combine_Offer.VAT.Description'
      : 'Shared.Change_request.Combine_Offer.Mark-up.Description';
    modalRef.componentInstance.setButton = 'Button.Update';
    modalRef.componentInstance.price = percent;
    modalRef.componentInstance.priceOnly = true;
    modalRef.componentInstance.priceLabel = priceLabel;
    modalRef.componentInstance.separatorLimit = '1000';

    modalRef.dismissed.pipe(take(1)).subscribe(({ price }) => {
      if (!price) return;
      if (vat) {
        this.combine!.combinedOffer.pricingComponents.vat.value = +price.replace(' ', '');
      } else {
        this.combine!.combinedOffer.pricingComponents.markup.value = +price.replace(/\s/g, '');
      }
    });
  }

  public sendOffer(): void {
    this.loading = true;
    const body = new FormData();
    body.append('requests_group_id', this.combine!.requestsGroupId.toString());
    body.append(
      'combined_offer[expiration_date]',
      this.dateAdapter.toModelYYYYMMDD(this.combine!.combinedOffer.expirationDate as NgbDateStruct),
    );
    body.append(
      'combined_offer[description]',
      this.combine!.combinedOffer.offerDescription
    );
    if (this.combine!.combinedOffer.fileResourcesAttributes.length) {
      this.combine!.combinedOffer.fileResourcesAttributes.forEach((file) => {
        body.append('combined_offer[file_resources_attributes][][filename]', file);
      });
    }
    body.append(
      'combined_offer[pricing_components][vat][value]',
      String(this.combine!.combinedOffer.pricingComponents.vat.value)
    );
    body.append(
      'combined_offer[pricing_components][vat][include_in_total]',
      String(this.combine!.combinedOffer.pricingComponents.vat.includeInTotal),
    );

    body.append(
      'combined_offer[pricing_components][markup][value]',
      String(this.combine!.combinedOffer.pricingComponents.markup.value),
    );
    body.append(
      'combined_offer[pricing_components][markup][include_in_total]',
      String(this.combine!.combinedOffer.pricingComponents.markup.includeInTotal),
    );

    const calculationCost = this.combine!.combinedOffer.pricingComponents.calculationCost.includeInTotal
      ?  this.combine!.combinedOffer.pricingComponents.calculationCost.value
      : 0;
    body.append(
      'combined_offer[pricing_components][calculation_cost][value]',
      String(calculationCost),
    );
    body.append(
      'combined_offer[pricing_components][calculation_cost][include_in_total]',
      String(this.combine!.combinedOffer.pricingComponents.calculationCost.includeInTotal),
    );

    const administrationCost = this.combine!.combinedOffer.pricingComponents.administrationCost.includeInTotal
      ?  this.combine!.combinedOffer.pricingComponents.administrationCost.value
      : 0;
    body.append(
      'combined_offer[pricing_components][administration_cost][value]',
      String(administrationCost),
    );
    body.append(
      'combined_offer[pricing_components][administration_cost][include_in_total]',
      String(this.combine!.combinedOffer.pricingComponents.administrationCost.includeInTotal),
    );

    if (this.combine!.combinedOffer.pricingComponents.additionalCosts.length) {
      this.combine!.combinedOffer.pricingComponents.additionalCosts.forEach((item) => {
        body.append('combined_offer[pricing_components][additional_costs][][name]', String(item.name));
        body.append('combined_offer[pricing_components][additional_costs][][value]', String(item.value));
      });
    }

    this.combine!.combinedOffer.subOffersAttributes.filter(item => !item.declined).forEach((request) => {
      body.append('combined_offer[sub_offers_attributes][][change_request_id]', String(request.changeRequestId));
      body.append('combined_offer[sub_offers_attributes][][price]', String(request.price));
      body.append('combined_offer[sub_offers_attributes][][description]', String(request.description));
      body.append('combined_offer[sub_offers_attributes][][version_number]', String(request.versionNumber));
      if (request.fileResourcesAttributes.length) {
        request.fileResourcesAttributes.forEach((file) => {
          if ((file as (FileModel | ImageModel))?.id) {
            body.append('combined_offer[sub_offers_attributes][][file_resources_attributes][][prefill_file_id]', String((file as (FileModel | ImageModel))?.id));
            return;
          }
          body.append('combined_offer[sub_offers_attributes][][file_resources_attributes][][filename]', file as File);
        });
      }
    });

    this.changeRequestService
      .createGroupOffer(this.projectId, body)
      .pipe(take(1), finalize(() => this.loading = false))
      .subscribe((value) => {
        this.modalRef.dismiss(value);
      });
  }

  public previewFloorPlanVersion(e: MouseEvent, versionData: IVersionList): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();

    if (versionData.url) {
      const title = `${this.translateService.instant('Shared.Entity.Version')} ${versionData.version}`;
      this.modalHelpersService.previewImageByUrl(versionData.url, title);
    }
  }

  public showComment(comment: string): void {
    const modalRef = this.modalFacadeService.openModal(
      MessageModalComponent,
      this.sharedUiStorageService.modalCreateOffer,
    );

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.title = 'Shared.Change_request.Combine_Offer.Customer_description';
    modalRef.componentInstance.fieldTitle = 'Shared.Change_request.Combine_Offer.Customer_description';
    modalRef.componentInstance.comment = comment;
    modalRef.componentInstance.onlyView = true;
  }

  public declineModal(data: SubOffersAttributes): void {
    const modalRef = this.modalFacadeService.openModal(
      MessageModalComponent,
      this.sharedUiStorageService.modalCreateOffer,
    );

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.title = 'Title.Change_request_decline';
    modalRef.componentInstance.button = 'Shared.Yes_decline';
    modalRef.componentInstance.placeholder = 'Change_request.Decline.Modal.Placeholder';

    modalRef.result.then((res) => {
      if (res !== undefined) {
        this.changeStatusChangeRequest(data, res);
      }
    });
  }

  private changeStatusChangeRequest(data: SubOffersAttributes, comment = ''): void {
    this.changeRequestService
      .setStatusChangeRequest(this.projectId, data.changeRequestId, EChangeRequestStatus.RequestDeclined, comment)
      .pipe(
        take(1),
      )
      .subscribe(() => {
        data.declined = true;
        this.isDeclined = true;
        this.updateGroupList.emit();
        if (this.groupList.length === 1 && this.combine!.combinedOffer.subOffersAttributes.length === 1) {
          this.closeModal();
        }
      });
  }
}
