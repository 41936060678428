import {ChangeRequestReportPdfMessagesType} from "@atlas-workspace/shared/models";
import {TranslateService} from "@ngx-translate/core";

export const getReportMessageOptions = (trs: TranslateService) => ([
  {
    id: ChangeRequestReportPdfMessagesType.All,
    label: trs.instant('Shared.Title.All_messages'),
    selected: true
  },
  {
    id: ChangeRequestReportPdfMessagesType.CustomerCommunication,
    label: trs.instant('Title.Customer_communication'),
    selected: false
  },
  {
    id: ChangeRequestReportPdfMessagesType.InternalNotes,
    label: trs.instant('Reclamation.Tab.Discussion'),
    selected: false
  }
]);
