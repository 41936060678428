<!--
  ******************************************************
  @Cypress
  - interaction with toogle dropdown menu
  - interaction with search (e.g. fill, clear)
  - interaction with dropdown items (e.g. click, check)
  - interaction with Clear All button
  ******************************************************
-->
<atl-default-dropdown>
  <div
    #dropdownRef="ngbDropdown"
    ngbDropdown
    [placement]="placement"
    [container]="container"
    [dropdownClass]="dropdownClass"
    [autoClose]="'outside'"
    (openChange)="openChange($event)"
  >
    <div
      *ngIf="customFilterChipTemplate; else defaultChip"
      ngbDropdownToggle
      class="cursor-pointer"
      [class.pointer-events-none]="disabled"
    >
      <ng-container *ngTemplateOutlet="customFilterChipTemplate"></ng-container>
    </div>
    <ng-template #defaultChip>
      <atl-filter-chip
        ngbDropdownToggle
        [title]="buttonText"
        [selected]="isDropdownOpen || !!selectedItemIds?.length"
        [disabled]="disabled"
        [useRoundedCount]="true"
        [useDropdownIcon]="true"
        [isDropdownOpen]="isDropdownOpen"
        [count]="selectedItemIds.length"
        [style.pointer-events]="disabled ? 'none' : 'auto'"
      ></atl-filter-chip>
    </ng-template>

    <div class="dropdown-menu" aria-labelledby="dropdownButton" ngbDropdownMenu>
      <ng-container *ngIf="tabs && tabs.length > 1; else defaultHeaderTitleRef">
        <atl-simple-tabs
          class="newest-tabs"
          [activeTab]="activeTab"
          [tabs]="tabs"
          [routeNavigation]="false"
          (selectingTab)="onTabSelected($event)"
        ></atl-simple-tabs>
      </ng-container>
      <ng-template #defaultHeaderTitleRef>
        <p class="fz-14 lh-24 text-black-810 label">{{ buttonText }}</p>
      </ng-template>

      <ng-container *ngIf="showSearch">
        <form class="search-form" [formGroup]="form">
          <div class="position-relative d-inline-block">
            <img src="/assets/search.svg" alt="icon" class="search-icon" />
            <input
              class="search-input"
              formControlName="search"
              [placeholder]="'Input.Search.Placeholder_without_dots' | translate"
            />
          </div>

          <img
            (click)="clearSearch()"
            *ngIf="form.controls.search.value.length"
            class="clear-search"
            src="/assets/newest_remove_text_gray.svg"
            alt="icon"
          />
        </form>
      </ng-container>
      <div class="dropdown-content v-scrollbar" [class.dropdown-content-empty]="!filteredItems?.length && !searchQuery">
        <ng-container *ngIf="showAllCheckbox && filteredItems?.length">
          <div
            class="dropdown-item d-flex align-items-center cursor-pointer fw-400"
            (click)="toggleItem(!allCheckbox.value, allCheckboxItem)"
          >
            <atl-titled-checkbox
              #allCheckbox
              class="ml-8 mr-8 pointer-events-none"
              [value]="allCheckboxItem.checked || !!selectedItemIds.length"
              [checkedPartially]="!!selectedItemIds.length && !allCheckboxItem.checked"
            ></atl-titled-checkbox>
            <label class="ellipsis w-100 cursor-pointer fz-14 lh-24">
              <span class="ellipsis">{{ allCheckboxItem[key] | replaceunderscore | upperCaseFirst }}</span>
            </label>
          </div>
        </ng-container>

        <ng-container *ngFor="let item of filteredItems; let last = last">
          <div
            *ngIf="!item?.hide"
            class="dropdown-item d-flex align-items-center cursor-pointer fw-400"
            [attr.data-cy-id]="cyKey ? item[cyKey] : null"
            [class.has-subtitle]="showSubtitle && item[showSubtitleForProp]"
            (click)="toggleItem(!itemCheckbox.value, item)"
            (atlInfiniteScroll)="askNextPage(last)"
          >
            <atl-titled-checkbox
              #itemCheckbox
              class="ml-8 mr-8"
              [value]="item.checked"
              (changeEvent)="toggleItem($event, item)"
              (click)="$event.stopPropagation()"
            ></atl-titled-checkbox>
            <atl-firm-icon
              *ngIf="
              (showUserIcon && item.id !== 'none' && !showUserIconForProp) ||
              (showUserIcon && showUserIconForProp && item[showUserIconForProp] === showUserIconWhenPropEq)
            "
              class="mr-8"
              [model]="item"
              [isUser]="true"
              [nameKey]="'name'"
            ></atl-firm-icon>
            <ng-template [ngIf]="item.id !== 'none' && showSubtitle && showSubtitleForProp" [ngIfElse]="baseItemRef">
              <label class="ellipsis w-100 cursor-pointer fz-14 lh-24">
                <div class="d-flex">
                  <span class="ellipsis">{{ item[key] | replaceunderscore | upperCaseFirst }}</span>
                  <span *ngIf="showCategory && item.id !== 'none'" class="ml-4 text-black-810"
                  >({{ item.category?.name }})</span
                  >
                  <span *ngIf="showCounter && item.id !== 'none'" class="ml-4 text-black-810">({{ item.count }})</span>
                  <span *ngIf="showCustomField" class="text-black-810">{{ dotDivider + ' ' + item[customField] }}</span>
                </div>
                <div class="dropdown-item-subtitle fz-12 lh-16 fw-400 ellipsis">{{ item[showSubtitleForProp] }}</div>
              </label>
            </ng-template>
            <ng-template #baseItemRef>
              <label class="d-flex w-100 cursor-pointer fz-14 lh-24">
                <span class="d-block ellipsis">{{ item[key] | replaceunderscore | upperCaseFirst }}</span>
                <span *ngIf="showCategory && item.id !== 'none'" class="ml-4 text-black-810"
                >({{ item.category?.name }})</span
                >
                <span *ngIf="showCounter && item.id !== 'none'" class="ml-4 text-black-810">({{ item.count }})</span>
                <span *ngIf="showCustomField" class="text-black-810">{{ dotDivider + ' ' + item[customField] }}</span>
              </label>
            </ng-template>
          </div>
        </ng-container>
      </div>

      <div class="dropdown-footer d-flex align-items-center" *ngIf="!noFooter && filteredItems.length">
        <p (click)="resetFilters()" class="cursor-pointer fz-14 lh-24 reset-button">
          {{ 'Shared.Entity.Clear_all' | translate }}
        </p>
      </div>

      <div *ngIf="searchQuery && !filteredItems.length" class="mt-8 mb-8 ml-16">
        <span class="fz-14 lh-24"> {{ 'Entity.No_matches_for' | translate }} "{{ searchQuery | truncate: 10 }}" </span>
      </div>
      <div *ngIf="!searchQuery && !filteredItems.length" class="mt-8 mb-8 ml-16">
        <span class="fz-14 lh-24 text-black-810"> {{ 'Shared.Title.Nothing_is_found' | translate }}</span>
      </div>
    </div>
  </div>
</atl-default-dropdown>
