import {Injectable} from "@angular/core";
import {ImageCropModalComponent} from "@atlas-workspace/shared/form";
import {
  ICroppingData,
  imageJpegMime,
  imageJpgMime,
  imagePngMime,
  pdfMime,
  PositionedFile
} from "@atlas-workspace/shared/models";
import {extractFirstPdfPageAsImage, ModalFacadeService} from "@atlas-workspace/shared/service";

@Injectable({
  providedIn: 'root',
})
export class CroppingImageHelper {
  private readonly acceptableImageMimes: string[] = [imageJpegMime, imageJpgMime, imagePngMime];

  constructor(private modalFacadeService: ModalFacadeService) {}

  public crop(file: PositionedFile, successCb: (file: PositionedFile) => void, rejectCb?: () => void): void {
    if (file.type === pdfMime) {
      void extractFirstPdfPageAsImage(file).then((img) => {
        this.openCropModalWrapper(img, successCb);
      });
    } else if(this.acceptableImageMimes.includes(file.type)) {
      this.openCropModalWrapper(file, successCb);
    } else {
      rejectCb && rejectCb();
    }
  }

  private openCropModalWrapper(file: PositionedFile, successCb: (file: PositionedFile) => void): void {
    this.openCropModal(file).then(
      () => null,
      (e) => this.cropModalResultHandler(e, file, successCb),
    );
  }

  private async openCropModal(file: PositionedFile | File): Promise<File> {
    const modalRef = this.modalFacadeService.openModal(ImageCropModalComponent, {
      modalDialogClass: 'wider-modal',
      windowClass: 'custom-modal-main centered-modal-crop-image',
    });
    const componentInstance = <ImageCropModalComponent>modalRef.componentInstance;
    componentInstance.originalImage = file;
    componentInstance.maintainAspectRatio = false;
    componentInstance.event = new Event('open');
    componentInstance.cropperPosition = undefined;
    componentInstance.newConfirmBtnPosition = true;
    componentInstance.confirmBtnText = 'Title.Save_changes';

    return modalRef.result;
  }

  private cropModalResultHandler(
    e: ICroppingData | unknown,
    file: PositionedFile,
    successCb: (file: PositionedFile) => void
  ): void {
    if (e === 'close' || e === 0 || e === 1) {
      return;
    }
    const { image } = <ICroppingData>e;
    if (!image) {
      return;
    }
    const croppedFile = new File([image], file.name, { type: file.type });
    successCb(<PositionedFile>croppedFile);
  }
}
