/* eslint-disable */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FloorDrawVersionModel,
  FloorModel,
  IFloorDrawToSave,
  IKonvaObject,
  ISettingsMenu,
  KonvaObject,
} from '@atlas-workspace/shared/models';
import { ConverterService, KonvaService } from '@atlas-workspace/shared/service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import html2canvas from 'html2canvas';
import { Layer } from 'konva/lib/Layer';
import { Arrow } from 'konva/lib/shapes/Arrow';
import { Circle } from 'konva/lib/shapes/Circle';
import { Line } from 'konva/lib/shapes/Line';
import { Rect } from 'konva/lib/shapes/Rect';
import { Stage } from 'konva/lib/Stage';
import { Subject } from 'rxjs';
import { debounceTime, finalize, switchMap, take, tap } from 'rxjs/operators';

enum EKonvaButton {
  Line = 'line',
  Eraser = 'eraser',
  Arrow = 'arrow',
  Circle = 'circle',
  Square = 'rect',
  Pencil = 'brush',
}

@UntilDestroy()
@Component({
  selector: 'atl-drawing-modal',
  templateUrl: './drawing-modal.component.html',
  styleUrls: ['./drawing-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [KonvaService],
})
export class DrawingModalComponent implements OnInit {
  @Input() client = false;
  @Input() showTab = false;
  @Input() previewPlan!: string;
  @Input() activeIndex: number | undefined;
  @Input() readonly drawVersion = false;
  @Input() readonly floorPlans!: FloorModel[];
  @Input() floorPlan!: FloorModel;
  @Input() readonly floorDrawVersions!: FloorDrawVersionModel[];
  @Input() private readonly floorType!: string;
  @Input() private readonly unitId!: number;
  @Input() private readonly showMultiplePlans = false;
  @Input() public isEditFlow = false;
  @Input() savedDrawings: IFloorDrawToSave[] = [];
  @Input() private readonly newVersion!: number;
  @Input() reuse = false;
  @Input() isGroupCreationScope = false;
  @Input() onlyWithFloorPlanIndex: number | null = null

  @Output() private readonly updateFloorPlanDraw = new EventEmitter<IFloorDrawToSave[]>();

  public newVersionTitle = 'Shared.Entity.New_version';
  public newAlternativeTitle = 'Shared.Entity.New_alternative';
  public readonly controlButton = EKonvaButton;
  public readonly selectedButton: Record<string, boolean> = {
    [this.controlButton.Line]: false,
    [this.controlButton.Eraser]: false,
    [this.controlButton.Arrow]: false,
    [this.controlButton.Circle]: false,
    [this.controlButton.Square]: false,
    [this.controlButton.Pencil]: false,
  };
  public activeButton = '';
  public activeTab!: string;
  public navList!: ISettingsMenu[];
  public loading = false;
  public loader = false;

  private floorDrawings: { [key: string]: IKonvaObject[] } = {};
  public shapes: any = [];
  private removedShapes: any = [];
  private layer!: Layer;
  private stage!: Stage;
  private isDrawing = false;

  private readonly inkColor = '#D92C26';
  private readonly container = 'konva-container';
  private readonly transformers: Transformer[] = [];
  private readonly resizeSubject$ = new Subject<void>();
  private readonly resizeDebounceTime = 300;
  public readonly tooltipDelay = 400;
  private isMobile!: boolean;

  @ViewChild('konvaContainer', { static: true }) konvaContainer!: ElementRef;

  @HostListener('window:resize')
  onWindowResize() {
    this.resizeSubject$.next();
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if ((event.key === 'z' || event.key === 'Z') && (event.metaKey || event.ctrlKey)) {
      this.undo();
    }
  }

  constructor(
    private readonly konvaService: KonvaService,
    private readonly activeModal: NgbActiveModal,
    private readonly cdr: ChangeDetectorRef,
    private readonly converterService: ConverterService,
  ) {}

  ngOnInit(): void {
    if (this.isEditFlow) {
      this.loadInitialData();
      return;
    }

    this.drawing();
  }

  private loadInitialData(): void {
    this.initNavList();
    const initMultiplePlans = this.floorPlans?.length >= 1 && this.showMultiplePlans;
    if (initMultiplePlans) {
      if (this.activeIndex !== undefined) {
        this.previewPlan =
          this.floorPlans[this.activeIndex]?.fileFullImageUrl || (this.floorPlans[0]?.fileFullImageUrl as string);
      }
    } else {
      // ToDO: load drawings for the edit flow in v2
      //  this.loadSavedDrawings();
    }

    this.drawing();
  }

  private drawing(): void {
    this.initDrawing();
    this.resizeSubject$.pipe(untilDestroyed(this), debounceTime(this.resizeDebounceTime)).subscribe(() => {
      this.initDrawing();
    });
  }

  private initNavList(): void {
    let activeTabIndex = this.activeIndex ?? 0;
    this.navList = this.floorPlans
      .filter((p) => p.plan)
      .map((p) => {
        return {
          name: p.name,
          route: '',
          disabled: false,
          hasPlan: !!p.plan,
        };
      });
    if (this.activeIndex !== undefined) {
      this.activeTab = this.navList[this.activeIndex]?.name
        ? this.navList[this.activeIndex].name
        : this.navList[0].name;
      return;
    }
    this.activeTab = this.navList[activeTabIndex].name;
  }

  public selectTab(event: { tabName: string }, element: HTMLElement): void {
    if (this.loader) return;
    this.loader = true;
    const previousTab = this.activeTab;
    const activeTab = event.tabName;
    this.setSelection(this.activeButton);
    this.activeIndex = this.floorPlans.findIndex((x) => x.name === activeTab);
    if (this.activeIndex !== -1) {
      this.saveCurrentDrawings(previousTab);
      this.saveData(element, false, this.floorPlan.id, previousTab, activeTab);
    }
  }

  private saveCurrentDrawings(previousTab: string): void {
    if (previousTab && this.layer) {
      const currentDrawings = this.layer.getChildren();
      this.floorDrawings[previousTab] = currentDrawings.map((drawing: any) => drawing.toObject());
    }
  }

  private loadSavedDrawings(): void {
    this.layer.destroyChildren();
    const drawings = this.floorDrawings[this.activeTab] || [];
    drawings.forEach((drawing: IKonvaObject) => {
      const shape = this.konvaService.createShapeFromData(drawing);
      shape?.draggable(true);
      this.layer.add(shape as any);
    });
    this.layer.batchDraw();
  }

  private initDrawing(): void {
    const isMobile = window.innerWidth <= 743;
    const widthSpace = isMobile ? 0 : 300;
    const backgroundImage = new Image();
    backgroundImage.src = this.previewPlan;

    backgroundImage.onload = () => {
      const imageWidth = backgroundImage.width;
      const imageHeight = backgroundImage.height;
      const maxCanvasWidth = window.innerWidth - widthSpace;
      const containerHeight = this.konvaContainer.nativeElement.parentElement.offsetHeight;
      const containerWidth = this.konvaContainer.nativeElement.parentElement.offsetWidth;
      let canvasWidth = Math.min(imageWidth, maxCanvasWidth);
      let canvasHeight = Math.min(imageHeight, containerHeight);

      const imageAspectRatio = backgroundImage.naturalWidth / backgroundImage.naturalHeight;
      const containerAspectRatio = containerWidth / containerHeight;

      if (imageAspectRatio > containerAspectRatio) {
        canvasHeight = canvasWidth / imageAspectRatio;
      } else {
        canvasWidth = canvasHeight * imageAspectRatio;
      }

      this.konvaContainer.nativeElement.style.width = `${canvasWidth}px`;
      this.konvaContainer.nativeElement.style.height = `${canvasHeight}px`;

      if (this.stage) {
        this.stage.width(canvasWidth);
        this.stage.height(canvasHeight);
        this.layer.width(canvasWidth);
        this.layer.height(canvasHeight);
        this.shapes = [];
        this.applyCreationDrawings();
        this.stage.batchDraw();
      } else {
        this.stage = new Stage({
          container: this.container,
          width: canvasWidth,
          height: canvasHeight,
        });
        this.layer = new Layer();
        this.stage.add(this.layer);
        this.addLineListeners();
        this.applyCreationDrawings();
        this.loading = false;
        this.cdr.markForCheck();
      }
    };
  }

  private applyCreationDrawings(): void {
    this.clearBoard();
    const savedDrawing = this.savedDrawings.find((drawing: IFloorDrawToSave) => drawing.floor_id === this.floorPlan.id);
    if (savedDrawing && this.layer) {
      const shapes = this.createShapesFromData(savedDrawing.draw_data);
      if (shapes) {
        shapes.forEach((shape) => {
          this.shapes.push(shape);
          this.layer.add(shape);
        });
        this.setSelection(this.activeButton);
        this.stage.batchDraw();
        this.cdr.markForCheck();
      }
    }
  }

  private createShapesFromData(data: KonvaObject | any): any[] | undefined {
    if (data.className === 'Stage') {
      const layerData = data.children.find((child: any) => child.className === 'Layer');
      if (layerData) {
        return layerData.children.map((shapeData: any) => this.createShapeFromData(shapeData));
      }
    } else {
      switch (data.className) {
        case 'Arrow':
          return [new Arrow(data.attrs)];
        case 'Line':
          return [new Line(data.attrs)];
        case 'Rect':
          return [new Rect(data.attrs)];
        case 'Circle':
          return [new Circle(data.attrs)];
        default:
          return [];
      }
    }
  }

  private createShapeFromData(data: any, stage?: Stage, layer?: Layer): Layer | Line<any> | Rect | Circle | any {
    if (data.className === 'Stage') {
      const layerData = data.children.find((child: any) => child.className === 'Layer');
      if (layerData) {
        const shapes = layerData.children.map((shapeData: any) => this.createShapeFromData(shapeData, stage, layer));
        if (shapes.length > 0) {
          const targetStage = stage || new Stage(data.attrs);
          const targetLayer = layer || new Layer();
          shapes.forEach((shape: any) => targetLayer.add(shape));
          targetStage.add(targetLayer);
          return targetLayer;
        }
      }
    } else {
      switch (data.className) {
        case 'Arrow':
          return new Arrow(data.attrs);
        case 'Line':
          return new Line(data.attrs);
        case 'Rect':
          return new Rect(data.attrs);
        case 'Circle':
          return new Circle(data.attrs);
        default:
          return null;
      }
    }
  }

  public closeModal(data?: IFloorDrawToSave[]): void {
    this.activeModal.close(data);
  }

  saveDraw(element: HTMLElement): void {
    this.saveCurrentDrawings(this.activeTab);
    this.saveData(element, true, this.floorPlan.id, this.activeTab);
  }

  private drawingNewTab(activeTab?: string): void {
    if (activeTab) {
      this.activeTab = activeTab;
      if (this.activeIndex !== undefined) {
        this.previewPlan = this.floorPlans[this.activeIndex].fileFullImageUrl || '';
        this.floorPlan = this.floorPlans[this.activeIndex];
        this.loadSavedDrawings();
        this.layer.draw();
        this.initDrawing();
        this.loader = false;
        this.removedShapes = [];
      }
    }
  }

  public saveData(element: HTMLElement, close = false, floorId: number, tab: string, activeTab?: string): void {
    if (!this.isEditFlow) {
      return;
    }
    const drawData = this.stage.toObject();
    if (close) {
      this.loading = true;
    }

    const currentDrawings = this.floorDrawings[tab];
    if (!currentDrawings?.length) {
      if (close) {
        this.closeModal(this.savedDrawings);
      }

      this.drawingNewTab(activeTab);
      return;
    }
    let screenshotLink: string;
    html2canvas(element, { useCORS: true }).then((canvas: HTMLCanvasElement) => {
      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          const file = new File([blob], 'screenshot.png', { type: blob.type });
          this.converterService
            .generateFilenameUrl(file)
            .pipe(
              take(1),
              switchMap((res) => {
                const uploadUrl = res.uploadUrl;
                return this.converterService.uploadFileToS3(uploadUrl, file).pipe(
                  take(1),
                  tap(() => {
                    screenshotLink = res.publicUrl;
                  }),
                  finalize(() => {
                    if (close) {
                      this.loading = true;
                    }
                  }),
                );
              }),
            )
            .subscribe(
              () => {
                const idx = this.savedDrawings.findIndex((data) => data.floor_id === floorId);
                const version = this.isGroupCreationScope ? (this.savedDrawings.length) : this.newVersion;
                const floorToSave: IFloorDrawToSave = {
                  floor_id: floorId,
                  floor_type: this.floorType,
                  filename_remote_url: screenshotLink,
                  draw_data: drawData,
                  version_number: version,
                  ... (idx !== -1 && this.isGroupCreationScope && {
                    id: this.savedDrawings[idx].id,
                  })
                };
                if (idx !== -1) {
                  this.savedDrawings[idx] = floorToSave;
                } else {
                  this.savedDrawings.push(floorToSave);
                }

                if (close) {
                  this.closeModal(this.savedDrawings);
                }
                this.drawingNewTab(activeTab);
                this.cdr.detectChanges();
              },
              (error) => {
                // ToDO: remove it. Temporaty to debug possible errors.
                console.error('Upload error:', error);
              },
            );
        }
      });
    });
  }

  public reuseDrawData(version: number): void {
    this.savedDrawings = this.floorDrawVersions
      .filter((data) => {
        return data.versionNumber === version && this.floorPlans.some((f) => f.id === data.floor?.id);
      })
      .map((data) => {
        return {
          floor_id: data.floor?.id,
          floor_type: this.floorType,
          draw_data: data.drawData,
          version_number: this.newVersion,
          filename_remote_url: '',
        } as IFloorDrawToSave;
      });

    this.reuse = false;
    this.initDrawing();
  }

  public clearSelection(button: string): void {
    Object.keys(this.selectedButton).map((key) => {
      if (key === button) return;
      this.selectedButton[key] = false;
    });
  }

  public setSelection(key: EKonvaButton | string): void {
    this.clearSelection(key);
    this.selectedButton[key] = !this.selectedButton[key];

    if (this.activeButton === key) {
      this.activeButton = '';
    } else {
      this.activeButton = key;
    }

    const isDrawingMode = this.activeButton === '';
    this.konvaService.draggable = isDrawingMode;
    this.shapes.forEach((shape: any) => {
      shape.draggable(isDrawingMode);
    });
  }

  public clickOutside(): void {
    this.isDrawing = false;
  }

  public undo(): void {
    const removedShape = this.shapes.pop();
    this.removedShapes.push(removedShape);
    this.transformers.forEach((t: any) => {
      t.detach();
    });

    if (removedShape) {
      removedShape.remove();
      const currentDrawings = this.floorDrawings[this.activeTab];
      if (currentDrawings?.length) {
        const lastIndex = currentDrawings?.length - 1;
        currentDrawings.splice(lastIndex, 1);
      }
    }
    this.layer.draw();
  }

  public redo(): void {
    const restoredShape = this.removedShapes.pop();
    if (restoredShape) {
      this.shapes.push(restoredShape);
      this.layer.add(restoredShape);
      const currentDrawings = this.floorDrawings[this.activeTab];
      if (currentDrawings) {
        currentDrawings.push(restoredShape);
      }
      this.layer.draw();
    }
  }

  public clearBoard(): void {
    this.layer.destroyChildren();
    this.layer.draw();
  }

  private addLineListeners(): void {
    const component = this;
    let lastLine: any;
    let isDragging = false;

    this.stage.on('mousedown touchstart', () => {
      if (!component.activeButton || component.loading) {
        return;
      }
      component.isDrawing = true;
      this.removedShapes = [];
      const pos = component.stage.getPointerPosition();
      Object.keys(component.selectedButton).map((key) => {
        if (component.selectedButton[key]) {
          // @ts-ignore
          lastLine = component.konvaService[key](pos, component.brushSize, component.inkColor, component.brushOpacity);
        }
      });

      component.shapes.push(lastLine);
      component.layer.add(lastLine);
    });

    this.stage.on('mouseup touchend', () => {
      component.isDrawing = false;
      isDragging = false;
      this.cdr.markForCheck();
    });

    this.stage.on('mousemove touchmove', (e) => {
      if (!component.isDrawing || !component.activeButton) {
        return;
      }
      e.evt.preventDefault();
      const position: any = component.stage.getPointerPosition();
      const attr = lastLine.getAttrs();

      switch (component.activeButton) {
        case component.controlButton.Square:
          const width = position.x - attr.x;
          const height = position.y - attr.y;
          lastLine.width(width);
          lastLine.height(height);
          break;
        case component.controlButton.Circle:
          const offsetX = attr.x - position.x;
          const offsetY = attr.y - position.y;
          const radius = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2)) / 2;
          lastLine.radius(radius);
          lastLine.offsetX(offsetX / 2);
          lastLine.offsetY(offsetY / 2);
          break;
        case component.controlButton.Line:
          const point = lastLine.points();
          point[2] = position.x;
          point[3] = position.y;
          lastLine.points(point);
          break;
        case component.controlButton.Arrow:
          const pointArrow = lastLine.points();
          pointArrow[2] = position.x;
          pointArrow[3] = position.y;
          lastLine.points(pointArrow);
          break;
        default:
          const newPoints = lastLine.points().concat([position.x, position.y]);
          lastLine.points(newPoints);
      }

      component.layer.batchDraw();
    });

    this.stage.on('dragstart', () => {
      isDragging = true;
    });
  }

  public setIsMobile(isMobile: boolean): void {
    this.isMobile === isMobile || (this.isMobile = isMobile);
  }

  public get konvaButtonTooltipPosition(): string {
    return this.isMobile ? 'top' : 'left';
  }
}
