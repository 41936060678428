<virtual-scroller
  #tableWrapper
  #vsRef
  tabindex="0"
  id="virtual-scroller"
  class="shared-table__wrapper scrollbar overlay-y"
  (scroll)="onScroll()"
  [items]="list"
  [bufferAmount]="bufferAmount"
  [compareItems]="compareItems"
  (vsEnd)="virtualScrollerEnd($event)"
>
  <table #table class="shared-vsTable__table shared-table__table">
    <thead #header class="shared-vsTable__thead position-relative z-index-2">
      <ng-container [ngTemplateOutlet]="theadRowTemplate"></ng-container>
    </thead>

    <tbody #container #tbody class="shared-vsTable__tbody" [ngClass]="{ 'v-scrollbar': bodyOverflow }">
      <ng-container *ngFor="let item of vsRef.viewPortItems; let index = index; let last = last">
        <ng-container
          [ngTemplateOutlet]="tbodyRowTemplate"
          [ngTemplateOutletContext]="{ item, index, last }"
        ></ng-container>
      </ng-container>
    </tbody>
  </table>
</virtual-scroller>

<ng-template #spinner>
  <div class="loader">
    <span class="custom-spinner custom-spinner-48"></span>
  </div>
</ng-template>
