import {ELang} from "@atlas-workspace/shared/models";
import {TranslateService} from "@ngx-translate/core";

export function getFloorPlanTranslation(
  floorPlanVersion: number,
  translateService: TranslateService,
  translateKey = 'Entity.Floor_plan_v2'
): string {
  const word: string = translateService.instant(translateKey);

  switch (translateService.currentLang) {
    case ELang.Swedish:
      return `${floorPlanVersion} ${word}`;
    case ELang.Norwegian:
      return `${floorPlanVersion}. ${word.toLowerCase()}`;
    default:
      return `${word} ${floorPlanVersion}`;
  }
}
