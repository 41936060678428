import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedPipesModule } from '@atl-libs-shared-pipes/shared-pipes.module';
import { TimePassedAgoModule } from '@atl-libs-shared-pipes/time-passed-ago/time-passed-ago.module';
import { TruncatePipeModule } from '@atl-libs-shared-pipes/truncate/truncate.pipe.module';
import { FirmIconComponent, TooltipComponent } from '@atlas-workspace/shared/ui';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ListOfThreadsItemComponent } from './list-of-threads-item.component';
import {NoProjectSegmentInChangeRequestIdentifierPipe} from "@atlas-workspace/shared/pipes";



@NgModule({
  declarations: [ListOfThreadsItemComponent],
  exports: [ListOfThreadsItemComponent],
	imports: [
		CommonModule,
		TranslateModule,
		SharedPipesModule,
		TimePassedAgoModule,
		TruncatePipeModule,
		TooltipComponent,
		NgbTooltipModule,
		FirmIconComponent,
		NoProjectSegmentInChangeRequestIdentifierPipe
	]
})
export class ListOfThreadsItemModule { }
