<ng-container *ngIf="!selectOffer; else offerDetail">
  <div class="offers">
      <div class="offers__header">
        <span class="fz-20 lh-24 fw-500">{{ 'Option.Price_Offer.Title' | translate }}</span>
      </div>
      <div class="offers__table-wrapper" *ngIf="offers.length">
        <atl-shared-table class="gl-shared-table--lv offers__table">
          <ng-container ngProjectAs="shared-table-header">
            <th
              atlColumnDef="offer_title"
              class="shared-table__header-cell w20"
              atl-sort-header
              [sortParam]="sortParam"
              (sortEvent)="sort($event)"
            >
              <div>{{ 'Title.Offer' | translate }}</div>
            </th>
            <th
              atlColumnDef="requests_group_identifier"
              class="shared-table__header-cell w22"
              atl-sort-header
              [sortParam]="sortParam"
              (sortEvent)="sort($event)"
            >
              <div>{{ 'Button.Group' | translate }}</div>
            </th>
            <th
              atlColumnDef="offer_price"
              class="shared-table__header-cell w18 border-right"
              atl-sort-header
              [sortParam]="sortParam"
              (sortEvent)="sort($event)"
            >
            <span>
              {{ 'Shared.Title.Total_price' | translate }}
            </span>
            </th>
            <th
              atlColumnDef="expiration_date"
              class="shared-table__header-cell w18"
              atl-sort-header
              [sortParam]="sortParam"
              (sortEvent)="sort($event)"
            >
              <span> {{ 'Title.Offer_expiration' | translate }} </span>
            </th>
            <th
              atlColumnDef="status"
              class="shared-table__header-cell w22"
              atl-sort-header
              [sortParam]="sortParam"
              (sortEvent)="sort($event)"
            >
              <span> {{ 'Shared.Entity.Status' | translate }} </span>
            </th>
          </ng-container>

          <ng-container ngProjectAs="shared-table-body">
            <tr
              *ngFor="let offer of offers; index as i; let last = last"
              (click)="getOffer(offer)"
            >
              <td class="shared-table__body-cell w20 position-relative">
                <div class="d-flex align-items-center">
                  <div class="gl-ellipsis-table-wr">
                    <span
                      #title
                      [ngbTooltip]="offer.title"
                      [disableTooltip]="title.clientWidth >= title.scrollWidth"
                      container="body"
                      placement="right"
                      triggers="hover touchstart"
                      class="d-block ellipsis"
                    >
                      {{ offer.title }}
                    </span>
                  </div>
                </div>
              </td>
              <td class="shared-table__body-cell w22 position-relative">
                <div class="d-flex align-items-center">
                  <div class="gl-ellipsis-table-wr">
                    <span
                      #requestsGroup
                      ngbTooltip="{{'Title.Change_request' | translate}} {{ offer.requestsGroup.identifier }}"
                      [disableTooltip]="requestsGroup.clientWidth >= requestsGroup.scrollWidth"
                      container="body"
                      placement="right"
                      triggers="hover touchstart"
                      class="d-block ellipsis"
                    >
                      {{'Title.Change_request' | translate}} {{ offer.requestsGroup.identifier }}
                    </span>
                  </div>
                </div>
              </td>
              <td class="shared-table__body-cell w18 cursor-pointer border-right">
                {{ offer.price | customDecimal }},-
              </td>
              <td class="shared-table__body-cell w18">
                <ng-container *ngIf="offer.expirationDate">
                  {{ offer.expirationDate | changeFormatDate: '.' | date: 'MMM d, y' }}
                </ng-container>
              </td>
              <td class="shared-table__body-cell w22">
              <span
                *ngIf="offer.status"
                class="gl-ellipsis-table-item status"
                [ngClass]="'status__' + statusClasses[offer.status].style"
              >
                {{ offer.localizedStatus }}
              </span>
              </td>
            </tr>
          </ng-container>
        </atl-shared-table>
      </div>
  </div>
</ng-container>

<ng-template #offerDetail>
  <button (click)="returnToOffers()" class="offers__return ml-24 mt-24 mb-24 d-flex align-items-center gap-4 p-0 fz-14 lh-25 fw-500 text-gray-890">
    <img src="assets/arrow-left-mobile.svg" alt="Back">
    <span>{{ 'Option.Price_Offer.Title' | translate }}</span>
  </button>
  <atl-sub-offer-detail
    [projectId]="projectId"
    [unitId]="unitId"
    [offer]="selectOffer!"
    (updateList)="getChangeRequestsCombinedOffersList($event)"
    [unit]="unit"
  ></atl-sub-offer-detail>
</ng-template>
