<atl-ngb-modal-header [title]="title" (closeModalEvent)="closeModal()"></atl-ngb-modal-header>

<div class="offer-detail v-scrollbar">
  <span class="offer-detail__title fz-16 lh-24 mb-24">{{'Shared.Change_request.Combine_Offer.Description' | translate}}</span>

  <div class="offer-detail__request-list">
    <span class="fz-16 lh-24 fw-600 mb-16 pl-12">
      {{ 'Shared.Entity.Summary' | translate }}
    </span>

    <div class="d-flex flex-column align-items-center w-100">
      <div
        *ngFor="let request of offer.subOffers"
        class="offer-detail__accordion"
        [class.open]="request.open"
      >
        <div class="header">
          <span class="header__title">
            {{ request.changeRequest.identifier }}
          </span>

          <span class="cost mr-8">{{ request.price | customDecimal }},-</span>

          <span class="header__arrow" [class.open]="request.open" (click)="request.toggle()">
            <img src="/assets/arrow-gray-down.svg" alt="arrow" />
          </span>
        </div>
        <div class="main">
          <label class="main__label mt-4">{{ 'Shared.Reclamation.Input.Description.Label' | translate }}</label>
          <p class="main__description v-scrollbar small">
            {{ request.description }}
          </p>
          <label class="main__label mt-16">
            {{ 'Shared.Change_request.Combine_Offer.Label.Floor_plan_version' | translate }}
          </label>
          <span class="main__description">
            {{ 'Shared.Entity.Version' | translate }} {{ request.versionNumber }}
          </span>
          <div class="mt-16">
            <atl-document-drag-and-drop
              [ngModel]="request.fileResources"
              [disabled]="true"
              [newestViewImage]="true"
              [label]="'Shared.Messages.Attachments'"
              [format]="acceptedExtensions"
              [asUploadButton]="true"
              [multiple]="true"
              (filePreviewHandler)="subOfferPreview($event, request)"
            >
            </atl-document-drag-and-drop>
          </div>
        </div>
      </div>
    </div>
    <div class="offer-detail__header">
      <div class="offer-detail__total">
        <span class="offer-detail__total-title">
          {{ 'Shared.Change_request.Combine_Offer.Total_changes' | translate }}
        </span>
        <span class="cost mr-53 fw-500"> {{ offer.subOffersTotalPrice | customDecimal }},- </span>
      </div>
      <div class="header mt-8">
        <span class="header__title">
          {{ 'Auth.Create.Price.Mark-up.Input.VAT.Title' | translate }} ({{ offer.pricingComponents.vat}}%)
        </span>
        <span class="cost mr-53"> {{ offer.computedPricingComponents.vat | customDecimal }},- </span>
      </div>
      <div class="header mt-8">
        <span class="header__title">
          {{ 'Input.Mark-up' | translate }} ({{ offer.pricingComponents.markup.value }}%)
        </span>
        <span class="cost mr-8"> {{ offer.computedPricingComponents.markup | customDecimal }},- </span>
        <atl-custom-checkbox
          [ngModel]="offer.pricingComponents.markup.includeInTotal"
          [disabled]="true"
          sizeInput="small"
          uniqID="detailMarkup"
          class="newest-checkbox"
        ></atl-custom-checkbox>
      </div>
      <div class="header mt-8">
        <span class="header__title">
          {{ 'Shared.Change_request.Combine_Offer.Calculation_cost' | translate }}
        </span>
        <span class="cost mr-8"> {{ offer.pricingComponents?.calculationCost?.value | customDecimal }},- </span>

        <atl-custom-checkbox
          [ngModel]="offer.pricingComponents.calculationCost.includeInTotal"
          [disabled]="true"
          sizeInput="small"
          uniqID="detailCalculationCost"
          class="newest-checkbox"
        ></atl-custom-checkbox>
      </div>
      <div class="header mt-8">
        <span class="header__title">
          {{ 'Shared.Change_request.Combine_Offer.Administration_costs' | translate }}
        </span>
        <span class="cost mr-8"> {{ offer.pricingComponents?.administrationCost?.value | customDecimal }},- </span>

        <atl-custom-checkbox
          [ngModel]="offer.pricingComponents.administrationCost.includeInTotal"
          [disabled]="true"
          sizeInput="small"
          uniqID="detailAdministrationCost"
          class="newest-checkbox"
        ></atl-custom-checkbox>
      </div>
      <ng-container *ngFor="let item of offer.pricingComponents?.additionalCosts; index as i">
        <div class="header mt-8">
          <span class="header__title d-flex min-w-0">
            <span
              #costName
              class="d-block ellipsis"
              [ngbTooltip]="item.name"
              [disableTooltip]="costName.offsetWidth >= costName.scrollWidth"
              triggers="hover touchstart"
            >
              {{ item.name }}
            </span>
          </span>
          <span class="cost mr-53"> {{ item.value | customDecimal }},- </span>
        </div>
      </ng-container>
      <div class="offer-detail__total border-top mt-8">
        <span class="offer-detail__total-title">
          {{ 'Shared.Title.Total_price' | translate }}
        </span>
        <span class="cost fw-500 mr-53"> {{ offer.price | customDecimal }},- </span>
      </div>
    </div>
  </div>
  <div *ngIf="offer.expirationDate || offer.fileResources?.length" class="offer-detail__controls">
    <div *ngIf="offer.expirationDate" class="mt-24">
      <label class="mb-16 d-flex fz-16 lh-24 fw-600">
        {{ 'Title.Offer_expiration' | translate }}
      </label>
      <span class="offer-detail__controls-date">{{offer.expirationDate}}</span>
    </div>
    <div *ngIf="offer.fileResources?.length" class="mt-24">
      <label class="mb-16 d-flex fz-16 lh-24 fw-600">
        {{'Shared.Messages.Attachments' | translate}}
      </label>
      <atl-document-drag-and-drop
        class="offer-attachment"
        [ngModel]="offer.fileResources"
        [disabled]="true"
        [newestViewImage]="true"
        [format]="acceptedExtensions"
        [asUploadButton]="true"
        [multiple]="true"
        (filePreviewHandler)="openPreview($event)"
      >
      </atl-document-drag-and-drop>
    </div>
  </div>
</div>

