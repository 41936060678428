import { CommonModule } from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'atl-empty-state',
  template: `
    <section class="empty__state flex-column-centered parentheight f-family-sfpro">
      <div class="empty__state-icon flex-column-centered">
        <img [src]="'assets/' + iconPath" alt="Empty state icon" />
      </div>
      <h2 class="empty__state-title fw-500 lh-24 fz-20 mt-16">{{ title | translate }}</h2>
      <p class="empty__state-message fw-400 lh-24 fz-16">{{ message | translate }}</p>
      <button
        *ngIf="showAddNew"
        (click)="addNew.emit()"
        class="newest-button newest-button-plus h-px-32 fz-14 wf-500 mt-24"
      >
        {{ 'Shared.Button.Add_new_item' | translate }}
      </button>
    </section>
  `,
  styles: [
    `
      .empty__state-icon {
        width: 64px;
        height: 64px;
      }
      .empty__state-title {
        margin-bottom: 16px;
        color: var(--primary-text-color);
      }
      .empty__state-message {
        color: var(--color-gray-890);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class EmptyStateComponent {
  @Input() iconPath!: string;
  @Input() title!: string;
  @Input() message!: string;
  @Input() showAddNew!: boolean;

  @Output() readonly addNew = new EventEmitter();
}
