import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { ProjectUnitsSettingsModel } from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModuleAccessService {
  public accesses: ProjectUnitsSettingsModel | undefined;

  public accessHandler$ = new ReplaySubject<ProjectUnitsSettingsModel>(1);

  constructor(private http: HttpClient, @Inject('ENVIRONMENT') private environment: IEnvironment) {}

  /**
   * Get Unit settings
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Client-Unit-Settings/paths/~1api~1v1~1client~1projects~1{project_id}~1units~1{unit_id}~1settings/get
   */
  public getModuleAccess(projectId: string | number, unitId: number): Observable<ProjectUnitsSettingsModel> {
    return this.http.get(`${this.environment.apiBaseUrl}api/v1/client/projects/${projectId}/units/${unitId}/settings`).pipe(
      map((res: any) => <ProjectUnitsSettingsModel>res.data),
      map((data) => (data ? plainToClass(ProjectUnitsSettingsModel, data) : new ProjectUnitsSettingsModel()))
    );
  }

  public setAccesses(_accesses: ProjectUnitsSettingsModel): void {
    this.accesses = _accesses;
    this.accessHandler$.next(this.accesses);
  }
}
