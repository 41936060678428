import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  CableChannelPdfMessageReportEventModel,
  CableChannelRemoveThreadEventModel,
  CableChannelRemoveUnreadThreadMessageModel,
  CableChannelUpdateLastMessageEventModel,
  CableChannelUpdateMessageStatusEventModel,
  ECableChannelEvents,
  ICableChannelEmit,
  ThreadMessageModel,
  ThreadModel,
} from '@atlas-workspace/shared/models';
import * as ActionCable from 'actioncable';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ThreadsHelperService } from '../threads/threads-helper.service';

@Injectable()
export class CableService {
  private _token: string | null = null;
  private _cable: ActionCable.Cable | null = null;
  private _isCableInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _cableThreadsChannel: ActionCable.Channel | null = null;
  private _cableMessageChannel: ActionCable.Channel | null = null;
  private _unreadCounters$ = new Subject<number | null>();
  private _threads$ = new Subject<Partial<ThreadModel>>();
  private _drafts$ = new Subject<void>();
  private _updateThreadLastMessage$ = new Subject<CableChannelUpdateLastMessageEventModel>();
  private _updateFolderCounters$ = new Subject<boolean>();
  private _updateMessagesStatus$ = new Subject<CableChannelUpdateMessageStatusEventModel>();
  private _unreadMessageRemoved$ = new Subject<CableChannelRemoveUnreadThreadMessageModel>();
  private _pdfMessageReport$ = new Subject<CableChannelPdfMessageReportEventModel>();

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private threadsHelperService: ThreadsHelperService,
  ) {}

  public set token(t: string) {
    this._token = t;
  }

  public get token(): string {
    return this._token || '';
  }

  public get isCableInitialized$(): BehaviorSubject<boolean> {
    return this._isCableInitialized$;
  }

  public get threads$(): Subject<Partial<ThreadModel>> {
    return this._threads$;
  }

  public get drafts$(): Subject<void> {
    return this._drafts$;
  }

  public get unreadCounters$(): Subject<number | null> {
    return this._unreadCounters$;
  }

  public get updateThreadLastMessage$(): Subject<CableChannelUpdateLastMessageEventModel> {
    return this._updateThreadLastMessage$;
  }

  public get updateFolderCounters$(): Subject<boolean> {
    return this._updateFolderCounters$;
  }

  public get updateMessagesStatus$(): Observable<CableChannelUpdateMessageStatusEventModel> {
    return this._updateMessagesStatus$.asObservable();
  }

  public get unreadMessageRemoved$(): Observable<Pick<CableChannelRemoveThreadEventModel, 'messageThread'>> {
    return this._unreadMessageRemoved$.asObservable();
  }

  public get pdfMessageReport$(): Observable<CableChannelPdfMessageReportEventModel> {
    return this._pdfMessageReport$.asObservable();
  }

  public initCableConnection(): void {
    if (!this.token) {
      return;
    }
    if (!this._cable) {
      this._cable = ActionCable.createConsumer(`${this.environment.threadsWssUrl}/cable?jid=${this.token}`);
    }
    this._cable.connect();
    this._isCableInitialized$.next(true);
    this.initThreadsConnection();
  }

  public closeCableConnection(): void {
    this.closeMessagesConnection();
    this.closeThreadsConnection();
    this._isCableInitialized$.next(false);
    !this._cable || this._cable.disconnect();
    this._cable = null;
  }

  /**
   * Method to initialize action-cable socket channel to proccess changes of threads.
   * @note `ActionCable` instance should be already created globaly before executing this method.
   * @note This `Channel` instance doesn't emit threads changes for current user which were triggered by that user.
   */
  private initThreadsConnection(): void {
    if (!this._cable) {
      return;
    }
    this._cableThreadsChannel = this._cable.subscriptions.create('MemberChannel', {
      received: ({ data, event }: ICableChannelEmit<unknown>) => {
        if (event === ECableChannelEvents.ThreadUp) {
          this.threads$.next(plainToClass(ThreadModel, data));
          this.unreadCounters$.next(null);
        }
        if (event === ECableChannelEvents.UpdateLastMessage) {
          this.updateThreadLastMessage$.next(plainToClass(CableChannelUpdateLastMessageEventModel, data));
          this.unreadCounters$.next(null);
        }
        if (event === ECableChannelEvents.ThreadRemove) {
          data = plainToClass(CableChannelRemoveThreadEventModel, data);
          const thread = this.threadsHelperService.threadCustomModelToThreadShortModel(
            data as CableChannelRemoveThreadEventModel
          );
          thread.removed = true;
          this.threads$.next(plainToClass(ThreadModel, thread));
          this.unreadCounters$.next(null);
        }
        if (event === ECableChannelEvents.RemoveUnreadMessage) {
          this._unreadMessageRemoved$.next(plainToClass(CableChannelRemoveUnreadThreadMessageModel, data));
        }
        if (event === ECableChannelEvents.StatusChanged) {
          this._updateMessagesStatus$.next(plainToClass(CableChannelUpdateMessageStatusEventModel, data));
        }
        if (event === ECableChannelEvents.PdfMessagesReportTask) {
          this._pdfMessageReport$.next(plainToClass(CableChannelPdfMessageReportEventModel, data));
        }
      },
    });
  }

  private closeThreadsConnection(): void {
    !this._cableThreadsChannel || this._cableThreadsChannel?.unsubscribe();
    this._cableThreadsChannel = null;
  }

  /**
   * Method to initialize action-cable socket channel to proccess a new message chunk.
   * @note `ActionCable` instance should be already created globaly before executing this method.
   * @param threadID number
   * @param cb (data: ICableChannelEmit\<ThreadMessageModel>) => void
   */
  public initMessagesConnection(threadID: number, cb: (data: ICableChannelEmit<ThreadMessageModel>) => void): void {
    if (!this._cable) {
      return;
    }
    this._cableMessageChannel = this._cable.subscriptions.create(
      {
        channel: 'Messages::MessageThreadChannel',
        id: threadID,
      },
      {
        received: cb,
      }
    );
  }

  public closeMessagesConnection(): void {
    !this._cableMessageChannel || this._cableMessageChannel?.unsubscribe();
    this._cableMessageChannel = null;
  }

  public markMessagesAsRead(messageIds: number[]): void {
    if (this._cableMessageChannel) {
      this._cableMessageChannel.perform('read_message', {
        message_ids: messageIds,
      });
    }
  }
}
