/* eslint-disable sonarjs/no-duplicate-string */
export const acceptedAttachmentsExtensions = '.doc, .ppt, .pptx, .docx, .pdf, .xls, .xlsx';

export const acceptedRawExtensions = '.dng, .tiff, .heic';

export const acceptedImagesExtensionsPreview = '.jpg, .jpeg, .png';

export const acceptedImagesExtensions = '.jpg, .jpeg, .png, .dng, .tiff, .heic';

export const monitoringAcceptedExtensions = '.jpg, .jpeg, .png, .dng, .tiff, .heic, .mov, .mp4, .avi, .mkv, .qt';

export const acceptedVideoExtensions = '.avi, .m4v, .mp4, .mov, .mkv, .qt';

export const whiteLabelLogoAcceptedImagesExtensions = '.jpg, .jpeg, .png, .svg';

export const acceptedUnitsFloorExtensions = '.jpg, .jpeg, .png, .pdf, .heic';

export const editorImageTypes = 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp,svg';

export const fileUploaderImageTypes = 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp';

export const newEditorImageTypes = 'jpeg,jpg,jpe,gif,bmp,webp,';

export const newEditorInvalidTypes = ['image/svg+xml'];

export const imagesDefaultListOfMimeTypes = ['image/jpg', 'image/jpeg', 'image/png', 'mp4'];

export const imageJpegMime = 'image/jpeg';

export const imageJpgMime = 'image/jpg';

export const imagePngMime = 'image/png';

export const heicMime = 'image/heic';

export const pdfMime = 'application/pdf';

export const acceptedGlobalExtensions =
  '.doc, .pptx, .docx, .pdf, .xls, .xlsx, .ppt, .png, .jpg, .jpeg, .dng, .tiff, .heic, .txt, .xlsm, .pages, .ai, .psd, .tiff, .dxf, .svg, .zip, .rar, .mov, .qt, .mp4, .avi, .mkv, .sos, .bak, .dwg';

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const attachmentsDefaultListOfMimeTypes = [
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
  'application/msword',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-excel',
  'text/plain',
  'application/vnd.ms-excel.sheet.macroenabled.12',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.apple.pages',
  'application/postscript',
  'image/vnd.adobe.photoshop',
  'application/x-photoshop',
  'application/photoshop',
  'application/psd',
  'image/png',
  'image/psd',
  'image/tiff',
  'image/jpeg',
  'image/x-dxf',
  'image/svg+xml',
  'image/heic',
  'application/zip',
  'application/x-zip-compressed',
  'application/vnd.rar',
  'application/x-iwork-pages-sffpages',
  'image/x-adobe-dng',
  'video/avi',
  'video/mp4',
  'video/quicktime',
  'application/acad',
  'image/vnd.dwg',
];

export const mimeTypesImages = ['image/png', 'image/psd', 'image/tiff', 'image/jpeg'];
export const mimeTypesFiles = [
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
  'application/msword',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-excel',
  'text/plain',
  'application/vnd.ms-excel.sheet.macroenabled.12',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.apple.pages',
  'application/postscript',
];

export const svgMimeType = 'image/svg+xml';
// https://github.com/Marcelh1983/document-viewer/tree/main/packages/ngx-doc-viewer
export const supportedNgxDocViewerExtensions =
  '.doc, .pptx, .docx, .pdf, .xls, .xlsx, .ppt, .png, .jpg, .jpeg, .txt, xlsm, .svg, .tiff';

export const documentsListOfMimeTypes = [...attachmentsDefaultListOfMimeTypes, ...imagesDefaultListOfMimeTypes];

// eslint-disable-next-line @typescript-eslint/naming-convention
export type fileExtensions = 'pptx' | 'doc' | 'docx' | 'pdf' | 'xls';

export const correctMimeTypes = [
  {
    mimeType: 'video/avi',
    extension: ['avi'],
  },
  {
    mimeType: 'video/quicktime',
    extension: ['mov', 'qt'],
  },
];

export function getExtension(name: string): string {
  const arr = name.split('.');
  return arr[arr.length - 1];
}

export function isImageExtension(file: any): boolean {
  if (file.filename.content_type) {
    const imgExtension = file.filename.content_type;
    return imgExtension === imageJpegMime || imgExtension === imagePngMime || file.type === 'image';
  }
  return false;
}

export function isImageByExtension(extension: string): boolean {
  return acceptedImagesExtensions.includes(extension!.toLowerCase());
}

export const xlsFileExtensions = '.xls, .xlsx';

export const editorOnlyImageTypes = 'jpg,jpeg,png';

/**
 * @extentions .doc .docx .xlsx .xlsm .xls .ppt .pptx
 */
export const microsoftDocumentViewerMimetypeList = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.macroenabled.12',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];
