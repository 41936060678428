export async function convertHeicToPng(file: File): Promise<File | null> {
  try {
    const heic2any = (await import('heic2any')).default;

    const converted: Blob | Blob[] = await heic2any({
      blob: file,
      toType: 'image/png'
    });

    const convertedBlob = Array.isArray(converted) ? converted[0] : converted;

    if (!convertedBlob) {
      return null;
    }

    return new File([convertedBlob], file.name.replace(/\.\w+$/, ".png"), { type: 'image/png' });
  } catch {
    return null;
  }
}
