import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NewestConfirmModalComponent } from '@atlas-workspace/shared/modals';
import {
  AdminModel,
  AdminProjectModel,
  changeReqStatusList,
  ChangeRequestGroupListModel,
  ChangeRequestGroupModel,
  ChangeRequestListModel,
  ChangeRequestModel,
  ChangeRequestReportPdfMessagesType,
  ChangeRequestReportType,
  ChangeRequestSettingModel,
  ChangeRequestsUnitModel,
  CombinedOffersListModel,
  CreateCombinedOffer,
  EChangeRequestStatus,
  EChangeRequestTab,
  EChangeRequestTabId,
  EGroupView,
  EThreadType,
  EUnitEventLogType,
  FloorModel, getDatePickerFormat,
  getTimeProjects,
  ICreationList,
  IDropdownReportsItem,
  IMark,
  IReqChangeRequestSetting,
  IReqChangeRequestUnitConfigSetting,
  ISettingsMenu,
  IThreadState,
  IUnitMainBuyer,
  ThreadModel,
  UnitFloorModel,
  UnitModel,
} from '@atlas-workspace/shared/models';
import {
  CableService,
  ChangeRequestService,
  MessageBannerService,
  ModalFacadeService,
  PageHelperService,
  ReportsService,
  SharedUiStorageService,
  ThreadsHelperService,
  ToasterService,
} from '@atlas-workspace/shared/service';
import { NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import {cloneDeep} from "lodash";
import { finalize, firstValueFrom, Subject, Subscription } from 'rxjs';
import {take, takeUntil, tap} from 'rxjs/operators';

// @ts-ignore
import messagesIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/message-state-status-16.svg';

import {
  changeRequestUnitDropdownReports,
  getGroupViewList,
  IUpdateTable,
  navigationAdvanceMenu
} from '../../../helpers/change-request-admin.helper';
import {ChangeRequestCombineOfferComponent} from '../change-request-combine-offer/change-request-combine-offer.component';
import {
  ChangeRequestMessagesReportModalComponent
} from '../change-request-messages-report-modal/change-request-messages-report-modal.component';
import {GroupCreatorModalComponent} from '../group-creator-modal/group-creator-modal.component';
import {GroupMarkFloorPlanModalComponent} from '../group-mark-floor-plan-modal/group-mark-floor-plan-modal.component';

@UntilDestroy()
@Component({
  selector: 'atl-change-request-group-details',
  templateUrl: './change-request-group-details.component.html',
  styleUrls: [
    './change-request-group-details.component.scss',
    '../../../styles/status.component.scss',
  ],
  providers: [ChangeRequestService],
})
export class ChangeRequestGroupDetailsComponent implements OnInit {
  @Input() public modalRef!: NgbModalRef;
  @Input() public projectId!: string | number;
  @Input() public changeRequestUnit!: ChangeRequestsUnitModel;
  @Input() public changeRequestGroups!: ChangeRequestGroupModel[];
  @Input() public settings!: ChangeRequestSettingModel;
  @Input() public unit!: UnitModel;
  @Input() public users: AdminProjectModel[] | AdminModel[] = [];
  @Input() public threadViewComponent!: any;

  @Output() public readonly progressUpdate = new EventEmitter<ChangeRequestsUnitModel>();

  public openedChangeRequestModalRef?: NgbModalRef;
  public openedChangeRequest?: ChangeRequestModel | ChangeRequestListModel;
  public openedChangeRequestIndex?: number;
  public isLoading = true;
  public isMoreDropdownExpanded = false;
  public isDisplayTooltip = false;
  public viewerUsers!: IUnitMainBuyer[];
  public openDateForPicker: NgbDateStruct = getDatePickerFormat(new Date().toISOString(), new Date().toISOString())
    .from;
  public closedDateControl!: FormControl;
  public responsibleControl!: FormControl;
  public readonly analyticsFilterType = EUnitEventLogType.ChangeRequests;
  public readonly statusList = changeReqStatusList;
  public readonly isMac = window?.navigator.userAgent.includes('Mac');
  public readonly isSafari = 'safari' in window;
  public readonly messagesIcon = messagesIcon;
  public readonly nameTruncate = 30;
  public readonly dropdownReports = changeRequestUnitDropdownReports;
  private newClosedDateSubscription: Subscription | null = null;

  public totalChangeRequests = 0;
  public viewModeKey = EGroupView;
  public viewMode = EGroupView.List;
  public groupViewList = getGroupViewList(this.translateService);
  public floorPlanData?: UnitFloorModel[] | FloorModel[];
  public floorTabActiveId!: number;
  public navList!: ISettingsMenu[];
  public mark!: IMark;
  public previewPlan?: string;
  private floorsMarkData: ICreationList[] = [];
  public offerList!: CombinedOffersListModel[];
  public changeRequestTabKey = EChangeRequestTabId;
  public activeTab = EChangeRequestTabId.General;
  public navigationMenu: ISettingsMenu[] = cloneDeep(navigationAdvanceMenu);
  public loadingDocument = false;
  private _cancelDownload$ = new Subject<void>();

  constructor(
    private readonly changeRequestService: ChangeRequestService,
    private readonly pageHelperService: PageHelperService,
    private readonly messageBannerService: MessageBannerService,
    private readonly translateService: TranslateService,
    private readonly modalFacadeService: ModalFacadeService,
    private readonly injector: Injector,
    private readonly router: Router,
    private readonly cableService: CableService,
    private readonly threadsHelperService: ThreadsHelperService,
    private readonly toasterService: ToasterService,
    private readonly sharedUiStorageService: SharedUiStorageService,
    private readonly reportsService: ReportsService,
  ) {}

  ngOnInit(): void {
    this.getChangeRequestsCombinedOffersList();
    this.setOrderChangeRequests();
    this.initFloorPlanData();
    this.totalChangeRequestsInGroups();
    this.setViewerUsers();
    this.initDates();
    this.initResponsible();
    this.subscribeToCableThreadsSnapshots();
    this.subscribeToThreadReadStateChange();
  }

  private subscribeToCableThreadsSnapshots(): void {
    this.cableService.threads$.pipe(untilDestroyed(this)).subscribe((thread) => {
      if (Number(this.projectId) !== thread.project?.id) {
        return;
      }
      const changeRequests = this.findChangeRequestInGroup('messageThreadId', thread.id);
      const notesChangeRequests = this.findChangeRequestInGroup('notesMessageThreadId', thread.id);
      if (changeRequests) {
        if (thread instanceof ThreadModel) {
          changeRequests.messageThreadState = thread.state;
          changeRequests.messageThreadHasMessages = true;
          changeRequests.messageThreadUnreadCount = Number(thread.unreadCount);
        } else {
          changeRequests.messageThreadState = thread.state as IThreadState;
          changeRequests.messageThreadHasMessages = true;
        }
      }

      if (notesChangeRequests) {
        if (thread instanceof ThreadModel) {
          notesChangeRequests.messageThreadState = thread.state;
          notesChangeRequests.messageThreadHasMessages = true;
          notesChangeRequests.notesMessageThreadUnreadCount = Number(thread.unreadCount);
        } else {
          notesChangeRequests.messageThreadState = thread.state as IThreadState;
          notesChangeRequests.messageThreadHasMessages = true;
        }
      }
    });
  }

  private subscribeToThreadReadStateChange(): void {
    this.threadsHelperService
      .getThreadStateReadId()
      .pipe(untilDestroyed(this))
      .subscribe((id) => {
        if (id && this.changeRequestGroups.length) {
          const changeRequests = this.findChangeRequestInGroup('messageThreadId', id);
          const notesChangeRequests = this.findChangeRequestInGroup('notesMessageThreadId', id);
          if (changeRequests) {
            changeRequests.messageThreadUnreadCount = 0;
          }
          if (notesChangeRequests) {
            notesChangeRequests.notesMessageThreadUnreadCount = 0;
          }
        }
      });
  }

  private findChangeRequestInGroup(key: keyof ChangeRequestGroupListModel, id?: number): ChangeRequestGroupListModel | undefined {
    return this.changeRequestGroups.find(group =>
      group.changeRequests.some(cr => cr[key] === id))?.changeRequests
      .find(cr => cr[key] === id);
  }

  public closeModal(): void {
    this.modalRef?.dismiss();
  }

  public selectAnotherTab(e: { tabName: EChangeRequestTab, itemId: EChangeRequestTabId }): void {
    this.activeTab = e.itemId;
  }

  public openActivity(activity = true): void {
    this.activeTab = activity ? EChangeRequestTabId.Activities : EChangeRequestTabId.General;
  }

  private totalChangeRequestsInGroups(): void {
    this.totalChangeRequests = this.changeRequestGroups.reduce((acc, item) => (acc += item.changeRequests.length), 0);
  }

  private initResponsible(): void {
    this.responsibleControl = new FormControl([], Validators.required);
    this.responsibleListen();

    const config = this.settings.unitsConfigs.find((c) => c.unitId === this.unit.id);
    if (config && config.responsible) {
      this.responsibleControl.setValue([config.responsible], { emitEvent: false });
    }
  }

  private responsibleListen(): void {
    this.responsibleControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      let newUnitConfig: IReqChangeRequestUnitConfigSetting;
      const prevUnitConfig = this.settings.unitsConfigs.find((c) => c.unitId === this.unit.id);
      if (prevUnitConfig) {
        newUnitConfig = {
          id: prevUnitConfig.id,
          member_id: prevUnitConfig.unitId,
          responsible_id: value?.length ? value[0].id : null,
        };
      } else {
        newUnitConfig = {
          member_id: this.unit.id,
          responsible_id: value?.length ? value[0].id : null,
        };
      }
      this.setProjectSettings({ units_configs_attributes: [newUnitConfig] });
    });
  }

  private initDates(): void {
    if (!this.changeRequestUnit.openDate) {
      return;
    }
    this.openDateForPicker = getDatePickerFormat(this.changeRequestUnit.openDate, this.changeRequestUnit.openDate).from;
    this.closedDateControl = new FormControl(this.getValueOfClosedDate(), [
      Validators.required,
      this.closeDateValidation(),
    ]);
  }

  private handleClosedDateValueChanged(date: any): void {
    const newClosedDate = date ? getTimeProjects(date.to, date.to) : null;
    let newUnitConfig: IReqChangeRequestUnitConfigSetting;
    const prevUnitConfig = this.settings.unitsConfigs.find((c) => c.unitId === this.changeRequestUnit.id);
    if (prevUnitConfig) {
      newUnitConfig = {
        id: prevUnitConfig.id,
        member_id: prevUnitConfig.unitId,
        closed_date: newClosedDate,
      };
    } else {
      newUnitConfig = {
        member_id: this.changeRequestUnit.id,
        closed_date: newClosedDate,
      };
    }
    this.setProjectSettings({ units_configs_attributes: [newUnitConfig] });
  }

  public onresetClosedDate(): void {
    this.changeRequestUnit.closedDate = this.settings.closedDate;
    this.closedDateControl.setValue(this.getGlobalValueOfClosedDate(), { emitEvent: false });
    this.handleClosedDateValueChanged(null);
  }

  public onsetClosedDate(): void {
    this.handleClosedDateValueChanged(this.closedDateControl.value);
  }

  private getValueOfClosedDate(): unknown {
    const unitConfig = this.settings.unitsConfigs.find((c) => c.unitId === this.unit.id);
    if (unitConfig?.closedDate) {
      return unitConfig.closedDate ? getDatePickerFormat(unitConfig.closedDate, unitConfig.closedDate) : '';
    }
    return this.getGlobalValueOfClosedDate();
  }

  private getGlobalValueOfClosedDate(): unknown {
    return this.changeRequestUnit.closedDate
      ? getDatePickerFormat(this.changeRequestUnit.closedDate, this.changeRequestUnit.closedDate)
      : '';
  }

  private setProjectSettings(data: Partial<IReqChangeRequestSetting>): void {
    if (this.newClosedDateSubscription && !this.newClosedDateSubscription.closed) {
      this.newClosedDateSubscription.unsubscribe();
    }
    this.newClosedDateSubscription = this.changeRequestService
      .setProjectSettings(+this.projectId, data)
      .pipe(finalize(() => this.newClosedDateSubscription?.unsubscribe()))
      .subscribe((settings) => {
        this.settings.unitsConfigs = settings.unitsConfigs;
        this.closedDateControl.setValue(this.getValueOfClosedDate(), { emitEvent: false });
      });
  }

  public openedChangeRequestUnitChanged(changes: IUpdateTable): void {
    this.openedChangeRequest = changes.changeRequest;
    const changeRequests = this.findChangeRequestInGroup('id', this.openedChangeRequest?.id);
    if (changeRequests) {
      for (const property in changes) {
        //@ts-ignore
        changeRequests[property] = changes[property];
      }
    }
  }

  public openedChangeRequestWasClosed(): void {
    this.openedChangeRequest = undefined;
    this.openedChangeRequestIndex = undefined;
    this.getGroupChangeRequest();
  }

  public openChangeRequestDetails(changeRequest: ChangeRequestGroupListModel): void {
    const id = changeRequest.id;
    this.changeRequestService
      .getChangeRequestDetails(this.projectId.toString(), id.toString())
      .pipe(take(1))
      .subscribe((data) => {
        this.openedChangeRequestModalRef = <NgbModalRef>{
          dismiss: () => this.openedChangeRequestWasClosed(),
        };
        this.openedChangeRequest = data;
      });
  }

  public setViewerUsers(): void {
    const mainPrimaryOwner = this.changeRequestUnit.mainPrimaryOwner;
    const primaryOwners = this.changeRequestUnit.primaryOwners;
    this.viewerUsers = [
      ...(mainPrimaryOwner ? [mainPrimaryOwner] : []),
      ...(primaryOwners ? primaryOwners.filter(({ id }) => id !== mainPrimaryOwner?.id) : []),
    ];
  }

  public copyToClipboard(): void {
    const params = `?unitId=${this.changeRequestUnit.id}`;
    this.pageHelperService.copyToClipboard(params, false, () => {
      this.isMoreDropdownExpanded = false;
      this.messageBannerService.triggerShowBanner({
        text: 'Shared.Entity.Link_copied',
        icon: false,
      });
    });
  }

  public closeDocumentLoading(): void {
    this._cancelDownload$.next();
    this.loadingDocument = false;
  }

  public download(): void {
    if (this.loadingDocument) {
      return;
    }
    this.loadingDocument = true;
    this.changeRequestService
      .getDownloadUrlForChangeRequestUnit(+this.projectId, this.changeRequestUnit.id)
      .pipe(
        take(1),
        takeUntil(this._cancelDownload$),
        tap((downloadUrl) => {
          this.loadingDocument = false;
          window.open(downloadUrl, '_self');
        }),
      )
      .subscribe();
  }

  public openModalOfMessageSections(): void {
    const modalRef = this.modalFacadeService.openModal(ChangeRequestMessagesReportModalComponent, {
      ...this.sharedUiStorageService.modalChangeRequestMessagesReport,
    });
    const componentInstance = <ChangeRequestMessagesReportModalComponent>modalRef.componentInstance;
    componentInstance.modalRef = modalRef;
    componentInstance.isCRUnitScope = true;
    componentInstance.descriptionIdentifier = this.unit.identifier;
    modalRef.closed.pipe(take(1)).subscribe(async (type) => {
      const generation = await firstValueFrom(this.reportsService.pdfMessageReportGeneration$);
      if (type && !generation.toggle) {
        this.getChangeRequestMessagesReport(type);
      }
    });
  }

  private closeDateValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.changeRequestUnit.openDate || !this.changeRequestUnit.closedDate) return null;
      if (!control.value?.from) return null;

      const newClosedDate = getTimeProjects(control.value?.from, control.value?.from);
      const openDatePickerFormat = getDatePickerFormat(
        this.changeRequestUnit.openDate,
        this.changeRequestUnit.openDate,
      );
      const openDate = getTimeProjects(openDatePickerFormat.from, openDatePickerFormat.from);
      return dayjs(openDate).isBefore(dayjs(newClosedDate)) ? null : { invalid: true };
    };
  }

  get closedDateErrorMsg(): string {
    if ((this.closedDateControl?.errors as any)?.invalid) {
      return 'Change_request.Settings.Deadline.Error';
    }
    return 'Input.Date.Error.Required';
  }

  public changeRequestThreadStateChanged(state: IThreadState): void {
    if (this.openedChangeRequest) {
      this.openedChangeRequest.messageThreadState = state;
      const changeRequests = this.findChangeRequestInGroup('id', this.openedChangeRequest.id);
      if (changeRequests) {
        changeRequests.messageThreadState = state;
      }
    }
  }

  public openCreationModal(): void {
    const modalRef = this.modalFacadeService.openModal(GroupCreatorModalComponent, {
      centered: true,
      windowClass: 'full-screen-modal',
      injector: this.injector,
      beforeDismiss: () => {
        if (!modalRef.componentInstance.changeRequestList.length) {
          return true;
        } else {
          return this.confirmCloseModal();
        }
      },
    });
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.projectId = this.projectId;
    modalRef.componentInstance.selectedUnit = this.unit;
    modalRef.componentInstance.unit = this.unit;
    modalRef.componentInstance.users = this.users;
    modalRef.dismissed.pipe(untilDestroyed(this)).subscribe((value) => {
      if (!value && modalRef.componentInstance.isRedirect) {
        this.unitRedirect();
      }
      if (value) {
        this.changeRequestGroups.push(value);
        this.changeRequestUnit.changeRequestsStats.total += value.changeRequests.length;
        this.progressUpdate.emit(this.changeRequestUnit);
        const messageText = (value as ChangeRequestGroupModel).changeRequests.length > 1
          ? this.translateService.instant('Change_Request.Message.Change_requests_was_created')
          : this.translateService.instant('Change_Request.Message.Change_request_was_created');
        this.toasterService.openSuccessToast(messageText);
        this.totalChangeRequestsInGroups();
        this.setOrderChangeRequests();
        this.initNavList();
      }
    });
  }

  private async confirmCloseModal(): Promise<boolean> {
    const title = 'Change_Request.Close.Confirm.Title';
    const description = 'Change_Request.Close.Confirm.Description';

    const confirmModalRef = this.modalFacadeService.openModal(NewestConfirmModalComponent, {
      centered: true,
      windowClass: 'confirm-action-modal-v2',
    });
    confirmModalRef.componentInstance.title = `${this.translateService.instant(title)}`;
    confirmModalRef.componentInstance.description = this.translateService.instant(description);
    confirmModalRef.componentInstance.button.text = `${this.translateService.instant('Alt.Close')}`;
    confirmModalRef.componentInstance.icon = 'basket.svg';

    return confirmModalRef.componentInstance.passConfirm
      .pipe(
        take(1),
        tap((res: boolean) => {
          if (res) {
            confirmModalRef.close();
          }
        }),
      )
      .toPromise();
  }

  private initFloorPlanData(): void {
    if (this.unit!.hasOwnFloorPlans) {
      this.floorPlanData = this.unit!.unitFloors;
    } else {
      this.floorPlanData = this.unit!.floors;
    }
    this.initNavList();
  }

  public selectTab(event: { itemId: number }): void {
    this.floorTabActiveId = event.itemId;
    this.activePreviewData();
  }

  private setOrderChangeRequests(): void {
    let count = 1;
    this.changeRequestGroups.forEach((group) => {
      group.changeRequests.forEach((request) => {
        request.order = count;
        count++;
      });
    });
  }

  private getMarkDataInChangeRequests(floorId: number): { x: number; y: number; order?: number }[] {
    return this.changeRequestGroups.reduce((acc: { x: number; y: number; order?: number }[], group) => {
      const mark = group.changeRequests
        .filter((c) => c.floorId === floorId)!
        .map((c) => {
          return {
            x: c.pointX,
            y: c.pointY,
            order: c.order,
          };
        })!;
      acc.push(...(mark || []));
      return acc;
    }, []);
  }

  private initNavList(): void {
    if (!this.floorPlanData?.length) return;
    this.floorsMarkData = [];
    this.navList = this.floorPlanData!.map((floor) => {
      const mark = this.getMarkDataInChangeRequests(floor.id);
      this.floorsMarkData.push({
        floorPlanId: floor.id,
        floorPlanData: floor,
        markData: {
          floorId: floor.id,
          floorNumber: floor.floorNumber,
          floorType: floor,
          mark: mark,
        },
      });
      return {
        id: floor.id,
        name: floor.name,
        route: '',
        disabled: false,
        hasPlan: !!floor.plan,
      };
    });

    if (this.navList.length) {
      this.floorTabActiveId = this.navList[0].id as number;
      this.activePreviewData();
    }
  }

  private activePreviewData(): void {
    const floor = this.floorsMarkData.find((item) => item.floorPlanId === this.floorTabActiveId);
    if (floor) {
      this.previewPlan = floor.floorPlanData.plan?.fullImage?.url;
      this.mark = { ...floor.markData };
    }
  }

  public unitRedirect(): void {
    const url = `/base-layout/projects/specific_project/${this.projectId}/units/view/units?unitId=${this.unit?.id}`;
    void this.router.navigateByUrl(url);
  }

  public selectFloorPlanModal(): void {
    const modalRef = this.modalFacadeService.openModal(GroupMarkFloorPlanModalComponent, {
      centered: true,
      windowClass: 'full-screen-modal',
    });

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.projectId = this.projectId;
    modalRef.componentInstance.users = this.users;
    modalRef.componentInstance.unitId = this.unit.id;
    modalRef.componentInstance.unitIdentifier = this.unit.identifier;
    modalRef.componentInstance.floorPlanData = this.floorPlanData;
    modalRef.componentInstance.floorTabActiveId = this.floorTabActiveId;
    modalRef.componentInstance.navList = this.navList;
    modalRef.componentInstance.floorsMarkData = this.floorsMarkData;
    modalRef.componentInstance.changeRequestGroups = this.changeRequestGroups;
    modalRef.componentInstance.threadViewComponent = this.threadViewComponent;

    modalRef.componentInstance.updateTable.pipe(untilDestroyed(this)).subscribe(() => {
      this.getGroupChangeRequest();
    });
  }

  public onRemoveSelectedItem(): void {
    this.responsibleControl.reset();
  }

  private groupListCreator(): {id: number, title: string}[] {
    return this.getAllowOffers.map((group, index) => {
      return {
        id: group.id,
        title: `${this.translateService.instant('Title.Change_request')} ${group.identifier ? group.identifier : index + 1}`,
      };
    });
  }

  public initCombineOfferModal(): void {
    const groupList = this.groupListCreator();

    if (groupList.length > 1) {
      this.combineOfferModal(groupList);
      return;
    }
    this.changeRequestService
      .getGroupChangeRequestsData(this.projectId?.toString(), this.unit.id, groupList[0].id)
      .pipe(take(1))
      .subscribe((value) => {
        const combine = new CreateCombinedOffer(groupList[0].id, value);
        this.combineOfferModal(groupList, combine);
      });
  }

  private combineOfferModal(groupList: { id: number; title: string }[], combine?: CreateCombinedOffer): void {
    const modalRef = this.modalFacadeService.openModal(ChangeRequestCombineOfferComponent, {
      centered: true,
      windowClass: 'change-request-combine-offer',
    });

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.identifier = this.unit.identifier;
    modalRef.componentInstance.groupList = groupList;
    modalRef.componentInstance.combine = combine;
    modalRef.componentInstance.projectId = this.projectId;
    modalRef.componentInstance.unit = this.unit;

    modalRef.dismissed.pipe(take(1)).subscribe((value) => {
      if (value) {
        this.getGroupChangeRequest();
        this.getChangeRequestsCombinedOffersList();
        this.toasterService.openSuccessToast(this.translateService.instant('Shared.Change_request.Offer_sent'));
      }
    });

    modalRef.componentInstance.updateGroupList.pipe(untilDestroyed(this)).subscribe(() => {
      this.getGroupChangeRequest(() => {
        modalRef.componentInstance.groupList = this.groupListCreator();
      });
    });
  }

  public get getAllowOffers(): ChangeRequestGroupModel[] {
    const allowStatuses = [
      EChangeRequestStatus.InProgress,
      EChangeRequestStatus.OfferCanceled,
      EChangeRequestStatus.OfferDeclined,
      EChangeRequestStatus.RequestDeclined,
    ];

    const deniedStatuses = [
      EChangeRequestStatus.OfferApproved,
      EChangeRequestStatus.Offered,
    ];
    return this.changeRequestGroups.filter((group) =>
      !group.archived && (group.activeOffer ? !deniedStatuses.includes(group.activeOffer.status) : true)  &&
      (group.changeRequests.some((request) => allowStatuses.includes(request.status))) &&
      group.changeRequests.every((request) => !deniedStatuses.includes(request.status))
    );
  }

  private getGroupChangeRequest(cb?: () => void): void {
    this.changeRequestService
      .getGroupChangeRequests(this.projectId.toString(), this.unit.id)
      .pipe(take(1))
      .subscribe((value) => {
        this.changeRequestGroups = value;
        this.setOrderChangeRequests();
        this.initNavList();

        if (cb) {
          cb();
        }
      });
  }

  private getChangeRequestsCombinedOffersList(): void {
    this.changeRequestService
      .changeRequestsCombinedOffersList(this.projectId.toString(), this.unit.id, '')
      .pipe(take(1))
      .subscribe((value) => {
        this.offerList = value;
      });
  }

  public updateOffersList(offers: CombinedOffersListModel[]): void {
    this.offerList = offers;
    if (!this.offerList.length && this.activeTab === EChangeRequestTabId.Offer) {
      this.activeTab = EChangeRequestTabId.General;
    }
    this.getGroupChangeRequest();
  }

  public openOfferTab(): void {
    this.activeTab = EChangeRequestTabId.Offer;
  }

  public reportOn(e: IDropdownReportsItem<ChangeRequestReportType>): void {
    if (e.type === ChangeRequestReportType.PDF) {
      this.download();
    }
    if (e.type === ChangeRequestReportType.PdfMessages) {
      this.openModalOfMessageSections();
    }
  }

  private getChangeRequestMessagesReport(type: ChangeRequestReportPdfMessagesType): void {
    this.reportsService
      .getChangeRequestUnitMessagesReport(this.unit.id, type)
      .pipe(take(1))
      .subscribe();
  }
}
