import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  SharedVirtualScrollTableTbodyRowDirective,
  SharedVirtualScrollTableTheadRowDirective,
} from '@atlas-workspace/shared/directives';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';

import { SharedVirtualScrollTableComponent } from './shared-virtual-scroll-table.component';

const declarations = [
  SharedVirtualScrollTableComponent,
  SharedVirtualScrollTableTheadRowDirective,
  SharedVirtualScrollTableTbodyRowDirective,
];

@NgModule({
  declarations: [...declarations],
  imports: [CommonModule, VirtualScrollerModule],
  exports: [...declarations],
})
export class SharedVirtualScrollTableModule {}
