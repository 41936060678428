export enum EMeetingStatus {
  Today = 'Shared.Meeting.Status.Today',
  Available = 'Shared.Meeting.Status.Available',
  Booked = 'Shared.Meeting.Status.Booked',
  Completed = 'Shared.Meeting.Status.Completed',
  Declined = 'Shared.Reclamation.Status.Declined',
  Invited = 'Entity.Invited',
  NotBooked = 'Shared.Reclamation.Status.Not_Booked',
}

export enum EMeetingStatusValue {
  Today = 'today',
  Available = 'available',
  Completed = 'completed',
  Confirmed = 'confirmed',
  Invite = 'pending',
  Declined = 'declined',
  Filled = 'filled',
  NotBooked = 'not_booked',
}

export enum EMeetingEffectiveStatus {
  Booked = 'booked',
  Available = 'available',
  Invited = 'invited',
  Declined = 'declined',
  Completed = 'completed'
}

export enum EMeetingCountStatus {
  Booked = 'booked',
  Invited = 'invited',
}
