import { AbstractControl } from '@angular/forms';
import { Expose } from 'class-transformer';

export class WhiteLabelSettingsModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'white_labelable_type' })
  whiteLabelableType!: WhiteLabelSettingsType;
  @Expose({ name: 'white_labelable_id' })
  whiteLabelableId!: number;
  @Expose({ name: 'status' })
  status!: WhiteLabelSettingsStatus;
  @Expose({ name: 'subdomain_name' })
  subdomainName!: string;
  @Expose({ name: 'accent_color' })
  accentColor!: string;
  @Expose({ name: 'border_color' })
  borderColor!: string;
  @Expose({ name: 'text_color' })
  textColor!: string;
  @Expose({ name: 'background_color' })
  backgroundColor!: string;
  @Expose({ name: 'button_text_color' })
  buttonTextColor!: string;
  @Expose({ name: 'button_corner_radius' })
  buttonCornerRadius!: number;
  @Expose({ name: 'logo_url' })
  logoUrl!: string;
}

export enum WhiteLabelSettingsStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum WhiteLabelSettingsType {
  Project = 'Project',
  Firm = 'Firm',
}

export enum WhiteLabelSettingsFormKeys {
  AccentColor = 'accent_color',
  BorderColor = 'border_color',
  TextColor = 'text_color',
  BackgroundColor = 'background_color',
  ButtonTextColor = 'button_text_color',
  ButtonCornerRadius = 'button_corner_radius',
  LogoUrl = 'logo_url',
  LogoFile = 'logo_file',
}

export type WhiteLabelSettingsFormControl = {
  [key in WhiteLabelSettingsFormKeys]: AbstractControl<any>;
};

export type WhiteLabelSettingsFormRawValue = {
  [key in WhiteLabelSettingsFormKeys]: any;
};
