<div class="breadcrumbs">
  <ng-container *ngIf="selectFolder.parents.length <= 1; else breadcrumbsDropdown">
    <span
      (click)="select(item.id)"
      *ngFor="let item of selectFolder.parents"
      class="breadcrumbs__item cursor-pointer"
      [ngClass]="{ 'client ellipsis': isClient }"
    >
      <img class="ml-4 mr-4" src="assets/arrow_right-gray-newest.svg" alt="arrow" />
      {{ item.name }}
    </span>
  </ng-container>
  <span class="breadcrumbs__item d-block ellipsis end" [class.client]="isClient">
    <img class="ml-4 mr-4" src="assets/arrow_right-gray-newest.svg" alt="arrow" />
    {{ selectFolder.name }}
  </span>
</div>

<ng-template #breadcrumbsDropdown>
  <div
    #dropdownMenu="ngbDropdown"
    ngbDropdown
    container="body"
    [placement]="['bottom-left']"
    dropdownClass="dropdown-fdv"
    class="breadcrumbs__dropdown d-flex align-items-center"
  >
    <img class="ml-4 mr-4" src="assets/arrow_right-gray-newest.svg" alt="arrow" />
    <div
      ngbDropdownToggle
      id="breadcrumbsDropdown{{ selectFolder.id }}"
      class="breadcrumbs__dropdown-toggle"
      [class.active]="dropdownMenu._open"
    >
      <img alt="icon" src="/assets/dropdown-dot-3.svg" />
    </div>
    <div ngbDropdownMenu aria-labelledby="breadcrumbsDropdown{{ selectFolder.id }}">
      <div class="breadcrumbs__dropdown-menu v-scrollbar small">
        <div
          (click)="select(item.id)"
          class="breadcrumbs__dropdown-item"
          ngbDropdownItem
          *ngFor="let item of selectFolder.parents"
        >
          <span class="ellipsis">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
