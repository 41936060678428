<ng-container *ngIf="isEditMode; else ViewMode">
  <atl-offer-detail
    [editOffer]="editOffer"
    (back)="updateOffer($event)"
    (updateOffer)="updateOffer($event, true)"
    [projectId]="projectId"
    [changeRequest]="changeRequest"
    [disabledNewOffer]="disabledNewOffer"
    (removeOfferHandler)="removeOfferHandler($event)"
    (duplicateHandler)="duplicateHandler($event)"
    [requestIsDeclined]="isDeclined"
    [floorPlanData]="floorPlanData"
    [unit]="unit"
    [unitId]="unitId"
  ></atl-offer-detail>
</ng-container>

<ng-template #ViewMode>
  <ng-container *ngIf="!isFirstLoad">
    <div class="offers">
      <div class="offers__form-control">
        <span class="fz-20 lh-24 fw-500">{{ 'Option.Price_Offer.Title' | translate }}</span>
        <div
          class="offers__filter-wrapper"
          [ngbTooltip]="
            (isDeclined ? 'Change_request.Offers.Button.Message.Decline' : 'Change_request.Offers.Button.Message.Send')
              | translate
          "
          tooltipClass="change-request-offers button"
          placement="bottom-right"
          triggers="hover touchstart"
          [disableTooltip]="!(disabledNewOffer || isDeclined)"
        >
          <button
            (click)="createOffer()"
            data-cy="cy-uoffers-add-offer-button"
            class="newest-button newest-button-plus h-px-32 fz-14"
            [disabled]="!unit || disabledNewOffer || isDeclined || changeRequest.archived"
          >
            {{ 'Button.New_offer' | translate }}
          </button>
        </div>
      </div>
      <div class="offers__table-wrapper" *ngIf="offers.length; else noOffers">
        <atl-shared-table class="gl-shared-table--lv offers__table">
          <ng-container ngProjectAs="shared-table-header">
            <th
              atlColumnDef="title"
              class="shared-table__header-cell w40"
              atl-sort-header
              (sortEvent)="sort($event)"
              [sortParam]="sortParam"
            >
              <atl-titled-checkbox
                class="newest-checkbox mr-8"
                [disabledState]="!offers?.length"
                (changeEvent)="checkAll($event)"
                [ngModel]="!!selectedIds.length"
                [checkedPartially]="!!selectedIds.length && !allOffersSelected"
              ></atl-titled-checkbox>
              <div>{{ 'Title.Offer_name' | translate }}</div>
            </th>
            <th
              atlColumnDef="price"
              class="shared-table__header-cell w20 border-right"
              atl-sort-header
              (sortEvent)="sort($event)"
              [sortParam]="sortParam"
            >
              <span>
                {{ 'Entity.Price' | translate }}
              </span>
            </th>
            <th
              atlColumnDef="expiration_date"
              class="shared-table__header-cell w20"
              atl-sort-header
              (sortEvent)="sort($event)"
              [sortParam]="sortParam"
            >
              <span> {{ 'Shared.Title.Due_date' | translate }} </span>
            </th>
            <th
              atlColumnDef="status"
              class="shared-table__header-cell w20"
              atl-sort-header
              (sortEvent)="sort($event)"
              [sortParam]="sortParam"
            >
              <span> {{ 'Shared.Entity.Status' | translate }} </span>
            </th>
          </ng-container>

          <ng-container ngProjectAs="shared-table-body">
            <tr
              *ngFor="let offer of offers; index as i; let last = last"
              (atlInfiniteScroll)="getNextPage(last)"
              [threshold]="0.9"
              (click)="openDetail(offer)"
            >
              <td class="shared-table__body-cell w40 position-relative">
                <div class="d-flex align-items-center">
                  <atl-titled-checkbox
                    [disabledState]="offer.status === statusKeys.OfferApproved"
                    class="newest-checkbox mr-8 pl-13"
                    (changeEvent)="checkValue(offer)"
                    [value]="offer.selected"
                    [ngbTooltip]="'Change_request.Offers.Checkbox.Approved' | translate"
                    tooltipClass="change-request-offers checkbox"
                    container="body"
                    placement="bottom-left"
                    triggers="hover touchstart"
                    [disableTooltip]="offer.status !== statusKeys.OfferApproved"
                  ></atl-titled-checkbox>
                  <div class="gl-ellipsis-table-wr">
                    <span
                      #title
                      [ngbTooltip]="offer.title"
                      [disableTooltip]="title.clientWidth >= title.scrollWidth"
                      container="body"
                      placement="right"
                      triggers="hover touchstart"
                      class="d-block ellipsis"
                      >{{ offer.title }}
                    </span>
                  </div>
                </div>
              </td>
              <td class="shared-table__body-cell w20 cursor-pointer border-right">
                {{ offer.price | customDecimal }},-
              </td>
              <td class="shared-table__body-cell w20">
                {{ offer.expirationDate | changeFormatDate: '.' | date: 'MMM d, y' }}
              </td>
              <td class="shared-table__body-cell w20">
                <span
                  *ngIf="offer.status"
                  class="gl-ellipsis-table-item status"
                  [ngClass]="'status__' + statusClasses[offer.status].style"
                >
                  {{ offer.localizedStatus }}
                </span>
              </td>
            </tr>
          </ng-container>
        </atl-shared-table>
      </div>
      <div *ngIf="selectedIds.length" class="offers__delete-wrapper">
        <atl-delete-modal (passCancel)="checkAll(false)" (passDelete)="initConfirmModal()">
          <span *ngIf="selectedIds.length > 1; else singleTask">
            {{ selectedIds.length }} {{ 'Entity.Items_selected' | translate }}
          </span>
          <ng-template #singleTask>
            <span>{{ selectedIds.length }} {{ 'Entity.Item_selected' | translate }}</span>
          </ng-template>
        </atl-delete-modal>
      </div>
      <atl-action-message-banner
        class="action-message-banner_component change-request"
        *ngIf="isShowMessageBanner"
        [messageText]="messageBanner"
        [closeButton]="false"
        [icon]="true"
        (closeEvent)="hideMessageBanner()"
      ></atl-action-message-banner>
    </div>

    <ng-template #noOffers>
      <div class="empty-offers">
        <div class="offers__empty-wrapper">
          <div class="offers__empty-content-wrapper">
            <div class="offers__empty-content">
              <img class="offers__empty-image mb-16" src="assets/offers_empty_state.svg" alt="image" />
              <h2 class="offers__empty-title mb-8">{{ 'Description.No_offer' | translate }}</h2>
              <p class="offers__empty-desc">
                {{ 'Description.Add_first_offer' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-template>
