import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ILockedTrigger } from '@atlas-workspace/shared/directives';
import { NewestConfirmModalComponent } from '@atlas-workspace/shared/modals';
import {
  DropdownFilePreview,
  EFileType,
  ErrorFilePreview,
  FileModel,
  ImageModel,
} from '@atlas-workspace/shared/models';
import { ModalFacadeService } from '@atlas-workspace/shared/service';
import { TranslateService } from '@ngx-translate/core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import autoScroll from 'dom-autoscroller';
import * as mime from 'mime';
import { DragulaService } from 'ng2-dragula';
import { take } from 'rxjs/operators';

import { FileValidators } from '../../validators/file-validators';

interface IConfirmRemove {
  title: string;
  description: string;
}

@Component({
  selector: 'atl-dropdown-input-file',
  templateUrl: './dropdown-input-file.component.html',
  styleUrls: ['./dropdown-input-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownInputFileComponent),
      multi: true,
    },
  ],
})
export class DropdownInputFileComponent implements ControlValueAccessor, OnInit, OnDestroy {
  inputAcceptString = '';

  @Input() set previewAttachment(files: (FileModel | ImageModel)[]) {
    this.listOfPreview = [];
    files.forEach((file, i: number) => this.sortTypeFile(file, true, i));
  }

  @Input() newOnly = false;
  @Input() tabindex = 0;
  @Input() set format(value: string | Array<string>) {
    if (typeof value === 'string') {
      this.inputAcceptString = value;
    } else {
      this.inputAcceptString = (value || []).filter((mType) => mType).join(', ');
    }
  }

  @Input() imagePreview = true;
  @Input() multiple = true;
  @Input() disabled = false;
  @Input() maxUploadedFiles = 10;
  @Input() autoSave = false;
  @Input() isLoading = false;
  @Input() isOnline = true;
  @Input() dragGroup = 'uploader';
  @Input() disableChangePosition = false;
  @Input() tooltipPlacement = 'top';
  @Input() placeholder = '';
  @Input() hideFilename = false;
  //TODO added this property for hiding the dropdown immediately
  // to fix the UI bug when the dropdown blinked for a second if open ng-select
  @Input() hiddenDropdown = false;
  @Input() setFileForWriteValue = false;
  @Input() noDownloadLink = false;

  @Input() set maxUploadedFilesSize(value: number) {
    if (value) {
      this._maxUploadedFilesSize = value;
    }
  }

  get maxUploadedFilesSize(): number {
    return this._maxUploadedFilesSize;
  }

  @Output() private readonly addNewFilesToExisting = new EventEmitter<File[]>();
  @Output() private readonly addCreateNewFilesToExisting = new EventEmitter<File[]>();
  @Output() private readonly addNewFile = new EventEmitter<File>();
  @Output() private readonly deleteExistingFile = new EventEmitter<FileModel | ImageModel>();
  @Output() private readonly deleteTempFile = new EventEmitter<number>();
  @Output() private readonly fileDeletion = new EventEmitter<number>();
  @Output() private readonly openPreview = new EventEmitter<FileModel | ImageModel>();
  @Output() private readonly updateFilePositionsEmitter = new EventEmitter<(FileModel | ImageModel | undefined)[]>();
  @Output() private readonly viewTempFilesEmitter = new EventEmitter<number>();
  @Output() private readonly tempPreview = new EventEmitter<{ src:  string | ArrayBuffer | null | undefined; name?: string; extension: string }>();

  @ViewChild('uploadInput') uploadInput!: ElementRef<HTMLElement>;
  @ViewChild('autoscroll', { static: false }) autoscroll!: ElementRef;

  public listOfPreview: DropdownFilePreview[] = [];
  public errorFilePreviews: ErrorFilePreview[] = [];
  public editFiles: (FileModel | ImageModel)[] = [];
  public showDropdown = false;
  public isDraggedOver = false;
  private files: File[] = [];
  private focused = false;

  // global max size in the project should not exceed 30 Mb
  private _maxUploadedFilesSize = 30000000;
  private readonly confirmRemoveText: IConfirmRemove = {
    title: 'Shared.Attachment.Confirm.Title',
    description: 'Shared.Attachment.Confirm.Description',
  };
  private fileListLength = 0;
  private drake!: any;

  private onTouched!: () => void;
  private onChange: (value: (FileModel | ImageModel | File)[] | null | undefined) => void = () => null;

  private locked = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private modalFacadeService: ModalFacadeService,
    private translateService: TranslateService,
    private dragulaService: DragulaService,
  ) {}

  ngOnInit(): void {
    this.initDragulaListener();
  }

  ngOnDestroy(): void {
    this.dragulaService.destroy(this.dragGroup);
  }

  public get extensionString(): string {
    return this.inputAcceptString.replace(/[^/,a-z0-9]/g, ' ').toUpperCase();
  }

  isLocked(value: ILockedTrigger): void {
    this.locked = value.locked ? !value.focused : value.locked;
  }

  public openDropdown(event: Event): void {
    if (this.disabled) return;
    if (this.focused) {
      this.focused = false;
      return;
    }
    if (this.locked) return;
    this.stopDefault(event);
    this.showDropdown = !this.showDropdown;

    this.cdr.detectChanges();
    if (this.showDropdown) {
      this.autoscrollInit();
    }
  }

  public displayDropdown(): void {
    if (this.disabled) return;
    this.showDropdown = true;
    this.focused = true;
  }

  public closeDropdown(): void {
    this.showDropdown = false;
  }

  public handleDragOver(event: Event): void {
    this.stopDefault(event);
    if (this.disabled) return;
    this.isDraggedOver = true;
  }

  public handleDragEnter(): void {
    if (this.disabled) return;
    this.fileListLength = this.listOfPreview.length;
    this.isDraggedOver = true;
  }

  public handleDragLeave(): void {
    this.isDraggedOver = false;
  }

  public handleUpload(e?: Event): void {
    if (e) this.stopDefault(e);
    if (this.disabled) return;
    this.fileListLength = this.listOfPreview.length;
    this.uploadInput.nativeElement.click();
  }

  public handleDrop(event: DragEvent): void {
    if (this.disabled) return;
    this.stopDefault(event);
    this.isDraggedOver = false;
    if (event.dataTransfer?.files?.length) {
      const files: File[] = Array.from(event.dataTransfer.files);
      this.loadValidFiles(files);
    }
  }

  public selectedFile(event: Event): void {
    if (event.target) {
      const target = event.target as HTMLInputElement;
      const files: File[] = Array.from(target.files as FileList);
      this.loadValidFiles(files);
      target.value = '';
      target.files = null;
    }
  }

  public removeFile(event: Event, index: number, file: DropdownFilePreview): void {
    this.stopDefault(event);
    if (file.isTemp) {
      this.listOfPreview.splice(index, 1);
      const idx = this.files.findIndex((f) => f.name === file.name && f.size === file.file?.size);
      if (idx !== -1) {
        this.files.splice(idx, 1);
      }
      this.removeTempFile(index);
      this.addErrorFile();
      this.onChange(this.files);
    } else {
      this.confirmFileRemoval(index, file.editFile);
    }
  }

  private removeTempFile(index: number): void {
    this.deleteTempFile.emit(index);
  }

  private addErrorFile(): void {
    this.fileListLength = this.listOfPreview.length;
    if (this.listOfPreview.length < this.maxUploadedFiles && this.errorFilePreviews.length) {
      const count = this.maxUploadedFiles - this.listOfPreview.length;
      const res = this.errorFilePreviews.filter((f) => f.maxUpload && !f.extension && !f.size).slice(0, count);
      res.forEach((r) => {
        const idx = this.errorFilePreviews.findIndex((f) => f.timestamp === r.timestamp);
        if (idx !== -1) {
          this.errorFilePreviews.splice(idx, 1);
        }
      });
      this.loadValidFiles(res.map((r) => r.file));
    }
  }

  public removeErrorFile(event: Event, index: number): void {
    this.stopDefault(event);
    this.errorFilePreviews.splice(index, 1);
  }

  public disabledPreview(file: FileModel | ImageModel, index: number): void {
    if (!this.disabled) return;
    this.selectFilePreview(file, index);
  }

  public selectFilePreview(file: FileModel | ImageModel, index: number, data?: DropdownFilePreview): void {
    if (data && data?.previewImage) {
      const ext = data?.name?.split('.') || [];
      this.tempPreview.emit({src: data.previewImage, name: data.name, extension: ext[ext?.length - 1]})
    }
    this.viewTempFilesEmitter.emit(index);
    if (!file) return;
    this.openPreview.emit(file);
  }

  public updateFilePositions(list: DropdownFilePreview[]): void {
    this.listOfPreview = list
      .map((l: DropdownFilePreview, i: number) => {
        if (l?.editFile) {
          l.editFile.position = i + 1;
        }
        return l;
      })
      .sort((a: DropdownFilePreview, b: DropdownFilePreview) => {
        const aPos = a.editFile?.position;
        const bPos = b.editFile?.position;
        if (aPos && bPos) {
          return aPos - bPos;
        }
        return 0;
      });
    const posFiles = this.listOfPreview.map((f) => {
      if (f.editFile) {
        return f.editFile;
      } else {
        if (f.file) {
          return f.file as any;
        }
      }
    });
    this.updateFilePositionsEmitter.emit(posFiles);
    this.onChange(posFiles);
    this.cdr.detectChanges();
  }

  public stopDefault(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
  }

  private autoscrollInit(): void {
    const drake = this.drake;
    if (this.autoscroll?.nativeElement) {
      autoScroll([this.autoscroll?.nativeElement], {
        margin: 200,
        maxSpeed: 4,
        scrollWhenOutside: true,
        autoScroll() {
          return this.down && drake.dragging;
        },
      });
    }
  }

  private confirmFileRemoval(index: number, editFile?: FileModel | ImageModel): void {
    const modalRef = this.modalFacadeService.openModal(NewestConfirmModalComponent, {
      centered: true,
      windowClass: 'confirm-action-modal-v2',
    });
    modalRef.componentInstance.title = this.translateService.instant(this.confirmRemoveText.title);
    modalRef.componentInstance.description = this.translateService.instant(this.confirmRemoveText.description, {
      name: editFile?.name,
    });
    modalRef.componentInstance.button.text = this.translateService.instant('Shared.Button.Yes_delete');
    return modalRef.componentInstance.passConfirm.pipe(take(1)).subscribe(() => {
      modalRef.close();
      this.removingFile(index, editFile);
    });
  }

  private removingFile(index: number, editFile?: FileModel | ImageModel): void {
    if (this.autoSave) {
      const foundIndex = this.editFiles.findIndex((file) => file.id === editFile?.id);
      this.editFiles?.splice(foundIndex, 1);
      this.listOfPreview?.splice(index, 1);
      this.deleteExistingFile.emit(editFile);
    } else {
      const foundIndex = this.editFiles.findIndex((file) => file.id === editFile?.id);
      this.editFiles?.splice(foundIndex, 1);
      this.listOfPreview?.splice(index, 1);
      this.fileDeletion.emit(index);
    }
    this.addErrorFile();
    this.onChange([...this.editFiles, ...this.files]);

    this.cdr.detectChanges();
  }
  // ToDO: check here, error Group named: "imagesAttributes" already exists.
  private initDragulaListener(): void {
    const drag = this.dragulaService.createGroup(this.dragGroup, {
      moves: (el: Element | undefined, container: Element | undefined, handle: Element | undefined) => {
        const outerTrigger = handle?.classList.contains('drag__icon');
        const innerTrigger = handle?.parentElement?.classList.contains('questions__drag-icon');
        return !!(outerTrigger || innerTrigger);
      },
    });
    this.drake = drag.drake;
  }

  private loadValidFiles(files: File[]): void {
    const validFile = this.filteringValidFile(files);
    this.fileLoadingPreprocessing(validFile);

    this.cdr.markForCheck();
  }

  private filteringValidFile(files: File[]): File[] {
    const validFile: File[] = [];
    files.forEach(async (file: File, i: number) => {
      const size = file.size > this.maxUploadedFilesSize;
      const extension = this.invalidExtension(file);
      const maxUpload = i >= this.maxUploadedFiles - this.fileListLength;
      const timestamp = Date.now();
      if (size || extension || maxUpload) {
        this.errorFilePreviews.push({ name: file.name, file, size, extension, maxUpload, timestamp });
      } else {
        validFile.push(file);
        await this.onPreparationPreview(file);
      }
    });
    return validFile;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: (FileModel | ImageModel)[] | File[] = []): void {
    if (this.setFileForWriteValue) {
      this.loadValidFiles(value as File[]);
      return;
    }
    this.listOfPreview = [];

    if (!value?.length) return;

    this.editFiles = value as (FileModel | ImageModel)[];
    this.editFiles.forEach((file: FileModel | ImageModel, i: number) => {
      this.sortTypeFile(file, false, i);
    });
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  private sortTypeFile(file: FileModel | ImageModel, isTemp: boolean, i: number): void {
    if (!file.position) {
      file.position = i + 1;
    }
    if (file.type === EFileType.Image) {
      this.listOfPreview.push({
        type: EFileType.Image,
        previewImage: file.fileName.url,
        previewImageW260: file.fileName.w260?.url || file.fileName.url,
        name: file.name,
        editFile: file,
        isTemp: isTemp,
        isAutoSave: this.autoSave,
      });
    } else {
      this.listOfPreview.push({
        type: EFileType.File,
        editFile: file,
        name: file.name,
        isTemp: isTemp,
        isAutoSave: this.autoSave,
      });
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private invalidExtension(file: File): boolean {
    if (
      file.type &&
      (this.inputAcceptString.includes(String(mime.getExtension(file.type))) ||
        this.inputAcceptString.includes(file.type))
    ) {
      return false;
    } else {
      const ext = file.name.split('.');
      return !this.inputAcceptString.includes(ext[ext.length - 1].toLowerCase());
    }
  }

  private fileLoadingPreprocessing(files: File[]): void {
    if (files.length) {
      this.saveFile(files);
    }
  }

  private saveFile(files: File[]): void {
    if (this.autoSave) {
      this.addNewFilesToExisting.emit(files);
    }

    if (this.newOnly) {
      this.addCreateNewFilesToExisting.emit(files);
      this.files.push(...files);
      this.onChange(this.files);
    } else {
      if (this.autoSave && this.isOnline) {
        this.isLoading = true;
      }
      this.onChange([...this.editFiles, ...files]);
    }
  }

  private checkImageType(file: File): boolean {
    return FileValidators.isImage(file);
  }

  private async setImagePreview(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        resolve(reader.result);
      };
    });
  }

  private async onPreparationPreview(file: File): Promise<void> {
    if (this.checkImageType(file)) {
      const url = await this.setImagePreview(file);
      this.listOfPreview.push({
        type: EFileType.Image,
        previewImage: url,
        previewImageW260: url,
        name: file.name,
        file: file,
        isTemp: true,
        isAutoSave: this.autoSave,
      });
    } else {
      this.listOfPreview.push({
        type: EFileType.File,
        file: file,
        fileType: String(mime.getExtension((file as File).type)),
        name: file.name,
        isTemp: true,
        isAutoSave: this.autoSave,
      });
    }
    this.addNewFile.emit(file);
    this.cdr.detectChanges();
  }
}
