import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {DateAdapterService, MessageModalComponent} from "@atlas-workspace/shared/form";
import {ModalHelpersService, NewestConfirmModalComponent} from "@atlas-workspace/shared/modals";
import {
  acceptedGlobalExtensions, CombinedOffer,
  CreateCombinedOffer,
  CreatedCombinedOfferModel,
  EChangeRequestStatus,
  GroupChangeRequestData,
  offerStatusList,
  SubOfferModel,
  UnitModel,
} from '@atlas-workspace/shared/models';
import {ChangeRequestService, ModalFacadeService, SharedUiStorageService} from '@atlas-workspace/shared/service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {take} from "rxjs/operators";

import {
  ChangeRequestCombineOfferComponent
} from "../change-request-combine-offer/change-request-combine-offer.component";

@UntilDestroy()
@Component({
  selector: 'atl-sub-offer-detail',
  templateUrl: './sub-offer-detail.component.html',
  styleUrls: ['./sub-offer-detail.component.scss'],
})
export class SubOfferDetailComponent implements OnInit {
  @Input() projectId!: string;
  @Input() offer!: CreatedCombinedOfferModel;
  @Input() unitId!: number;
  @Input() unit!: UnitModel;

  @Output() readonly updateList = new EventEmitter<boolean>()

  public form!: FormGroup;
  public buttonDisable = true;
  public readonly statusClasses = offerStatusList;
  public readonly currentDate = new Date();
  public readonly acceptedExtensions = acceptedGlobalExtensions;
  public readonly statusKeys = EChangeRequestStatus;

  constructor(
    private readonly fb: FormBuilder,
    private readonly dateAdapter: DateAdapterService,
    private readonly modalHelpersService: ModalHelpersService,
    private readonly cdr: ChangeDetectorRef,
    private readonly changeRequestService: ChangeRequestService,
    private readonly modalFacadeService: ModalFacadeService,
    private readonly translateService: TranslateService,
    private readonly sharedUiStorageService: SharedUiStorageService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      expirationDate: [''],
      description: [{ value: '', disabled: true }],
      fileResources: [{ value: [], disabled: true }],
    });
    this.setForm();
    this.dateChangeDetection();
  }

  private setForm(): void {
    this.form.patchValue({
      expirationDate: this.offer.expirationDate,
      description: this.offer.description,
      fileResources: this.offer.fileResources,
    });

    if (this.offer.status !== EChangeRequestStatus.OfferExpired) {
      this.form.get('expirationDate')?.disable();
    } else {
      this.form.get('expirationDate')?.enable();
    }
    this.cdr.detectChanges();
  }

  private dateChangeDetection(): void {
    this.form
      .get('expirationDate')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((date) => {
        const expirationDate = this.dateAdapter.toModel(date);
        const status = this.offer.status !== EChangeRequestStatus.OfferExpired;
        const changed = this.offer.expirationDate === expirationDate;
        this.buttonDisable = status ? status : changed;
      });
  }

  public openPreview(index: number): void {
    const files = this.form.get('fileResources')?.value ?? [];
    this.modalHelpersService.previewImages(files, index);
  }

  public subOfferPreview(index: number, offer: SubOfferModel): void {
    this.modalHelpersService.previewImages(offer.fileResources, index);
  }

  public saveOffer(): void {
    const date = this.dateAdapter.toModelYYYYMMDD(this.form.get('expirationDate')?.value);
    this.changeRequestService.updateChangeRequestsCombinedOffer(this.projectId, this.offer.id, date)
      .pipe(take(1))
      .subscribe(offer => {
        this.offer = offer;
        this.updateList.emit();
    });
  }

  private changeStatus(status: EChangeRequestStatus): void {
    this.changeRequestService.changeStatusChangeRequestsCombinedOffer(this.projectId, this.offer.id, status)
      .pipe(take(1))
      .subscribe(offer => {
        this.offer = offer;
        this.updateList.emit();
    });
  }

  private removeOffer(): void {
    this.changeRequestService.removeChangeRequestsCombinedOffer(this.projectId, [this.offer.id]).pipe(take(1)).subscribe(() => {
      this.updateList.emit(true);
    });
  }

  public confirmCancelOffer(): void {
    const confirmModalRef = this.modalFacadeService.openModal(NewestConfirmModalComponent, {
      centered: true,
      windowClass: 'confirm-action-modal-v2',
    });

    confirmModalRef.componentInstance.title = `${this.translateService.instant('Button.Cancel_offer')}?`;
    confirmModalRef.componentInstance.description = this.translateService.instant('Change_request.Offers.Cancel.Title');
    confirmModalRef.componentInstance.button.text = this.translateService.instant('Button.Yes_cancel');
    confirmModalRef.componentInstance.icon = 'basket.svg';

    confirmModalRef.componentInstance.passConfirm.pipe(untilDestroyed(this)).subscribe((v: boolean) => {
      if (v) {
        confirmModalRef.close();
        this.changeStatus(EChangeRequestStatus.OfferCanceled);
      }
    });
  }

  public confirmRemoveOffer(): void {
    const confirmModalRef = this.modalFacadeService.openModal(NewestConfirmModalComponent, {
      centered: true,
      windowClass: 'confirm-action-modal-v2',
    });

    confirmModalRef.componentInstance.title = `${this.translateService.instant('Button.Delete_offer')}?`;
    confirmModalRef.componentInstance.description = this.translateService.instant('Description.Offer_delete_description');
    confirmModalRef.componentInstance.button.text = this.translateService.instant('Shared.Button.Yes_delete');
    confirmModalRef.componentInstance.icon = 'basket.svg';

    confirmModalRef.componentInstance.passConfirm.pipe(untilDestroyed(this)).subscribe((v: boolean) => {
      if (v) {
        confirmModalRef.close();
        this.removeOffer();
      }
    });
  }

  public duplicateOffers(): void {
    const group: GroupChangeRequestData[] = this.offer.subOffers
      .filter(item => item.changeRequest.status !== EChangeRequestStatus.RequestDeclined)
      .map(item => {
      return <GroupChangeRequestData>{
        id: item.changeRequest.id,
        identifier: item.changeRequest.identifier,
        description: '',
        prefillOfferInfo: {
          description: item.description,
          versionNumber: item.versionNumber,
          fileResources: item.fileResources,
          price: item.price
        },
        floorDrawVersions: item.changeRequest?.floorDrawVersions || []
      };
    });
    const combinedOffer = new CombinedOffer(group);
    combinedOffer.pricingComponents = this.offer.pricingComponents;
    combinedOffer.offerDescription = this.offer.description || '';

    if (!combinedOffer.pricingComponents.additionalCosts?.length) {
      combinedOffer.pricingComponents.additionalCosts = [];
    }

    const combine: CreateCombinedOffer = {
      requestsGroupId: this.offer.groupId,
      combinedOffer: combinedOffer
    }
    this.combineOfferModal(combine);
  }

  public combineOfferModal(combine: CreateCombinedOffer): void {
    const modalRef = this.modalFacadeService.openModal(ChangeRequestCombineOfferComponent, {
      centered: true,
      windowClass: 'change-request-combine-offer',
    });

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.identifier = this.unit.identifier;
    modalRef.componentInstance.combine = combine;
    modalRef.componentInstance.projectId = this.projectId;
    modalRef.componentInstance.unit = this.unit;

    modalRef.dismissed.pipe(take(1)).subscribe((value) => {
      if (value) {
        this.updateList.emit(true);
      }
    });
  }

  public showComment(): void {
    const modalRef = this.modalFacadeService.openModal(
      MessageModalComponent,
      this.sharedUiStorageService.modalCreateOffer
    );

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.title = 'Shared.Title.Offer_was_declined';
    modalRef.componentInstance.placeholder = 'Change_request.Decline.Modal.Placeholder';
    modalRef.componentInstance.comment = this.offer.declineReason;
    modalRef.componentInstance.onlyView = true;
  }
}
