import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IDropdownReportsItem } from '@atlas-workspace/shared/models';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import downloadIconNew from '!!raw-loader?!@atlas-workspace/shared/assets/lib/download-icon-new.svg';

import { CustomIconComponent } from '../custom-icon/custom-icon.component';

@Component({
  selector: 'atl-dropdown-reports',
  templateUrl: './dropdown-reports.component.html',
  styleUrls: ['./dropdown-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgbModule, NgbTooltipModule, TranslateModule, CustomIconComponent]
})
export class DropdownReportsComponent {
  @Input() items!: IDropdownReportsItem<any>[];
  @Input() dropdownToggleTranslateKey?: string;
  @Input() placement = "bottom-right";
  @Output() reportOn = new EventEmitter<IDropdownReportsItem<any>>();

  public readonly openDelay = 500;
  public readonly downloadIconNew: string = downloadIconNew;
  public readonly id!: number;
  public isDropdownOpened = false;

  constructor() {
    this.id = Math.random() * 1e18;
  }
}
