import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CroppingImageHelper } from "@atlas-workspace/shared/form";
import {GlobalLoadingMessageService, ModalFacadeService, ToasterService} from '@atlas-workspace/shared/service';
import { TranslateService } from '@ngx-translate/core';

import { InputFileDragDropComponent } from '../input-file-drag-drop/input-file-drag-drop.component';

@Component({
  selector: 'atl-document-drag-and-drop',
  templateUrl: './document-drag-and-drop.component.html',
  styleUrls: ['./document-drag-and-drop.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DocumentDragAndDropComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentDragAndDropComponent extends InputFileDragDropComponent {
  @Input() asUploadButton = false;

  @Output() private readonly filePreviewHandler = new EventEmitter<number>();

  constructor(
    cdr: ChangeDetectorRef,
    modalFacadeService: ModalFacadeService,
    toasterService: ToasterService,
    translateService: TranslateService,
    croppingImageHelper: CroppingImageHelper,
    globalLoadingMessageService: GlobalLoadingMessageService
  ) {
    super(cdr, modalFacadeService, toasterService, translateService, croppingImageHelper, globalLoadingMessageService);
  }

  public onPreviewImageHandler(index: number): void {
    this.filePreviewHandler.emit(index);
  }
}
