<div class="custom-select-dropdown custom-select-dropdown__wrapper">
  <div
    tabindex="0"
    class="custom-select-dropdown__dropdown"
    [class.custom-select-dropdown__dropdown--without-border]="isWithoutBorder"
    (blur)="onCloseModal(); $event.stopImmediatePropagation()"
    (click)="onOpenModal(); $event.stopImmediatePropagation()"
  >
    <span class="custom-select-dropdown__selected">{{ label | translate }}</span>

    <img *ngIf="isShowIcon" class="custom-select-dropdown__arrow" src="/assets/drop-down-arrow.svg" alt="Arrows" />

    <div
      *ngIf="isOpen"
      #containerValuesDropdown
      class="custom-select-dropdown__list"
      [class.open]="isOpen"
      [ngClass]="placement"
    >
      <label
        class="custom-select-dropdown__item"
        *ngFor="let value of listOfValues"
        (click)="onChangeValue(value); $event.stopPropagation()"
      >
        <span> {{ value.title | translate }} </span>
      </label>
    </div>
  </div>
</div>
