<div class="threads__wrapper h-100">
  <section class="threads__section h-100">
    <header role="banner" class="threads__header d-flex align-items-center justify-content-between">
      <h2 class="capitalize fw-500">
        {{
          (scope === scopes.ReclamationThreadView || scope === scopes.ChangeRequestMessagesView)
            ? ('Messages.Inbox' | translate)
            : folderTitle
        }}
      </h2>
      <!-- TODO possibly will be used in future -->
      <!-- <ng-template #ReclamationThreadViewTitle>
        <atl-newest-select-dropdown
          class="threads__header-view-mode"
          [bindTitle]="'title'"
          [bindValue]="'key'"
          [customTitleValue]="false"
          [customTitleTemplate]="false"
          [listOfValues]="reclamationViewMenu"
          [(ngModel)]="listType"
          (ngModelChange)="onListTypeChange($event)"
        ></atl-newest-select-dropdown>
      </ng-template> -->

      <div class="threads__filter" [class.no-threads]="!hasThreads && !totalThreads">
        <ng-container *ngIf="listType !== foldersType.Drafts">
          <div
            [formGroup]="form"
            class="threads__header-action search"
            [ngbTooltip]="'Shared.Entity.Search' | translate"
            [openDelay]="tooltipOpenDelay"
            [disableTooltip]="hideSearchTooltip || !!searchParam"
            placement="bottom"
            triggers="hover touchstart"
          >
            <atl-newest-input
              [class.threads-search__active]="focusedSearch || form.get('search').value.length"
              class="threads-search icon-search-black hover-black"
              formControlName="search"
              [noBorderWhenHasValue]="true"
              [placeholder]="'Input.Search.Placeholder_without_dots'"
              (blurHandler)="handleSearchTooltip(false)"
              (focusHandler)="handleSearchTooltip(true)"
            ></atl-newest-input>
          </div>

          <atl-list-of-threads-filter
            *ngIf="inputScope !== scopes.ChangeRequestMessagesView"
            [showProjectsFilter]="showProjectsFilter"
            [firmId]="firmId"
            [projectId]="projectId"
            [scope]="scope"
            [activeFilters]="filters"
            (getFilteredThreadsEvent)="filterThreadList($event)"
          ></atl-list-of-threads-filter>
        </ng-container>
      </div>
    </header>
    <ng-container *ngIf="listOfThreads.length; then threadsTemplate; else emptyThreadsTemplate"></ng-container>
  </section>

  <router-outlet
    (activate)="isThreadViewComponentEmbedded = true"
    (deactivate)="isThreadViewComponentEmbedded = false"
  ></router-outlet>

  <div *ngIf="!isThreadViewComponentEmbedded && listOfThreads.length; then noSelectedThreadTemplate"></div>
</div>

<ng-template #threadsTemplate>
  <div
    #threadsContainer
    atlRestoreScroll
    *ngIf="listOfThreads.length; else emptyThreadsTemplate"
    class="v-scrollbar threads__thread-wrapper {{ scope }}"
    [class.has-scroll]="threadsContainer.scrollHeight > threadsContainer.clientHeight"
  >
    <div
      *ngFor="
        let thread of listOfThreads;
        index as i;
        let first = first;
        let last = last;
        trackBy: trackBylistOfThreads
      "
      class="threads__thread-item-container"
      (atlInfiniteScroll)="loadMoreThreads(last)"
    >
      <ng-container
        [ngTemplateOutlet]="listType !== foldersType.Drafts ? threadItem : threadDraftItem"
        [ngTemplateOutletContext]="{ thread: thread, first: first }"
      >
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #threadItem let-thread="thread" let-first="first">
  <atl-list-of-threads-item
    [projectId]="projectId"
    [listType]="listType"
    [activeThreadId]="activeThreadId"
    [threadData]="thread"
    [first]="first"
    [scope]="scope"
    [showProjectsFilter]="showProjectsFilter"
    [isAdvancedRequests]="isAdvancedRequests"
    (goToThreadDetail)="goToThreadView($event)"
  ></atl-list-of-threads-item>
</ng-template>

<ng-template #threadDraftItem let-thread="thread" let-first="first">
  <atl-list-of-threads-draft-item
    [currentUser]="currentUser"
    [activeThreadId]="activeThreadId"
    [threadData]="thread"
    [first]="first"
    (openDraftEvent)="openDraftThreadModal($event)"
    (removeDraftEvent)="openRemoveDraft($event)"
  ></atl-list-of-threads-draft-item>
</ng-template>

<ng-template #emptyThreadsTemplate>
  <ng-container *ngIf="!searchParam; else noSearchResult">
    <div class="threads__empty">
      <atl-custom-icon class="threads__empty-icon mb-16" [icon]="emptyThreadIcon"></atl-custom-icon>
      <div class="threads__empty-description">
        <h2 class="threads__empty-description-title fz-16 lh-24 fw-500">{{ 'Threads.No_threads' | translate }}</h2>
        <p class="threads__empty-description-subtitle fz-14 lh-24 mt-4">
          {{ 'Threads.Write_your_first_message' | translate }}
        </p>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #noSelectedThreadTemplate>
  <div class="threads__no-selected flex-column-centered">
    <ng-template [ngIf]="listType === foldersType.Drafts">
      <p class="threads__no-selected-title fz-14 lh-16 fw-500">
        {{ 'Shared.Threads.Select_message_to_edit_or_send_draft' | translate }}
      </p>
    </ng-template>
    <ng-template [ngIf]="listType !== foldersType.Drafts">
      <p class="threads__no-selected-title fz-14 lh-16 fw-500">
        {{ 'Messages.Write_your_fist_message_start_the_chat' | translate }}
      </p>
      <p *ngIf="scope === scopes.Global" class="threads__no-selected-subtitle fz-14 lh-24 fw-400 mt-8">
        {{ 'Threads.or_write_new_message' | translate }}
      </p>
    </ng-template>
  </div>
</ng-template>

<ng-template #noSearchResult>
  <div class="threads__no-result flex-column-centered f-family-sfpro">
    <img [src]="noResult.icon" alt="icon" />
    <h2 class="fw-500 lh-24 fz-16 mt-16">{{ noResult.title | translate }}</h2>
    <p class="fw-400 lh-24 fz-14 text-align-center mt-4">{{ noResult.description | translate }} "{{ searchParam }}"</p>
  </div>
</ng-template>
