<div ngbDropdown [placement]="placement" (openChange)="isDropdownOpened = $event">
  <button
    ngbDropdownToggle
    type="button"
    class="dropdown__report-button newest-button newest-button-sm button-gray"
    [ngClass]="{ 'with-toggle-text': dropdownToggleTranslateKey, 'opened': isDropdownOpened }"
    [id]="id"
    [ngbTooltip]="'Shared.Entity.Download' | translate"
    [openDelay]="openDelay"
    [disableTooltip]="isDropdownOpened"
    triggers="hover touchstart"
  >
    <span *ngIf="dropdownToggleTranslateKey" class="fz-14 fw-500 lh-24 mr-8">
      {{ dropdownToggleTranslateKey | translate }}
    </span>
    <atl-custom-icon [icon]="downloadIconNew" [size]="16"></atl-custom-icon>
  </button>
  <div ngbDropdownMenu [attr.aria-labelledby]="id" [ngClass]="placement">
    <ng-template ngFor let-item [ngForOf]="items">
      <div
        ngbDropdownItem
        class="cursor-pointer"
        [class.pointer-events-none]="item.commingSoon"
        (click)="reportOn.emit(item)"
      >
        <img [src]="item.icon" class="mr-8" alt="icon" />
        <div>
          <p class="fz-14 lh-24 fw-400 text-black-960">{{ item.labelTranslateKey | translate }}</p>
          <p *ngIf="item.commingSoon" class="coming_soon fz-12 lh-16 fw-400">{{ 'Entity.Coming_soon' | translate }}</p>
        </div>
      </div>
    </ng-template>
  </div>
</div>
