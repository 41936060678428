import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class GlobalLoadingMessageService {
  private _tooltipText = '';
  private readonly _showTooltipHandler$ = new BehaviorSubject<boolean>(false);
  public showTooltipHandler$ = this._showTooltipHandler$.asObservable();

  showTooltip(text: string): void {
    this._tooltipText = text;
    this._showTooltipHandler$.next(true);
  }

  showTooltipProcessing(): void {
    this._tooltipText = 'Shared.Title.Processing_file';
    this._showTooltipHandler$.next(true);
  }

  closeTooltip(): void {
    this._showTooltipHandler$.next(false);
  }

  get tooltipText(): string {
    return this._tooltipText;
  }
}
