<!--
  ******************************************************
  @Cypress
  - interaction with tabs identifiers ('id' attr)
  ******************************************************
-->
<div class="fw-500 fz-16 simple-tabs simple-tabs__wrapper">
  <ng-container *ngIf="scrollControl">
    <div *ngIf="arrowLeft" class="simple-tabs__arrow left" (click)="scrolling($event, 'left')">
      <img src="assets/arrow-gray-down.svg" alt="arrow-left" />
    </div>
  </ng-container>
  <div
    #scroll
    class="simple-tabs__list h-scrollbar"
    [ngClass]="{
      'h-scrollbar--hidden': scrollControl,
      'has-horizontal-scroll': scroll.offsetWidth < scroll.scrollWidth
    }"
    (scroll)="scrollEvent()"
  >
    <ng-container *ngIf="routeNavigation; else simpleSelecting">
      <ng-container *ngFor="let item of tabsData; let first = first; let last = last">
        <ng-template [ngIf]="!item.hidden">
          <button
            *ngIf="item.disabled; else notDisabledItem"
            [attr.id]="item?.id"
            class="button-accessibility simple-tabs__item"
            routerLinkActive="simple-tabs__item--active"
            [ngClass]="{ disabled: item.disabled }"
          >
            {{ skipTranslation ? item.name : (item.name | translate) }}
          </button>

          <ng-template #notDisabledItem>
            <button
              [attr.id]="item?.id"
              (click)="selectTab(item.name, item.disabled, item.id)"
              class="button-accessibility simple-tabs__item"
              [routerLink]="item.route"
              [queryParams]="queryParams"
              routerLinkActive="simple-tabs__item--active"
              [attr.data-cy]="first ? 'cy-unit-tab' : 'cy-unit-group-tab'"
              [routerLinkActiveOptions]="{ exact: exact }"
              [ngClass]="{ disabled: item.disabled }"
            >
              <span
                *ngIf="(first || last) && listenVisibility"
                atlIsVisible
                [threshold]="0.9"
                (visible)="onItemVisibility(first, $event)"
                class="dot-left invisible"
                >.</span
              >
              <ng-container *ngIf="!truncateLimit; else trancateBlock">
                {{ skipTranslation ? item.name : (item.name | translate) }}
              </ng-container>
              <ng-template #trancateBlock>
                {{
                  skipTranslation
                    ? (item.name | truncate: truncateLimit)
                    : (item.name | translate | truncate: truncateLimit)
                }}
              </ng-template>
              <ng-container *ngIf="item.counter">
                <div class="simple-tabs__item-counter">
                  {{ item.counter > maxCounterNum ? maxCounterNum + '+' : item.counter }}
                </div>
              </ng-container>
            </button>
          </ng-template>
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-template #simpleSelecting>
      <ng-container *ngFor="let item of tabsData; index as i; let first = first; let last = last">
        <ng-template [ngIf]="!item.hidden">
          <ng-container *ngIf="!useTooltip; else withToolTip">
            <button
              *ngIf="!hideWithZeroCounters || item.totalCount > 0"
              [attr.id]="item?.id"
              class="button-accessibility simple-tabs__item"
              (click)="selectTab(item.name, item.disabled, item.id)"
              [class.simple-tabs__item--active]="!withId ? item.name === activeTab : item.id === activeId"
              [ngClass]="{ disabled: item.disabled }"
            >
              <img *ngIf="item.disabled" src="/assets/admin_disable_menu.svg" alt="disabled item menu" />
              <ng-container *ngIf="!truncateLimit">
                {{ skipTranslation ? item.name : (item.name | translate) }}
                <ng-container *ngIf="!hideCounters">
                  <span
                    *ngIf="item.totalCount >= 0"
                    class="counter ml-8"
                    [class.counter--active]="item.name === activeTab || withId && item.id === activeTab"
                    >{{ moreCount && item.totalCount >= 100 ? '99+' : item.totalCount }}
                  </span>
                </ng-container>
                <span *ngIf="item.displayNewMark" class="new-item-mark"></span>
              </ng-container>
              <ng-container *ngIf="truncateLimit">
                <span
                *ngIf="(first || last) && listenVisibility"
                atlIsVisible
                [threshold]="0.9"
                (visible)="onItemVisibility(first, $event)"
                class="dot-left invisible"
                >.</span
              >
                {{
                  skipTranslation
                    ? (item.name | truncate: truncateLimit)
                    : (item.name | translate | truncate: truncateLimit)
                }}
              </ng-container>
              <ng-container *ngIf="item.counter">
                <div class="simple-tabs__item-counter">
                  {{ item.counter > maxCounterNum ? maxCounterNum + '+' : item.counter }}
                </div>
              </ng-container>
            </button>
          </ng-container>
        </ng-template>

        <ng-template #withToolTip>
          <div class="simple-tabs__tooltip-item">
            <atl-tooltip
              [text]="item.tooltip | translate"
              [isDisplayTooltip]="!!item.tooltip && item.disabled && item.isShowTooltip"
              [horizontalShiftTooltipOn]="shiftTooltip"
              class="simple-tabs__tooltip"
            >
              <div
                [attr.id]="item?.id"
                (click)="selectTab(item.name, item.disabled, item.id)"
                [class.simple-tabs__item--active]="item.name === activeTab || withId && item.id === activeTab"
                class="simple-tabs__item"
                [ngClass]="{ disabled: item.disabled }"
              >
                <img *ngIf="item.disabled" src="/assets/admin_disable_menu.svg" alt="disabled item menu" />
                <span
                  (mouseenter)="onMouseEnter(item)"
                  (mouseleave)="onMouseOut(item)"
                  [class.white-space-nowrap]="inlineTabs"
                  [innerHTML]="skipTranslation ? item.name : (item.name | translate)"
                >
                </span>
                <ng-container *ngIf="item.counter">
                  <div class="simple-tabs__item-counter">
                    {{ item.counter > maxCounterNum ? maxCounterNum + '+' : item.counter }}
                  </div>
                </ng-container>
              </div>
            </atl-tooltip>
          </div>
        </ng-template>
      </ng-container>
    </ng-template>
  </div>
  <ng-container *ngIf="scrollControl">
    <div *ngIf="arrowRight" class="simple-tabs__arrow right" (click)="scrolling($event, 'right')">
      <img src="assets/arrow-gray-down.svg" alt="arrow-right" />
    </div>
  </ng-container>
</div>
