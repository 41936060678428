import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  isLightHexColor,
  whiteLabelHexColorInputMaskPatternConfig,
  whiteLabelHexColorInputPattern,
  whiteLabelSettingsDefaultColorPreviewParams,
} from '@atlas-workspace/shared/service';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';

// todo: picker to be announced
@Component({
  selector: 'atl-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, NgxMaskDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerComponent implements ControlValueAccessor {
  public value!: string;
  public disabled = false;
  public mask = 'o{6}';
  public readonly maskPattern = whiteLabelHexColorInputMaskPatternConfig;
  private readonly pattern = whiteLabelHexColorInputPattern;
  private readonly defaultColorPreviewParams = whiteLabelSettingsDefaultColorPreviewParams;

  protected onChange: (value: string | null | undefined) => void = () => null;
  protected onTouched: () => void = () => null;

  @Output() onblur = new EventEmitter<unknown>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  writeValue(value: string): void {
    this.setValue(value);
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  onBlur(): void {
    this.onTouched();
    this.onblur.emit();
  }

  changeValue(value: string): void {
    this.setValue(value);
    this.onChange(this.value);
    this.cdr.detectChanges();
  }

  private setValue(_value: unknown): void {
    this.value = typeof _value === 'string' ? _value : '';
  }

  get isValid(): boolean {
    return this.pattern.test(this.value);
  }

  get previewColor(): string {
    return this.isValid ? `#${this.value}` : this.defaultColorPreviewParams.backgroundColor;
  }

  get previewBorderColor(): string {
    return this.isValid && !isLightHexColor(this.value) ? `#${this.value}` : this.defaultColorPreviewParams.borderColor;
  }
}
