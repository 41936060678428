import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ETimeFormat } from '@atl-libs-shared-pipes/time-passed-ago/interfaces/time-passed-ago';
import { EThreadFolders, EThreadScope, ThreadModel } from '@atlas-workspace/shared/models';

@Component({
  selector: 'atl-list-of-threads-item',
  templateUrl: './list-of-threads-item.component.html',
  styleUrls: ['./list-of-threads-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListOfThreadsItemComponent {
  @Input() set threadData(value: ThreadModel) {
    this.thread = value;
  }
  @Input() public first!: boolean;
  @Input() private projectId!: number;
  @Input() private listType!: EThreadFolders;
  @Input() public activeThreadId: number | null = null;
  @Input() public scope!: EThreadScope;
  @Input() public showProjectsFilter = false;
  @Input() public isAdvancedRequests: boolean | null = null;

  @Output() public readonly goToThreadDetail = new EventEmitter<number | string>();

  public passedTimeFormats = ETimeFormat;
  public thread!: ThreadModel;

  public readonly scopes: typeof EThreadScope = EThreadScope;

  public readonly dotDivider = ' · ';
  public readonly tooltipOpenDelay = 500;

  goToThreadView(id: number): void {
    this.goToThreadDetail.emit(id);
  }
}
