import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[atlFileDragDrop]',
})
export class FileDragNDropDirective {
  @Output() private readonly filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  @Output() private readonly hovered: EventEmitter<boolean> = new EventEmitter();
  @Output() private readonly dropEvent: EventEmitter<DragEvent> = new EventEmitter();

  @HostBinding('style.background') private background = '';
  @HostBinding('style.border') private borderStyle = '';
  @HostBinding('style.border-radius') private borderRadius = '8px';

  private dragCounter = 0;

  @HostListener('dragover', ['$event']) public onDragOver(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.borderStyle = '1px dashed var(--color-blue-930)';
    this.hovered.emit(true);
  }

  @HostListener('dragenter', ['$event']) public onDragEnter(e: DragEvent): void {
    this.dragCounter++;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(e: DragEvent): void {
    this.dragCounter--;

    setTimeout(() => {
      if (this.dragCounter === 0) {
        this.clearStyles();
        this.hovered.emit(false);
      }
    }, 50);
  }

  @HostListener('drop', ['$event']) public onDrop(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter = 0;

    const files = e.dataTransfer?.files;
    this.clearStyles();
    this.hovered.emit(false);
    if (files) {
      this.filesChangeEmiter.emit(Array.from(files));
      this.dropEvent.emit(e);
    }
  }

  private clearStyles(): void {
    this.background = '';
    this.borderStyle = '';
  }
}
