/* eslint-disable max-len */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { convertHeicToPng } from '@atlas-workspace/shared/form';
import {
  editorOnlyImageTypes,
  ITextEditorInitConfig,
  newEditorImageTypes,
  newEditorInvalidTypes,
  RegExpHelper,
} from '@atlas-workspace/shared/models';
import { TranslateService } from '@ngx-translate/core';
import mime from 'mime';
import { Editor } from 'tinymce';

export function hasImg(content?: string): number | undefined {
  if (!content) return undefined;
  const imgTagRegex = /<img\b[^>]*>/gi;
  let count = 0;
  let match;
  while ((match = imgTagRegex.exec(content)) !== null) {
    count++;
  }
  return count;
}

export function extractFileFromContent(content: string): string | null {
  const hasText = extractTextFromHTML(content);
  if (hasText) return null;
  const imgRegex = /<img\s+[^>]*src\s*=\s*["']((blob:|https?:\/\/)[^"']+)["'][^>]*>/;
  const match = content.match(imgRegex);
  if (match && match[1]) {
    return match[1];
  }
  return null;
}

function extractText(element: any): string {
  let text = '';
  if (element.children.length === 0) {
    text += element.textContent;
  } else {
    for (let i = 0; i < element.children.length; i++) {
      text += extractText(element.children[i]);
    }
  }
  return text;
}

function extractTextFromHTML(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const allElements = doc.body.querySelectorAll('*');
  return Array.from(allElements)
    .filter((element) => {
      return element.children.length === 0;
    })
    .map((element) => {
      return extractText(element);
    })
    .join(' ');
}

export async function getFileDataFromBlobURL(blobURL: string): Promise<File | null> {
  try {
    const response = await fetch(blobURL);
    if (!response.ok) {
      throw new Error(`Error load file: ${response.statusText}`);
    }
    const fileData = await response.blob();
    return blobToFile(fileData);
  } catch (error) {
    return null;
  }
}

export function blobToFile(blob: Blob): File {
  const mimeType = blob.type;
  const ext = mime.getExtension(mimeType);
  const name = Date.now() + '.' + ext;
  return new File([blob], name, { type: mimeType });
}

export class TextEditorData {
  public static fileStyle = `
  @font-face {
    font-family: "SF Pro";
    src: url("/assets/fonts/SF-Pro-Text-Regular.otf") format("opentype");
  }

  html {
    overflow-x: hidden;
    overflow-y: -moz-scrollbars-vertical;
    overflow-y: auto;
    scrollbar-color: #9fa7af transparent;
    scrollbar-width: thin;
    cursor: text;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  html::-webkit-scrollbar {
    height: 30px;
    width: 5px;
  }

  html::-webkit-scrollbar-track {
    width: 2px;
  }

  html::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #9fa7af;
    border-bottom: 4px transparent solid;
    border-radius: 5px;
    border-top: 4px transparent solid;
  }

  body {
    font-size: 12pt;
    min-height: calc(100% - 108px);
    margin: 0 24px;
    line-height: 24px;
    src: url('/assets/fonts/SF-Pro-Text-Regular.otf') format("opentype");
    * {
      font-family: 'SF Pro' !important;
    }
  }

  body::before {
    font-family: 'SF Pro' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #9fa7af !important;
  }

  body:focus::before {
    color: transparent !important;
  }

  span[data-mce-style="font-size: 36pt;"] {
     line-height: 72px;
  }

  .mce-content-body {
    margin-top: 1em;
    overflow-x: hidden;
    overflow-y: -moz-scrollbars-vertical;
    overflow-y: auto;
  }

  .mce-content-body::-webkit-scrollbar {
    height: 30px;
    width: 5px;
  }

  .mce-content-body::-webkit-scrollbar-track {
    width: 2px;
  }

  .mce-content-body::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #9fa7af;
    border-bottom: 4px transparent solid;
    border-radius: 5px;
    border-top: 4px transparent solid;
  }

  .mce-content-body p {
    margin: 0;
  }

  .input-file__preview {
    display: flex;
    flex-shrink: 0;
    z-index: 2;
    max-width: 650px;
    overflow: hidden;
    flex-wrap: wrap;
    padding: 14px 16px 0;
    user-select: none;
  }
  .input-file__item-preview {
    padding: 0 8px;
    position: relative;
    height: 80px;
    z-index: 2;
    max-width:200px;
  }
  .input-file__image {
    width: 192px;
    height: 64px;
    object-fit: cover;
    border-radius: 8px;
  }
  .input-file__remove-image-wrapper {
    position: absolute;
    left: 170px;
    bottom: 22px;
    background-color: #fff;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .input-file__remove-image-wrapper>.input-file__remove-image {
    position: initial;
  }
  .input-file__remove-file, .input-file__remove-image {
    width: 16px;
    height: 16px;
    position: relative;
    bottom: 30px;
    left: 155px;
    cursor: pointer;
  }

  .input-file__remove-image {
    left: 166px;
  }

  .input-file__title {
    color: #0F2438;
    font-size: 12px;
  }

  .input-file__title--upload {
    position: relative;
    bottom: 28px;
    z-index: 2;
    left: 200px;
    cursor: pointer;
  }
  .input-file-invalid {
    border: 1px solid red;
  }
  .file-card__wrapper {
    box-sizing: border-box;
    padding: 10px 12px 10px;
    width: 192px;
    height: 64px;
    background-color: #f3f4f5;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.5s ease-in-out background-color, 0.5s ease-in-out color;
  }
  .file-card__info {
    display: flex;
    height: 58px;
    flex-direction: column;
    justify-content: space-between;
  }
  .file-card__file-name {
    transition: 0.5s ease-in-out color;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'SF Pro', serif;
    font-weight: 500;
    white-space: nowrap;
  }
  .file-card__download {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .file-card__download > div {
    display: flex;
    align-items: center;
  }

  .file-card__file-type {
    padding: 2px 8px;
    background-color: white;
    border-radius: 6px;
    font-family: 'SF Pro', serif;
    font-weight: 500;
  }
  .file-card__file-size {
    margin-left: 8px;
    color: #9fa7af;
    font-family: 'SF Pro', serif;
    font-weight: 500;

  }

  .file-card__file-icon {
    width: 18px;
    height: 18px;
  }

  .newest-document-card-disable-gap {
    height: auto !important;
  }

  .newest-document-card__preview {
    justify-content: unset !important;
  }

  .newest-document-card__item-preview {
    width: fit-content !important;
    min-height: 56px !important;
  }

  .newest-document-card__wrapper {
    position: relative;
    padding: 11px 16px 13px 11px;
    width: 192px;
    height: 56px;
    background-color: #ffffff;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #ebeef0;
    box-sizing: border-box;
  }

  .newest-document-card__wrapper:hover {
    border: 1px solid #ced5da;
    box-shadow: 0 1px 4px rgba(23, 28, 38, 0.08);
  }

  .newest-document-card__wrapper--active {
    border: 1px solid #ced5da;
    box-shadow: 0 1px 4px rgba(23, 28, 38, 0.08);
  }

  .newest-document-card__info {
    display: flex;
  }

  .newest-document-card__file-name {
    font-family: 'SF Pro', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #14181F;
  }

  .newest-document-card__extension {
    margin-right: 1px;
    width: 32px;
    height: 32px;
  }

  .newest-document-card__file-size {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #686E79;
    font-family: 'SF Pro', serif;
  }

  .newest-document-card__image-container {
    margin-right: 11px;
    width: 31px;
    height: 31px;
    border: 1px solid #ebeef0;
    border-radius: 8px;
  }

  .newest-document-card__icon-control {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    display: none;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background-color: #F7F7F8;
  }

  .newest-document-card__icon-control-download {
    right: 12px;
  }

  .newest-document-card__icon-control-download atl-custom-icon span svg path {
    stroke: #777D88;
  }

  .newest-document-card__icon-control-download:hover > atl-custom-icon span svg path {
    stroke: #14181F;
  }

  .newest-document-card__icon-control-trash {
    right: 39px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background-color: #F7F7F8;
  }

  .newest-document-card__icon-control-trash atl-custom-icon span {
    width: 16px;
    height: 16px;
    display: block;
  }

  .newest-document-card__icon-control-trash--specific-position {
     right: 12px;
  }

  .newest-document-card__icon-control-trash:hover > atl-custom-icon span svg path {
    stroke: #e11900!important;
  }

  .newest-document-card__wrapper:hover .newest-document-card__icon-control {
    display: flex;
  }
  `;
  // ToDO: these styles will be updated in UI update task
  private static introductionStyle = `
  @font-face {
    font-family: "SF Pro";
    src: url("/assets/fonts/SF-Pro-Text-Regular.otf") format("opentype");
  }
  body {
    font-size: 12pt;
    min-height: 300px;
    margin: 0 24px;
    font-family: 'SF Pro', serif !important;
    color: #14181F;
  }

  body::before {
    font-family: 'SF Pro', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #9196A1 !important;
  }

  body:focus::before {
    color: transparent !important;
  }

  body img {
    float: none!important;
    clear: both;
  }
  `;

  public static fileStyleTextarea = `
  @font-face {
    font-family: "SF Pro";
    src: local("SF Pro"), url("/assets/fonts/SF-Pro-Text-Regular.otf") format("opentype");
  }

  html {
    overflow-x: hidden;
    overflow-y: -moz-scrollbars-vertical;
    overflow-y: auto;
    scrollbar-color: #9fa7af transparent;
    scrollbar-width: thin;
    cursor: text;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  html::-webkit-scrollbar {
    height: 30px;
    width: 5px;
  }

  html::-webkit-scrollbar-track {
    width: 2px;
  }

  html::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #9fa7af;
    border-bottom: 4px transparent solid;
    border-radius: 5px;
    border-top: 4px transparent solid;
  }

  body {
    font-size: 14px;
    color: #14181F;
    margin: 0!important;
    line-height: 24px;
    font-family: 'SF Pro' !important;
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
    padding: 4px 6px 0 6px !important;
  }

  body::before {
    font-family: 'SF Pro' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #14181F !important;
    left: 6px !important;
  }

  body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    color: #777D88 !important;
  }


  body:focus::before {
    color: transparent !important;
  }
  .mce-content-body {
    margin-top: 1em;
  }

  span[data-mce-style="font-size: 36pt;"] {
     line-height: 72px;
  }

  .mce-content-body::-webkit-scrollbar {
    height: 30px;
    width: 5px;
  }

  .mce-content-body::-webkit-scrollbar-track {
    width: 2px;
  }

  .mce-content-body::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #9fa7af;
    border-bottom: 4px transparent solid;
    border-radius: 5px;
    border-top: 4px transparent solid;
  }

  .mce-content-body p {
    margin: 0;
  }

  .input-file__preview {
    display: flex;
    flex-shrink: 0;
    z-index: 2;
    max-width: 650px;
    overflow: hidden;
    flex-wrap: wrap;
    padding: 14px 16px 0;
    user-select: none;
  }
  .input-file__item-preview {
    padding: 0 8px;
    position: relative;
    height: 80px;
    z-index: 2;
    max-width:200px;
  }
  .input-file__image {
    width: 192px;
    height: 64px;
    object-fit: cover;
    border-radius: 8px;
  }
  .input-file__remove-file, .input-file__remove-image {
    width: 16px;
    height: 16px;
    position: relative;
    bottom: 30px;
    left: 155px;
    cursor: pointer;
  }

  .input-file__remove-image {
    left: 166px;
  }

  .input-file__title {
    color: #0F2438;
    font-size: 12px;
  }

  .input-file__title--upload {
    position: relative;
    bottom: 28px;
    z-index: 2;
    left: 200px;
    cursor: pointer;
  }
  .input-file-invalid {
    border: 1px solid red;
  }
  .file-card__wrapper {
    box-sizing: border-box;
    padding: 10px 12px 10px;
    width: 192px;
    height: 64px;
    background-color: #f3f4f5;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.5s ease-in-out background-color, 0.5s ease-in-out color;
  }
  .file-card__info {
    display: flex;
    height: 58px;
    flex-direction: column;
    justify-content: space-between;
  }
  .file-card__file-name {
    transition: 0.5s ease-in-out color;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'SF Pro', serif;
    font-weight: 500;
    white-space: nowrap;
  }
  .file-card__download {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .file-card__download > div {
    display: flex;
    align-items: center;
  }

  .file-card__file-type {
    padding: 2px 8px;
    background-color: white;
    border-radius: 6px;
    font-family: 'SF Pro', serif;
    font-weight: 500;
  }
  .file-card__file-size {
    margin-left: 8px;
    color: #9fa7af;
    font-family: 'SF Pro', serif;
    font-weight: 500;

  }

  .file-card__file-icon {
    width: 18px;
    height: 18px;
  }

  .newest-document-card-disable-gap {
    height: auto !important;
  }

  .newest-document-card__preview {
    justify-content: unset !important;
  }

  .newest-document-card__item-preview {
    width: fit-content !important;
    min-height: 56px !important;
  }

  .newest-document-card__wrapper {
    position: relative;
    padding: 11px 16px 13px 11px;
    width: 192px;
    height: 56px;
    background-color: #ffffff;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #ebeef0;
    box-sizing: border-box;
  }

  .newest-document-card__wrapper:hover {
    border: 1px solid #ced5da;
    box-shadow: 0 1px 4px rgba(23, 28, 38, 0.08);
  }

  .newest-document-card__wrapper--active {
    border: 1px solid #ced5da;
    box-shadow: 0 1px 4px rgba(23, 28, 38, 0.08);
  }

  .newest-document-card__info {
    display: flex;
  }

  .newest-document-card__file-name {
    font-family: 'SF Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #14181F;
  }

  .newest-document-card__extension {
    margin-right: 11px;
    width: 32px;
    height: 32px;
  }

  .newest-document-card__file-size {
    font-family: 'SF Pro', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #686E79;
  }

  .newest-document-card__image-container {
    margin-right: 12px;
    width: 32px;
    height: 32px;
    border: 1px solid #ebeef0;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .newest-document-card__icon-control {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    display: none;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background-color: #F7F7F8;
  }

  .newest-document-card__icon-control-download {
    right: 12px;
  }

  .newest-document-card__icon-control-download atl-custom-icon span svg path {
    stroke: #777D88;
  }

  .newest-document-card__icon-control-download:hover > atl-custom-icon span svg path {
    stroke: #14181F;
  }

  .newest-document-card__icon-control-trash {
    right: 39px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background-color: #F7F7F8;
  }

  .newest-document-card__icon-control-trash atl-custom-icon span {
    width: 16px;
    height: 16px;
    display: block;
  }

  .newest-document-card__icon-control-trash--specific-position {
     right: 12px;
  }

  .newest-document-card__icon-control-trash:hover > atl-custom-icon span svg path {
    stroke: #e11900!important;
  }

  .newest-document-card__wrapper:hover .newest-document-card__icon-control {
    display: flex;
  }
  `;

  public static fileStyleThreads = `
  @font-face {
    font-family: "SF Pro";
    src: url("/assets/fonts/SF-Pro-Text-Regular.otf") format("opentype");
  }

  html {
    overflow-x: hidden;
    overflow-y: -moz-scrollbars-vertical;
    overflow-y: auto;
    scrollbar-color: #9fa7af transparent;
    scrollbar-width: thin;
    cursor: text;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  html::-webkit-scrollbar {
    height: 30px;
    width: 5px;
  }

  html::-webkit-scrollbar-track {
    width: 2px;
  }

  html::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #9fa7af;
    border-bottom: 4px transparent solid;
    border-radius: 5px;
    border-top: 4px transparent solid;
  }

  body {
    font-size: 12pt;
    line-height: 24px;
    src: url('/assets/fonts/SF-Pro-Text-Regular.otf') format("opentype");
    * {
      font-family: 'SF Pro' !important;
    }
  }

  body::before {
    font-family: 'SF Pro' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #8F96A3 !important;
    left: 16px !important;
  }

  .mce-content-body {
    color: #14181F;
    font-size: 14px;
    font-family: 'SF Pro', sans-serif !important;
    min-height: 24px !important;
    overflow-x: hidden;
    overflow-y: -moz-scrollbars-vertical;
    overflow-y: auto;
    margin: 0!important;
    padding: 16px 8px 8px;
  }

  .mce-content-readonly {
    cursor: not-allowed;
  }

  .mce-content-body::-webkit-scrollbar {
    height: 30px;
    width: 5px;
  }

  .mce-content-body::-webkit-scrollbar-track {
    width: 2px;
  }

  .mce-content-body::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #9fa7af;
    border-bottom: 4px transparent solid;
    border-radius: 5px;
    border-top: 4px transparent solid;
  }

  .mce-content-body p {
    margin: 0 16px;
    width: fit-content;
    max-width: calc(100% - 32px);
    min-width: 10px;
  }

  .mce-content-body p *[data-uid] {
    display: inline-block;
    font-family: 'SF Pro', sans-serif;
    font-style: initial;
    font-weight: initial;
    font-size: inherit;
    line-height: inherit;
    color: #0055ff;
    background: #E5EEFF;
    padding: 0px 2px;
    border-radius: 4px;
    margin-left: 3px;
  }
  .mce-content-body *[data-uid][contentEditable=false] {
    cursor: context-menu;
    outline: none;
  }

  .input-file__preview {
    display: flex;
    flex-shrink: 0;
    z-index: 2;
    overflow: hidden;
    flex-wrap: wrap;
    padding: 12px 16px;
    gap: 12px;
    user-select: none;
  }
  .input-file__item-preview {
    position: relative;
    z-index: 2;
    max-width:200px;
  }
  .input-file__image {
    width: 192px;
    height: 64px;
    object-fit: cover;
    border-radius: 8px;
  }
  .input-file__remove-image-wrapper {
    position: absolute;
    left: 170px;
    bottom: 22px;
    background-color: #fff;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .input-file__remove-image-wrapper>.input-file__remove-image {
    position: initial;
  }
  .input-file__remove-file, .input-file__remove-image {
    width: 16px;
    height: 16px;
    position: relative;
    bottom: 30px;
    left: 155px;
    cursor: pointer;
  }

  .input-file__remove-image {
    left: 166px;
  }

  .input-file__title {
    color: #0F2438;
    font-size: 12px;
  }

  .input-file__title--upload {
    position: relative;
    bottom: 28px;
    z-index: 2;
    left: 200px;
    cursor: pointer;
  }
  .input-file-invalid {
    border: 1px solid red;
  }
  .file-card__wrapper {
    box-sizing: border-box;
    padding: 10px 12px 10px;
    width: 192px;
    height: 64px;
    background-color: #f3f4f5;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.5s ease-in-out background-color, 0.5s ease-in-out color;
  }
  .file-card__info {
    display: flex;
    height: 58px;
    flex-direction: column;
    justify-content: space-between;
  }
  .file-card__file-name {
    transition: 0.5s ease-in-out color;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'SF Pro', serif;
    font-weight: 500;
    white-space: nowrap;
  }
  .file-card__download {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .file-card__download > div {
    display: flex;
    align-items: center;
  }

  .file-card__file-type {
    padding: 2px 8px;
    background-color: white;
    border-radius: 6px;
    font-family: 'SF Pro', serif;
    font-weight: 500;
  }
  .file-card__file-size {
    margin-left: 8px;
    color: #9fa7af;
    font-family: 'SF Pro', serif;
    font-weight: 500;

  }

  .file-card__file-icon {
    width: 18px;
    height: 18px;
  }

  .newest-document-card-disable-gap {
    height: auto !important;
  }

  .newest-document-card__preview {
    justify-content: unset !important;
  }

  .newest-document-card__item-preview {
    width: fit-content !important;
    min-height: 56px !important;
  }

  .newest-document-card__wrapper {
    position: relative;
    padding: 12px;
    width: 192px;
    height: 56px;
    background-color: #ffffff;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #ebeef0;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
  }

  .newest-document-card__wrapper:hover {
    border: 1px solid #ced5da;
    box-shadow: 0 1px 4px rgba(23, 28, 38, 0.08);
  }

  .newest-document-card__wrapper--active {
    border: 1px solid #ced5da;
    box-shadow: 0 1px 4px rgba(23, 28, 38, 0.08);
  }

  .newest-document-card__info {
    display: flex;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 32px;
    box-sizing: border-box;
  }

  .newest-document-card__file-name-wr {
    width: calc(100% - 50px);
  }

  .newest-document-card__file-name {
    font-family: 'SF Pro', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #14181F;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .newest-document-card__extension {
    margin-right: 11px;
    width: 32px;
    height: 32px;
  }

  .newest-document-card__file-size {
    font-family: 'SF Pro', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #686E79;
  }

  .newest-document-card__image-container {
    margin-right: 12px;
    width: 32px;
    height: 32px;
    border: 1px solid #ebeef0;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .newest-document-card__icon-control {
    display: none;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background-color: #F7F7F8;
  }

  .newest-document-card__icon-control.mobile {
    display: flex;
  }

  .newest-document-card__icon-control-download {
    right: 12px;
  }

  .newest-document-card__icon-control-download atl-custom-icon span svg path {
    stroke: #777D88;
  }

  .newest-document-card__icon-control-download:hover > atl-custom-icon span svg path {
    stroke: #14181F;
  }

  .newest-document-card__icon-control-trash {
    right: 12px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  .newest-document-card__icon-control-trash atl-custom-icon span {
    width: 16px;
    height: 16px;
    display: block;
  }

  .newest-document-card__icon-control-trash--specific-position {
    right: 12px;
  }

  .newest-document-card__icon-control-trash:hover {
    background: #FBEAE9;
  }

  .newest-document-card__icon-control-trash:hover > atl-custom-icon span svg path {
    stroke: #D92C26!important;
  }

  .newest-document-card__wrapper:hover .newest-document-card__icon-control {
    display: flex;
  }

  .newest-document-card__icons-wr {
    display: flex;
    align-items: center;
  }

  .floor-list {
    display: flex;
    flex-shrink: 0;
    z-index: 2;
    overflow: hidden;
    flex-wrap: wrap;
    padding: 12px 16px;
    gap: 12px;
    user-select: none;
  }

  .floor-list__item {
    position: relative;
    display: flex;
    width: 100px;
    height: 56px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #EAECF0;
    cursor: pointer;
  }

  .floor-list__item > img {
    width: auto;
    max-width: 100px;
    height: 100%;
    max-height: 56px;
  }

  .floor-list__hover {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .12);
    border-radius: 7px;
  }

  .floor-list__item:hover .floor-list__hover {
    display: block;
  }

  .floor-list__item:hover .floor-list__remove {
    display: flex;
  }

  .floor-list__remove {
    display: none;
    align-items: center;
    justify-content: center;
    background: #777D88;
    position: absolute;
    top: -6px;
    right: -6px;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    cursor: pointer;
    z-index: 5;
  }

  .floor-list__remove span {
    display: flex;
  }
}
  `;

  public static fileStyleCardRemoveMobile = `.newest-document-card__icon-control-trash {
    right: -8px;
    top: -8px;
    display: block;
    border-radius: 32px;
    background: black;
    width: 16px;
    height: 16px;
  }

  .newest-document-card__icon-control-trash::before,
  .newest-document-card__icon-control-trash::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 1px;
    background-color: white;

  }
    .newest-document-card__icon-control-trash::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    .newest-document-card__icon-control-trash::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
}
    `;

  public static fileStyleThreadsClient = `
  @font-face {
    font-family: "SF Pro";
    src: url("/assets/fonts/SF-Pro-Text-Regular.otf") format("opentype");
  }

  html {
    overflow-x: hidden;
    scrollbar-color: #9fa7af transparent;
    scrollbar-width: thin;
    cursor: text;
    height: 100%!important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  html::-webkit-scrollbar {
    height: 30px;
    width: 5px;
  }

  html::-webkit-scrollbar-track {
    width: 2px;
  }

  html::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #9fa7af;
    border-bottom: 4px transparent solid;
    border-radius: 5px;
    border-top: 4px transparent solid;
  }

  body {
    font-size: 12pt;
    min-height: calc(100% - 108px);
    margin: 0 24px;
    line-height: 24px;
    src: url('/assets/fonts/SF-Pro-Text-Regular.otf') format("opentype");
    * {
      font-family: 'SF Pro' !important;
    }
  }

  body::before {
    font-family: 'SF Pro' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #8F96A3 !important;
    left: 8px !important;
  }

  body:focus::before {
    color: transparent !important;
  }

  body.without-margin {
    margin: 4px 0 0 0;
  }

  .mce-content-body {
    margin-top: 1em;
    overflow-x: hidden;
    overflow-y: -moz-scrollbars-vertical;
    overflow-y: hidden;
    flex-shrink: 0;
    font-family: 'SF Pro', sans-serif !important;
    min-height: 24px !important;
    font-size: 14px;
    margin: 4px 8px 0;
    padding-bottom: 6px;
  }

  span[data-mce-style="font-size: 36pt;"] {
    line-height: 72px;
  }

  .mce-content-body::-webkit-scrollbar {
    height: 30px;
    width: 5px;
  }

  .mce-content-body::-webkit-scrollbar-track {
    width: 2px;
  }

  .mce-content-body::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #9fa7af;
    border-bottom: 4px transparent solid;
    border-radius: 5px;
    border-top: 4px transparent solid;
  }

  .mce-content-body p {
    margin: 0 8px;
  }

  .mce-content-body p *[data-uid] {
    display: inline-block;
    font-family: 'SF Pro', sans-serif;
    font-style: initial;
    font-weight: initial;
    font-size: inherit;
    line-height: inherit;
    color: #2c6bfd;
    background: #ECF2FF;
    padding: 0px 2px;
    border-radius: 4px;
    margin-left: 3px;
  }

  .input-file__preview {
    display: flex;
    z-index: 2;
    gap: 12px;
    padding: 12px 16px;
    flex-wrap: wrap;
    user-select: none;
  }
  .input-file__item-preview {
    position: relative;
    z-index: 2;
    max-width:200px;
  }
  .input-file__image {
    width: 192px;
    height: 64px;
    object-fit: cover;
    border-radius: 8px;
  }
  .input-file__remove-file, .input-file__remove-image {
    width: 16px;
    height: 16px;
    position: relative;
    bottom: 30px;
    left: 155px;
    cursor: pointer;
  }

  .input-file__remove-image {
    left: 166px;
  }

  .input-file__title {
    color: #0F2438;
    font-size: 12px;
  }

  .input-file__title--upload {
    position: relative;
    bottom: 28px;
    z-index: 2;
    left: 200px;
    cursor: pointer;
  }
  .input-file-invalid {
    border: 1px solid red;
  }
  .file-card__wrapper {
    box-sizing: border-box;
    padding: 10px 12px 10px;
    width: 192px;
    height: 64px;
    background-color: #f3f4f5;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.5s ease-in-out background-color, 0.5s ease-in-out color;
  }
  .file-card__info {
    display: flex;
    height: 58px;
    flex-direction: column;
    justify-content: space-between;
  }
  .file-card__file-name {
    transition: 0.5s ease-in-out color;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'SF Pro', serif;
    font-weight: 500;
    white-space: nowrap;
  }
  .file-card__download {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .file-card__download > div {
    display: flex;
    align-items: center;
  }

  .file-card__file-type {
    padding: 2px 8px;
    background-color: white;
    border-radius: 6px;
    font-family: 'SF Pro', serif;
    font-weight: 500;
  }
  .file-card__file-size {
    margin-left: 8px;
    color: #9fa7af;
    font-family: 'SF Pro', serif;
    font-weight: 500;

  }

  .file-card__file-icon {
    width: 18px;
    height: 18px;
  }

  .newest-document-card-disable-gap {
    height: auto !important;
  }

  .newest-document-card__preview {
    justify-content: unset !important;
  }

  .newest-document-card__item-preview {
    width: fit-content !important;
    min-height: 56px !important;
  }

  .newest-document-card__wrapper {
    position: relative;
    padding: 11px 16px 13px 11px;
    width: 192px;
    height: 56px;
    background-color: #ffffff;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #ebeef0;
    box-sizing: border-box;
  }

  .newest-document-card__wrapper:hover {
    border: 1px solid #ced5da;
    box-shadow: 0 1px 4px rgba(23, 28, 38, 0.08);
  }

  .newest-document-card__wrapper--active {
    border: 1px solid #ced5da;
    box-shadow: 0 1px 4px rgba(23, 28, 38, 0.08);
  }

  .newest-document-card__info {
    display: flex;
  }

  .newest-document-card__file-name {
    font-family: 'SF Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #14181F;
  }

  .newest-document-card__extension {
    margin-right: 8px;
    width: 32px;
    height: 32px;
  }

  .newest-document-card__file-size {
    font-family: 'SF Pro', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #686E79;
  }

  .newest-document-card__image-container {
    margin-right: 8px;
    width: 31px;
    height: 31px;
    border: 1px solid #ebeef0;
    border-radius: 8px;
  }

  .newest-document-card__icon-control {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    display: none;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background-color: #F7F7F8;
  }

  .newest-document-card__icon-control-download {
    right: 12px;
  }

  .newest-document-card__icon-control-download atl-custom-icon span svg path {
    stroke: #777D88;
  }

  .newest-document-card__icon-control-download:hover > atl-custom-icon span svg path {
    stroke: #14181F;
  }

  .newest-document-card__icon-control-trash {
    right: 12px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background-color: #F7F7F8;
  }

  .newest-document-card__icon-control-trash atl-custom-icon span {
    width: 16px;
    height: 16px;
    display: block;
  }

  .newest-document-card__icon-control-trash--specific-position {
     right: 12px;
  }

  .newest-document-card__icon-control-trash:hover > atl-custom-icon span svg path {
    stroke: #e11900!important;
  }

  .newest-document-card__wrapper:hover .newest-document-card__icon-control {
    display: flex;
  }

  .customMentionButton {
    background-color: red;
  }

  @media screen and (max-width: 768px) {
    body {
      margin: 0px 16px;
    }
    body.create_thread_mobile_editor {
      margin: 0px!important;
    }
  }
  `;

  public static fileStyleNewest = `
  @font-face {
    font-family: "SF Pro";
    src: local("SF Pro"), url("/assets/fonts/SF-Pro-Text-Regular.otf") format("opentype");
  }

  html {
    overflow-x: hidden;
    overflow-y: -moz-scrollbars-vertical;
    overflow-y: auto;
    scrollbar-color: #9fa7af transparent;
    scrollbar-width: thin;
    cursor: text;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  html::-webkit-scrollbar {
    height: 30px;
    width: 5px;
  }

  html::-webkit-scrollbar-track {
    width: 2px;
  }

  html::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #9fa7af;
    border-bottom: 4px transparent solid;
    border-radius: 5px;
    border-top: 4px transparent solid;
  }

  body {
    font-size: 14px;
    color: #14181F;
    margin: 0!important;
    line-height: 24px;
    font-family: 'SF Pro' !important;
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
  }

  body::before {
    font-family: 'SF Pro' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #14181F !important;
  }

  body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    color: #777D88 !important;
  }


  body:focus::before {
    color: transparent !important;
  }
  .mce-content-body {
    margin-top: 1em;
  }

  span[data-mce-style="font-size: 36pt;"] {
     line-height: 72px;
  }

  .mce-content-body::-webkit-scrollbar {
    height: 30px;
    width: 5px;
  }

  .mce-content-body::-webkit-scrollbar-track {
    width: 2px;
  }

  .mce-content-body::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #9fa7af;
    border-bottom: 4px transparent solid;
    border-radius: 5px;
    border-top: 4px transparent solid;
  }

  .mce-content-body p {
    margin: 0;
  }

  .input-file__preview {
    display: flex;
    flex-shrink: 0;
    z-index: 2;
    max-width: 650px;
    overflow: hidden;
    flex-wrap: wrap;
    padding: 14px 16px 0;
    user-select: none;
  }
  .input-file__item-preview {
    padding: 0 8px;
    position: relative;
    height: 80px;
    z-index: 2;
    max-width:200px;
  }
  .input-file__image {
    width: 192px;
    height: 64px;
    object-fit: cover;
    border-radius: 8px;
  }
  .input-file__remove-file, .input-file__remove-image {
    width: 16px;
    height: 16px;
    position: relative;
    bottom: 30px;
    left: 155px;
    cursor: pointer;
  }

  .input-file__remove-image {
    left: 166px;
  }

  .input-file__title {
    color: #0F2438;
    font-size: 12px;
  }

  .input-file__title--upload {
    position: relative;
    bottom: 28px;
    z-index: 2;
    left: 200px;
    cursor: pointer;
  }
  .input-file-invalid {
    border: 1px solid red;
  }
  .file-card__wrapper {
    box-sizing: border-box;
    padding: 10px 12px 10px;
    width: 192px;
    height: 64px;
    background-color: #f3f4f5;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.5s ease-in-out background-color, 0.5s ease-in-out color;
  }
  .file-card__info {
    display: flex;
    height: 58px;
    flex-direction: column;
    justify-content: space-between;
  }
  .file-card__file-name {
    transition: 0.5s ease-in-out color;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'SF Pro', serif;
    font-weight: 500;
    white-space: nowrap;
  }
  .file-card__download {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .file-card__download > div {
    display: flex;
    align-items: center;
  }

  .file-card__file-type {
    padding: 2px 8px;
    background-color: white;
    border-radius: 6px;
    font-family: 'SF Pro', serif;
    font-weight: 500;
  }
  .file-card__file-size {
    margin-left: 8px;
    color: #9fa7af;
    font-family: 'SF Pro', serif;
    font-weight: 500;

  }

  .file-card__file-icon {
    width: 18px;
    height: 18px;
  }

  .newest-document-card-disable-gap {
    height: auto !important;
  }

  .newest-document-card__preview {
    justify-content: unset !important;
  }

  .newest-document-card__item-preview {
    width: fit-content !important;
    min-height: 56px !important;
  }

  .newest-document-card__wrapper {
    position: relative;
    padding: 11px 16px 13px 11px;
    width: 192px;
    height: 56px;
    background-color: #ffffff;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #ebeef0;
    box-sizing: border-box;
  }

  .newest-document-card__wrapper:hover {
    border: 1px solid #ced5da;
    box-shadow: 0 1px 4px rgba(23, 28, 38, 0.08);
  }

  .newest-document-card__wrapper--active {
    border: 1px solid #ced5da;
    box-shadow: 0 1px 4px rgba(23, 28, 38, 0.08);
  }

  .newest-document-card__info {
    display: flex;
  }

  .newest-document-card__file-name {
    font-family: 'SF Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #14181F;
  }

  .newest-document-card__extension {
    margin-right: 11px;
    width: 32px;
    height: 32px;
  }

  .newest-document-card__file-size {
    font-family: 'SF Pro', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #686E79;
  }

  .newest-document-card__image-container {
    margin-right: 12px;
    width: 32px;
    height: 32px;
    border: 1px solid #ebeef0;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .newest-document-card__icon-control {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    display: none;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background-color: #F7F7F8;
  }

  .newest-document-card__icon-control-download {
    right: 12px;
  }

  .newest-document-card__icon-control-download atl-custom-icon span svg path {
    stroke: #777D88;
  }

  .newest-document-card__icon-control-download:hover > atl-custom-icon span svg path {
    stroke: #14181F;
  }

  .newest-document-card__icon-control-trash {
    right: 39px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background-color: #F7F7F8;
  }

  .newest-document-card__icon-control-trash atl-custom-icon span {
    width: 16px;
    height: 16px;
    display: block;
  }

  .newest-document-card__icon-control-trash--specific-position {
     right: 12px;
  }

  .newest-document-card__icon-control-trash:hover > atl-custom-icon span svg path {
    stroke: #e11900!important;
  }

  .newest-document-card__wrapper:hover .newest-document-card__icon-control {
    display: flex;
  }
  `;

  public static fileStyleModalEditor = `
  @font-face {
    font-family: "SF Pro";
    src: local("SF Pro"), url("/assets/fonts/SF-Pro-Text-Regular.otf") format("opentype");
  }

  html {
    overflow-x: hidden;
    overflow-y: -moz-scrollbars-vertical;
    overflow-y: auto;
    scrollbar-color: #9fa7af transparent;
    scrollbar-width: thin;
    cursor: text;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  html::-webkit-scrollbar {
    height: 30px;
    width: 5px;
  }

  html::-webkit-scrollbar-track {
    width: 2px;
  }

  html::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #9fa7af;
    border-bottom: 4px transparent solid;
    border-radius: 5px;
    border-top: 4px transparent solid;
  }

  body {
    font-size: 16px;
    line-height: 150%;
    color: #14181F;
    margin: 0 !important;
    font-family: 'SF Pro' !important;
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
  }

  h1 {
    line-height: 32px;
  }

  h2 {
    line-height: 28px;
  }

  h3 {
    line-height: 26px;
  }

  * {
    line-height: 150% !important;
  }

  p, li, h1, h2, h3, h4, h5, h6 {
    margin: 8px 0 !important;
    font-weight: normal;

    span {
      line-height: 150%;
     }
  }

  body::before {
    font-family: 'SF Pro' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #14181F !important;
  }

  body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    color: #777D88 !important;
    margin-top: 6px;
  }


  body:focus::before {
    color: transparent !important;
  }
  .mce-content-body {
    margin-top: 1em;
  }

  body img {
    float: none!important;
    clear: both;
  }

  span[data-mce-style="font-size: 36pt;"] {
     line-height: 72px;
  }

  .mce-content-body::-webkit-scrollbar {
    height: 30px;
    width: 5px;
  }

  .mce-content-body::-webkit-scrollbar-track {
    width: 2px;
  }

  .mce-content-body::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #9fa7af;
    border-bottom: 4px transparent solid;
    border-radius: 5px;
    border-top: 4px transparent solid;
  }

  .mce-content-body p {
    margin: 0;
  }
  `;

  public static alignSelector = 'p,h1,h2,h3,h4,h5,h6,ol,ul,li';

  public static editorInitConfig: ITextEditorInitConfig = {
    menubar: false,
    content_style: TextEditorData.fileStyle,
    plugins: ['lists'],
    autoresize_bottom_margin: 50,
    max_height: 500,
    toolbar: 'bold italic | underline | numlist bullist | fontsize',
    toolbar_location: 'bottom',
    placeholder: 'Shared.Entity.Write_update',
    paste_as_text: true,
    formats: {
      fontsize: { inline: 'span', styles: { lineHeight: '%value', fontSize: '%value' } },
    },
  };

  public static editorInitConfigTemplateUpdates: ITextEditorInitConfig = {
    menubar: false,
    content_style: TextEditorData.fileStyle,
    plugins: ['lists'],
    autoresize_bottom_margin: 50,
    max_height: 500,
    toolbar: 'bold italic | underline | numlist bullist | fontsize',
    toolbar_location: 'bottom',
    placeholder: 'Newsletter.Modal.Editor.Placeholder',
    paste_as_text: true,
  };

  public static editorInitConfigIntroduction: ITextEditorInitConfig = {
    menubar: false,
    content_style: TextEditorData.introductionStyle,
    plugins: ['quickbars', 'lists', 'image', 'autoresize'],
    toolbar: 'bold italic underline fontsize | numlist bullist',
    toolbar_location: 'top',
    placeholder: 'Shared.Entity.Start_typing_text',
    resize: true,
    paste_as_text: true,
    paste_block_drop: true,

    height: '100%',
    link_assume_external_targets: true,
    images_file_types: newEditorImageTypes,
    image_description: false,
    automatic_uploads: true,
    file_picker_types: 'image',
    statusbar: false,
    quickbars_selection_toolbar: 'alignleft aligncenter alignright alignjustify bold italic underline numlist bullist',
    quickbars_image_toolbar: false,
    quickbars_insert_toolbar: false,
    toolbar_sticky: true,
  };

  public static editorInitConfigNewThreadsAdmin: ITextEditorInitConfig = {
    menubar: false,
    body_class: 'thread_view_editor',
    content_style: TextEditorData.fileStyleThreads,
    plugins: ['lists', 'autoresize', 'quickbars'],
    autoresize_bottom_margin: 0,
    resize: true,
    toolbar1: 'bold italic underline fontsize numlist bullist visualblocks',
    toolbar2: '',
    toolbar_location: 'bottom',
    placeholder: 'Shared.Entity.Write_message',
    paste_as_text: false,
    default_link_target: '_blank',
    formats: {
      fontsize: { inline: 'span', styles: { lineHeight: '%value', fontSize: '%value' } },
    },
    mobile: {
      toolbar_mode: 'floating',
    },
    quickbars_selection_toolbar: 'bold italic underline fontsize numlist bullist',
    quickbars_image_toolbar: false,
    quickbars_insert_toolbar: false,
    paste_block_drop: true,
  };

  public static editorInitConfigNewThreadsClient: ITextEditorInitConfig = {
    menubar: false,
    content_style: TextEditorData.fileStyleThreadsClient,
    plugins: ['lists', 'autoresize'],
    autoresize_bottom_margin: 0,
    min_height: 100,
    max_height: 270,
    resize: false,
    toolbar: 'formatgroup',
    toolbar_groups: {
      formatgroup: {
        icon: 'change-case',
        tooltip: 'Formatting',
        items: 'bold italic underline fontsize numlist bullist',
      },
    },
    mobile: {
      toolbar_drawer: 'floating',
    },
    toolbar_location: 'bottom',
    placeholder: 'Shared.Entity.Write_message',
    paste_as_text: false,
    default_link_target: '_blank',
    formats: {
      fontsize: { inline: 'span', styles: { lineHeight: '%value', fontSize: '%value' } },
    },
  };

  public static editorInitConfigThreads: ITextEditorInitConfig = {
    menubar: false,
    body_class: 'thread_view_editor',
    content_style: TextEditorData.fileStyleThreads,
    plugins: ['lists', 'autoresize'],
    autoresize_bottom_margin: 0,
    resize: true,
    toolbar1: 'bold italic underline fontsize numlist bullist visualblocks',
    toolbar2: '',
    toolbar_location: 'bottom',
    placeholder: 'Shared.Entity.Write_message',
    paste_as_text: false,
    default_link_target: '_blank',
    formats: {
      fontsize: { inline: 'span', styles: { lineHeight: '%value', fontSize: '%value' } },
    },
    mobile: {
      toolbar_mode: 'floating',
    },
    paste_block_drop: true,
  };

  public static editorInitConfigNewest: ITextEditorInitConfig = {
    menubar: false,
    body_class: 'thread_view_editor',
    content_style: TextEditorData.fileStyleNewest,
    plugins: ['quickbars', 'lists', 'image', 'media', 'link', 'autolink'],
    height: '100%',
    toolbar: 'h1 h2 h3 numlist bullist',
    resize: true,
    toolbar_location: 'top',
    paste_as_text: false,
    image_title: false,
    image_description: false,
    images_file_types: newEditorImageTypes,
    automatic_uploads: true,
    file_picker_types: 'image',
    statusbar: false,
    quickbars_selection_toolbar: 'alignleft aligncenter alignright alignjustify bold italic underline numlist bullist',
    quickbars_image_toolbar: false,
    quickbars_insert_toolbar: false,
    toolbar_sticky: true,
    contextmenu: 'h1 h2 h3 numlist bullist image',
    link_default_target: '_blank',
    link_context_toolbar: false,
    link_assume_external_targets: true,
    link_title: false,
    link_target_list: false,
    paste_data_images: true,
    block_unsupported_drop: false,
    setup: function (editor) {
      editor.on('drop', function (event: DragEvent): void {
        event.preventDefault();
        const files = event.dataTransfer ? event.dataTransfer.files : null;
        if (!files || files.length === 0) {
          return;
        }

        const file = files[0];
        if (!file.type.startsWith('image/') || newEditorInvalidTypes.includes(file.type)) {
          return;
        }

        const reader = new FileReader();
        reader.onload = function (): void {
          const url = reader.result as string;
          const img = new Image();
          img.src = url;
          img.onload = function (): void {
            const maxWidth = 600;
            const maxHeight = 600;

            let width = img.width;
            let height = img.height;

            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }

            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }

            const resizedImage = document.createElement('canvas');
            resizedImage.width = width;
            resizedImage.height = height;

            const ctx = resizedImage.getContext('2d');
            ctx?.drawImage(img, 0, 0, width, height);

            const resizedUrl = resizedImage.toDataURL(file.type);
            editor.insertContent(
              `<img src="${resizedUrl}" style="display: block; width: 100%; clear: both; margin-top: 8px; margin-bottom: 8px; object-fit: contain; margin-left: auto; margin-right: auto; max-width: 600px; max-height: 600px" alt="${file.name}"/>`
            );
          };
        };
        reader.readAsDataURL(file);
      });

      editor.on('paste', function (event: ClipboardEvent): void {
        const clipboardData = event.clipboardData;
        if (!clipboardData) {
          return;
        }

        const text = clipboardData.getData('text/plain');
        if (isValidLink(text)) {
          event.preventDefault();
          const link = `<a href="${text}" target="_blank">Link</a>`;
          editor.insertContent(link);
        }
      });

      function isValidLink(url: string): boolean {
        const urlRegex = RegExpHelper.validLinkUrl;
        return urlRegex.test(url);
      }
    },
  };

  public static modalEditorConfig: ITextEditorInitConfig = {
    ...TextEditorData.editorInitConfigNewest,
    content_style: TextEditorData.fileStyleModalEditor,
    toolbar: 'blocks | bold italic underline numlist bullist | link customImage',
    style_formats_merge: true,
    font_size_input_default_unit: 'px',
    toolbar_mode: 'wrap',
    block_formats: 'Normal text=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6;',
    quickbars_image_toolbar: false,
    images_file_types: editorOnlyImageTypes,
    block_unsupported_drop: true,
    formats: {
      alignleft: {selector: this.alignSelector, styles: {'textAlign':'left'}},
      aligncenter: {selector: this.alignSelector, styles: {'textAlign':'center'}},
      alignright: {selector: this.alignSelector, styles: {'textAlign':'right'}}
    },
    setup: function (editor): void {
      editor.ui.registry.addButton('customImage', {
        icon: 'image',
        tooltip: 'Insert/edit image',
        onAction: function (): void {
          const input = document.createElement('input');
          input.type = 'file';
          input.accept = 'image/png, image/jpeg';
          input.onchange = function (): void {
            const file = input.files?.[0];
            const reader = new FileReader();
            reader.onload = function (): void {
              const url = reader.result as string;
              const img = new Image();
              img.src = url;
              img.onload = function (): void {
                const maxWidth = 600;
                const maxHeight = 600;

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                  height *= maxWidth / width;
                  width = maxWidth;
                }

                if (height > maxHeight) {
                  width *= maxHeight / height;
                  height = maxHeight;
                }

                const resizedImage = document.createElement('canvas');
                resizedImage.width = width;
                resizedImage.height = height;

                const ctx = resizedImage.getContext('2d');
                ctx?.drawImage(img, 0, 0, width, height);

                const resizedUrl = resizedImage.toDataURL(file?.type);
                editor.insertContent(
                  `<img src="${resizedUrl}" style="display: block; width: 100%; clear: both; margin-top: 8px; margin-bottom: 8px; object-fit: contain; margin-left: auto; margin-right: auto; max-width: 600px; max-height: 600px" alt="${file?.name}"/>`
                );
              };
            };
            reader.readAsDataURL(file as File);
          };
          input.click();
        },
      });
      editor.on('drop', function (event: DragEvent): void {
        event.preventDefault();
        const files = event.dataTransfer ? event.dataTransfer.files : null;
        if (!files || files.length === 0) {
          return;
        }

        const file = files[0];
        if (!file.type.startsWith('image/') || newEditorInvalidTypes.includes(file.type)) {
          return;
        }

        const reader = new FileReader();
        reader.onload = function (): void {
          const url = reader.result as string;
          const img = new Image();
          img.src = url;
          img.onload = function (): void {
            const maxWidth = 600;
            const maxHeight = 600;

            let width = img.width;
            let height = img.height;

            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }

            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }

            const resizedImage = document.createElement('canvas');
            resizedImage.width = width;
            resizedImage.height = height;

            const ctx = resizedImage.getContext('2d');
            ctx?.drawImage(img, 0, 0, width, height);

            const resizedUrl = resizedImage.toDataURL(file.type);
            editor.insertContent(
              `<img src="${resizedUrl}" style="display: block; width: 100%; clear: both; margin-top: 8px; margin-bottom: 8px; object-fit: contain; margin-left: auto; margin-right: auto; max-width: 600px; max-height: 600px" alt="${file.name}"/>`
            );
          };
        };
        reader.readAsDataURL(file);
      });
    },
  };

  public static wideEditorConfig(translateService: TranslateService): ITextEditorInitConfig {
    return {
      ...TextEditorData.editorInitConfigNewest,
      content_style: TextEditorData.fileStyleModalEditor,
      toolbar: 'blocks | bold italic underline numlist bullist | customLink customImage',
      style_formats_merge: true,
      font_size_input_default_unit: 'px',
      toolbar_mode: 'wrap',
      block_formats: 'Normal text=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6;',
      quickbars_image_toolbar: false,
      images_file_types: editorOnlyImageTypes,
      block_unsupported_drop: true,
      formats: {
        alignleft: {selector: this.alignSelector, styles: {'textAlign':'left'}},
        aligncenter: {selector: this.alignSelector, styles: {'textAlign':'center'}},
        alignright: {selector: this.alignSelector, styles: {'textAlign':'right'}},
        p: {title: translateService.instant('Editor.Blocks.Paragraph'), block: 'p'}
      },
      setup: function (editor: Editor): void {
        editor.ui.registry.addButton('customImage', {
          icon: 'image',
          tooltip: 'Insert/edit image',
          text: translateService.instant('Shared.Button.Add_image'),
          onAction: function (): void {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/png, image/jpeg, image/heic';
            input.onchange = function (): void {
              const file = input.files?.[0];

              if (!file) return;

              if (file.type === 'image/heic' || file.name.endsWith('.heic')) {
                convertHeicToPng(file).then((converted) => {
                  if (converted) {
                    insertImage(converted);
                  }
                });
              } else {
                insertImage(file);
              }
            };
            input.click();
          },
        });
        editor.ui.registry.addToggleButton('customLink', {
          icon: 'link',
          tooltip: 'Insert/edit link',
          text: translateService.instant('Shared.Button.Add_link'),
          onAction: () => {
            editor.execCommand('mceLink', false, { dialog: true });
          },
        });
        editor.on('drop', function (event: DragEvent): void {
          event.preventDefault();
          const files = event.dataTransfer ? event.dataTransfer.files : null;
          if (!files || files.length === 0) {
            return;
          }

          const file = files[0];
          if (!file.type.startsWith('image/') || newEditorInvalidTypes.includes(file.type)) return;

          if (file.type === 'image/heic' || file.name.endsWith('.heic')) {
            convertHeicToPng(file).then((converted) => {
              if (converted) {
                insertImage(converted);
              }
            });
          } else {
            insertImage(file);
          }
        });
        editor.on('paste', function (event: ClipboardEvent): void {
          const selectedText = editor.selection.getContent({ format: 'text' });
          const clipboardData = event.clipboardData;
          if (!clipboardData) {
            return;
          }

          const text = clipboardData.getData('text/plain');
          if (isValidLink(text)) {
            event.preventDefault();
            const urlTitle = selectedText || 'Link';
            const link = `<a href="${text}" target="_blank">${urlTitle}</a>`;
            editor.insertContent(link);
          }
        });
        function isValidLink(url: string): boolean {
          const urlRegex = RegExpHelper.validLinkUrl;
          return urlRegex.test(url);
        }
        function insertImage(file: File): void {
          const reader = new FileReader();
          reader.onload = function (): void {
            const url = reader.result as string;
            const img = new Image();
            img.src = url;
            img.onload = function (): void {
              const maxWidth = 600;
              const maxHeight = 600;

              let width = img.width;
              let height = img.height;

              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }

              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }

              const resizedImage = document.createElement('canvas');
              resizedImage.width = width;
              resizedImage.height = height;

              const ctx = resizedImage.getContext('2d');
              ctx?.drawImage(img, 0, 0, width, height);

              const resizedUrl = resizedImage.toDataURL('image/png');
              editor.insertContent(
                `<img src="${resizedUrl}" style="display: block; width: 100%; clear: both; margin-top: 8px; margin-bottom: 8px; object-fit: contain; margin-left: auto; margin-right: auto; max-width: 600px; max-height: 600px" alt="${file.name}"/>`
              );
            };
          };
          reader.readAsDataURL(file);
        }
      },
    };
  }

  public static wideEditorIntroductionConfig(translateService: TranslateService): ITextEditorInitConfig {
    return {
      ...TextEditorData.editorInitConfigIntroduction,
      content_style: TextEditorData.introductionStyle,
      toolbar: 'blocks | bold italic underline numlist bullist | customLink customImage',
      style_formats_merge: true,
      font_size_input_default_unit: 'px',
      toolbar_mode: 'wrap',
      block_formats: 'Normal text=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6;',
      quickbars_image_toolbar: false,
      images_file_types: editorOnlyImageTypes,
      block_unsupported_drop: true,
      formats: {
        alignleft: {selector: this.alignSelector, styles: {'textAlign':'left'}},
        aligncenter: {selector: this.alignSelector, styles: {'textAlign':'center'}},
        alignright: {selector: this.alignSelector, styles: {'textAlign':'right'}}
      },
      setup: function (editor: Editor): void {
        editor.ui.registry.addButton('customImage', {
          icon: 'image',
          tooltip: 'Insert/edit image',
          text: translateService.instant('Shared.Button.Add_image'),
          onAction: function (): void {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/png, image/jpeg';
            input.onchange = function (): void {
              const file = input.files?.[0];
              const reader = new FileReader();
              reader.onload = function (): void {
                const url = reader.result as string;
                const img = new Image();
                img.src = url;
                img.onload = function (): void {
                  const maxWidth = 600;
                  const maxHeight = 600;

                  let width = img.width;
                  let height = img.height;

                  if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                  }

                  if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                  }

                  const resizedImage = document.createElement('canvas');
                  resizedImage.width = width;
                  resizedImage.height = height;

                  const ctx = resizedImage.getContext('2d');
                  ctx?.drawImage(img, 0, 0, width, height);

                  const resizedUrl = resizedImage.toDataURL(file?.type);
                  editor.insertContent(
                    `<img src="${resizedUrl}" style="display: block; width: 100%; clear: both; margin-top: 8px; margin-bottom: 8px; object-fit: contain; margin-left: auto; margin-right: auto; max-width: 600px; max-height: 600px" alt="${file?.name}"/>`
                  );
                };
              };
              reader.readAsDataURL(file as File);
            };
            input.click();
          },
        });
        editor.ui.registry.addToggleButton('customLink', {
          icon: 'link',
          tooltip: 'Insert/edit link',
          text: translateService.instant('Shared.Button.Add_link'),
          onAction: ()=>{editor.execCommand("mceLink",false,{dialog:true})}
        });
        editor.on('drop', function (event: DragEvent): void {
          event.preventDefault();
          const files = event.dataTransfer ? event.dataTransfer.files : null;
          if (!files || files.length === 0) {
            return;
          }

          const file = files[0];
          if (!file.type.startsWith('image/') || newEditorInvalidTypes.includes(file.type)) {
            return;
          }

          const reader = new FileReader();
          reader.onload = function (): void {
            const url = reader.result as string;
            const img = new Image();
            img.src = url;
            img.onload = function (): void {
              const maxWidth = 600;
              const maxHeight = 600;

              let width = img.width;
              let height = img.height;

              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }

              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }

              const resizedImage = document.createElement('canvas');
              resizedImage.width = width;
              resizedImage.height = height;

              const ctx = resizedImage.getContext('2d');
              ctx?.drawImage(img, 0, 0, width, height);

              const resizedUrl = resizedImage.toDataURL(file.type);
              editor.insertContent(
                `<img src="${resizedUrl}" style="display: block; width: 100%; clear: both; margin-top: 8px; margin-bottom: 8px; object-fit: contain; margin-left: auto; margin-right: auto; max-width: 600px; max-height: 600px" alt="${file.name}"/>`
              );
            };
          };
          reader.readAsDataURL(file);
        });

        editor.on('paste', function (event: ClipboardEvent): void {
          const clipboardData = event.clipboardData;
          if (!clipboardData) {
            return;
          }

          const text = clipboardData.getData('text/plain');
          if (isValidLink(text)) {
            event.preventDefault();
            const link = `<a href="${text}" target="_blank">Link</a>`;
            editor.insertContent(link);
          }
        });

        function isValidLink(url: string): boolean {
          const urlRegex = RegExpHelper.validLinkUrl;
          return urlRegex.test(url);
        }
      },
    }
  };

  public static editorTextAreaMode: ITextEditorInitConfig = {
    menubar: false,
    body_class: 'thread_view_editor',
    content_style: TextEditorData.fileStyleTextarea,
    plugins: ['autoresize', 'quickbars', 'lists'],
    height: '100%',
    min_height: 32,
    max_height: 227,
    toolbar: 'h1 h2 h3 numlist bullist',
    resize: true,
    toolbar_location: 'top',
    paste_as_text: true,
    statusbar: false,
    quickbars_selection_toolbar: 'alignleft aligncenter alignright alignjustify bold italic underline numlist bullist',
    quickbars_image_toolbar: false,
    quickbars_insert_toolbar: false,
    toolbar_sticky: true,
    contextmenu: 'h1 h2 h3 numlist bullist',
    link_default_target: '_blank',
    link_context_toolbar: false,
    link_assume_external_targets: true,
    link_title: false,
    link_target_list: false,
    paste_data_images: false,
    block_unsupported_drop: true,
    paste_block_drop: true,
  };
}
