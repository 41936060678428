import { Injectable } from '@angular/core';
import { EPicarioImagePreperty, LayoutProductType, PicarioDataModel, PicarioOptions, ProductListItemModelCPB } from '@atlas-workspace/shared/models';

import { PicarioService } from './picario.service';

@Injectable({
  providedIn: 'root'
})

export class PicarioImageService {
  private designArr: any[] = [];
  private colorsArr: { id: string, colorNr: number }[] = [];
  private picarioOptions!: PicarioOptions;
  private readonly defaultWidth = 3508;
  private readonly defaultHeight = 2480;

  constructor(private readonly picarioService: PicarioService) {}

  /**
   * Generates and updates the product image URL based on Picario data.
   */
  updateProductImage(
    product: ProductListItemModelCPB,
    type: LayoutProductType,
    overlayImages: { url: string; typeId: number; id: number }[],
    sceneImages: { url: string; zIndex: number; id: number; typeId: number | null }[],
    imageUrls: string[]
  ): string {
    this.updateDesignArray(product);

    if (product.picarioData?.color) {
      this.initColors(product.picarioData, product.picarioData.color);
    }

    this.picarioOptions = {
      scene: product.picarioData.scene.storagePath,
      colors: this.colorsArr,
      width: this.defaultWidth,
      height: this.defaultHeight,
      format: 'png',
      overlay: '',
      designIndex: product.picarioData.nr,
      designArr: this.designArr,
      colorNr: product.picarioData.nr
    };

    if (product.picarioData?.scene.subscene) {
      return this.handleSubscene(product, type, overlayImages, imageUrls);
    }

    if (product.picarioData?.image) {
      this.setPicarioImages(product.picarioData, product, sceneImages);
      return '';
    }

    const url = this.picarioService.generateImageUrl(this.picarioOptions) as string;
    imageUrls.push(url);
    return url;
  }

  /**
   * Handles the subscene overlay logic.
   */
  private handleSubscene(
    product: ProductListItemModelCPB,
    type: LayoutProductType,
    overlayImages: { url: string; typeId: number; id: number }[],
    imageUrls: string[]
  ): string {
    this.picarioOptions.overlay = product.picarioData.scene.subscene.storagePath;

    const subColor = product.picarioData.color
      ? { id: `${product.picarioData.color.r}_${product.picarioData.color.g}_${product.picarioData.color.b}`, nr: product.picarioData.nr }
      : undefined;

    const overlayImg = {
      url: this.picarioService.generateImageOverlayUrl(this.picarioOptions, product.picarioData.scene.subscene.id, subColor) as string,
      typeId: type.id,
      id: product.picarioData.scene.subscene.id
    };

    imageUrls.push(overlayImg.url);

    const existingIndex = overlayImages.findIndex(x => x.typeId === type.id);
    if (~existingIndex) {
      overlayImages[existingIndex] = overlayImg;
    } else {
      overlayImages.push(overlayImg);
    }

    return overlayImg.url;
  }

  /**
   * Adds an overlay image to the sceneImages array.
   */
  private setPicarioImages(
    picarioData: PicarioDataModel,
    product: ProductListItemModelCPB | undefined,
    sceneImages: { url: string; zIndex: number; id: number; typeId: number | null }[]
  ): void {
    if (!picarioData.image) return;

    this.picarioOptions.overlay = picarioData.image.storagePath;

    const zIndexObject = picarioData.image.properties.find(item => item.name === EPicarioImagePreperty.ZIndex);
    const sceneImage = {
      url: (this.picarioService.generateImageOverlayUrl(this.picarioOptions) as string).replace('format=jpg', 'format=png'),
      zIndex: zIndexObject ?? 3,
      id: picarioData.image.id,
      typeId: product ? product.type.id : null
    };

    const isDuplicate = sceneImages.some(image => image.id === sceneImage.id);
    if (!isDuplicate) {
      if (sceneImage.typeId) {
        const i = sceneImages.findIndex(x => x.typeId === sceneImage.typeId);
        if (~i) {
          sceneImages.splice(i, 1);
        }
      }
      sceneImages.push(sceneImage);
    }
  }

  /**
   * Updates the design array for the product.
   */
  private updateDesignArray(product: ProductListItemModelCPB): void {
    const design = product.picarioData?.design;
    if (!design) return;

    const existingIndex = this.designArr.findIndex(item => item.nr === product.picarioData.nr);
    design['nr'] = product.picarioData.nr;

    if (existingIndex !== -1) {
      this.designArr[existingIndex] = design;
    } else {
      this.designArr.push(design);
    }
  }

  /**
   * Initializes the colors array.
   */
  private initColors(picarioData: PicarioDataModel, color: any): void {
    if (picarioData.scene.subscene) return;

    const colorToAdd = {
      id: `${color.r}_${color.g}_${color.b}`,
      colorNr: picarioData.nr
    };

    const index = this.colorsArr.findIndex(x => x.colorNr === colorToAdd.colorNr);
    if (~index) {
      this.colorsArr[index] = colorToAdd;
    } else {
      this.colorsArr.push(colorToAdd);
    }
  }
}
