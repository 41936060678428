import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  WhiteLabelSettingsFormControl,
  WhiteLabelSettingsFormKeys,
  WhiteLabelSettingsFormRawValue,
} from '@atlas-workspace/shared/models';
import { IConfig } from 'ngx-mask';

export const defaultWhiteLabelSettingsLogoPreviewPath = '/assets/client-logo-v3.svg';

export const whiteLabelHexColorInputPattern = /^#?([A-Fa-f0-9]{6})$/;

export const whiteLabelHexColorInputMaskPatternConfig: IConfig['patterns'] = {
  o: {
    pattern: /[A-Fa-f0-9]/,
  },
};

export const whiteLabelHexColorInputValidators = [
  Validators.required,
  Validators.pattern(whiteLabelHexColorInputPattern),
];

export function initWhiteLabelSettingsForm(): FormGroup<WhiteLabelSettingsFormControl> {
  return new FormGroup<WhiteLabelSettingsFormControl>({
    [WhiteLabelSettingsFormKeys.AccentColor]: new FormControl(null, whiteLabelHexColorInputValidators),
    [WhiteLabelSettingsFormKeys.BorderColor]: new FormControl(null, whiteLabelHexColorInputValidators),
    [WhiteLabelSettingsFormKeys.TextColor]: new FormControl(null, whiteLabelHexColorInputValidators),
    [WhiteLabelSettingsFormKeys.BackgroundColor]: new FormControl(null, whiteLabelHexColorInputValidators),
    [WhiteLabelSettingsFormKeys.ButtonTextColor]: new FormControl(null, whiteLabelHexColorInputValidators),
    [WhiteLabelSettingsFormKeys.ButtonCornerRadius]: new FormControl(null, [Validators.required]),
    [WhiteLabelSettingsFormKeys.LogoUrl]: new FormControl(null),
    [WhiteLabelSettingsFormKeys.LogoFile]: new FormControl(null),
  });
}

export function fillEmptyFieldsByDefaultValuesOfPlainSettingsObj(
  plainObj: Partial<WhiteLabelSettingsFormRawValue>,
  defaultFormRawValue = whiteLabelSettingsDefaultInputsValues,
): void {
  Object.keys(plainObj).forEach((key) => {
    const typedKey = key as keyof typeof defaultFormRawValue;
    if (!plainObj[typedKey] && typedKey in defaultFormRawValue) {
      plainObj[typedKey] = defaultFormRawValue[typedKey];
    }
  });
}

export function setDefaultValueForEmptyControl(
  control: AbstractControl,
  key: WhiteLabelSettingsFormKeys,
  defaultFormRawValue = whiteLabelSettingsDefaultInputsValues,
): void {
  if (!control.value) {
    const plainObj = { [key]: null } as Partial<WhiteLabelSettingsFormRawValue>;
    fillEmptyFieldsByDefaultValuesOfPlainSettingsObj(plainObj, defaultFormRawValue);
    control.setValue(plainObj[key]);
  }
}

export const whiteLabelSettingsColorsInputsList = [
  {
    formControlName: WhiteLabelSettingsFormKeys.AccentColor,
    labelTranslateKey: 'WL_builder.Accent_color',
  },
  {
    formControlName: WhiteLabelSettingsFormKeys.TextColor,
    labelTranslateKey: 'WL_builder.Text_color',
  },
  {
    formControlName: WhiteLabelSettingsFormKeys.BorderColor,
    labelTranslateKey: 'WL_builder.Border_color',
  },
  {
    formControlName: WhiteLabelSettingsFormKeys.BackgroundColor,
    labelTranslateKey: 'WL_builder.Background_color',
  },
];

export const whiteLabelSettingsDefaultInputsValues: Partial<WhiteLabelSettingsFormRawValue> = {
  [WhiteLabelSettingsFormKeys.AccentColor]: '0055FF',
  [WhiteLabelSettingsFormKeys.TextColor]: '14181F',
  [WhiteLabelSettingsFormKeys.BorderColor]: 'EAECF0',
  [WhiteLabelSettingsFormKeys.BackgroundColor]: 'F7F7F8',
  [WhiteLabelSettingsFormKeys.ButtonTextColor]: 'FFFFFF',
  [WhiteLabelSettingsFormKeys.ButtonCornerRadius]: 20,
  [WhiteLabelSettingsFormKeys.LogoUrl]: defaultWhiteLabelSettingsLogoPreviewPath,
};

export const whiteLabelSettingsDefaultColorPreviewParams = {
  backgroundColor: '#FFFFFF',
  borderColor: '#C4C9D4',
};

export const whiteLabelPreviewStyleVariables = {
  [WhiteLabelSettingsFormKeys.AccentColor]: '--wl-acsentcolor',
  [WhiteLabelSettingsFormKeys.TextColor]: '--wl-textcolor',
  [WhiteLabelSettingsFormKeys.BorderColor]: '--wl-bordercolor',
  [WhiteLabelSettingsFormKeys.BackgroundColor]: '--wl-backgroundcolor',
  [WhiteLabelSettingsFormKeys.ButtonTextColor]: '--wl-buttontextcolor',
  [WhiteLabelSettingsFormKeys.ButtonCornerRadius]: '--wl-buttoncornerradius',
};

export function formatWhiteLabelSettingsControlValue(key: WhiteLabelSettingsFormKeys, value: any): string {
  const isColor = [
    WhiteLabelSettingsFormKeys.AccentColor,
    WhiteLabelSettingsFormKeys.TextColor,
    WhiteLabelSettingsFormKeys.BorderColor,
    WhiteLabelSettingsFormKeys.BackgroundColor,
    WhiteLabelSettingsFormKeys.ButtonTextColor,
  ].includes(key);
  if (isColor) {
    return `#${value}`;
  }
  if (WhiteLabelSettingsFormKeys.ButtonCornerRadius === key) {
    return `${value}px`;
  }
  return value;
}

export function isLightHexColor(hex: string): boolean {
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  const brightnessDegree = 180;

  return luminance > brightnessDegree;
}
