<header class="d-flex align-items-center justify-content-between pl-24 pr-16">
  <h2 class="fz-16 lh-24 fw-500">{{ 'Title.Download_messages' | translate }}</h2>
  <atl-header-icon
    (click)="closeModal()"
    icon="/assets/close-icon.svg"
    [ngbTooltip]="'Alt.Close' | translate"
    [openDelay]="tooltipDelay"
    triggers="hover touchstart"
  ></atl-header-icon>
</header>

<main class="p-24">
  <p class="fz-16 lh-24 fw-400 mb-16">
    {{ (isCRUnitScope ? 'Description.Select_message_to_report_download' : 'Description.Select_message_to_report_download_cr') | translate }}
     {{ descriptionIdentifier }}
  </p>

  <form class="d-flex flex-column gap-16">
    <div *ngFor="let option of messageOptions" class="d-flex align-items-center">
      <div class="d-flex align-items-center gap-12">
        <input
          type="radio"
          width="20"
          height="20"
          name="messageOption"
          [id]="option.id"
          [value]="option.id"
          [checked]="option.selected"
          (change)="selectOption(option.id)"
          class="cursor-pointer"
        />
        <label [for]="option.id" class="fz-16 lh-24 fw-400 cursor-pointer">{{ option.label }}</label>
      </div>
    </div>
  </form>
</main>

<footer class="pt-16 pb-16 pl-24 pr-24">
  <div class="d-flex justify-content-end gap-16">
    <div class="d-flex gap-16">
      <button class="newest-button button-gray" (click)="closeModal()">
        {{ 'Button.Cancel' | translate }}
      </button>
      <button class="newest-button" (click)="downloadPDF()">
        {{ 'Button.Download_Pdf' | translate }}
      </button>
    </div>
  </div>
</footer>
