/* eslint-disable @nx/enforce-module-boundaries */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { EditorMultiFileUploadComponent } from '@atlas-workspace/shared/form';
import { ModalHelpersService } from '@atlas-workspace/shared/modals';
import {
  acceptedGlobalExtensions,
  acceptedVideoExtensions,
  correctMimeTypes,
  CUSTOM_FILE_LIST_CHANGE_EDITOR_EVENT_NAME,
  EAccessTag,
  EPlatform,
  EProjectRoles,
  EThreadScope,
  FileModel,
  FileNameModel,
  IFloorVersionList,
  initEditorCustomButtons,
  ITextEditorInitConfig,
  IThreadEditorInitCustomButtonsProps,
  OnSubmitEditorPropsType,
  selectedFloors,
  ThreadViewModel,
} from '@atlas-workspace/shared/models';
import {
  ProjectService,
  TextEditorService,
  ThreadsHelperService,
  TreadMessageSaveService,
} from '@atlas-workspace/shared/service';
import { WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';
import { TextEditorData } from '@atlas-workspace/shared/ui';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import * as mime from 'mime';
import { BehaviorSubject, of } from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';
import { Editor } from 'tinymce';

import floorPlanSvg from '!!raw-loader?!@atlas-workspace/shared/assets/lib/floor-plan-icon.svg';
import mentionIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/mentions.svg';
import uploadIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/paperclip_grey.svg';
import threadSendIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/send_new.svg';
import styleSettingsSvg from '!!raw-loader?!@atlas-workspace/shared/assets/lib/text-formatting.svg';

import { MentionsTextEditorComponent } from '../mentions-text-editor/mentions-text-editor.component';

@UntilDestroy()
@Component({
  selector: 'atl-thread-editor-wrapper',
  templateUrl: './thread-editor-wrapper.component.html',
  styleUrls: ['./thread-editor-wrapper.component.scss'],
  providers: [TextEditorService, TreadMessageSaveService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreadEditorWrapperComponent implements OnInit, OnChanges {
  @ViewChild('editorElementRef', { read: ElementRef }) editorElementRef!: ElementRef;
  @ViewChild('editorComponentRef') editorComponent!: MentionsTextEditorComponent;
  @ViewChild('fileRef', { read: ElementRef }) fileRef!: ElementRef;
  @ViewChild('fileComponent') fileComponent!: EditorMultiFileUploadComponent;
  @ViewChild('floorPlan', { read: ElementRef }) floorPlan!: ElementRef;

  @Input() thread?: ThreadViewModel;
  @Input() isLoading = false;
  @Input() scope?: EThreadScope;
  @Input() accessTag: EAccessTag = EAccessTag.Reclamations;
  @Input() disabled!: boolean;
  @Input() isClient = false;
  @Input() isMessageWriting = true;

  @Input() set droppFiles(files: File[]) {
    this.droppedFiles = files;
  }
  @Input() public floorPlanItems$!: BehaviorSubject<IFloorVersionList[] | null>;

  @Output() private readonly submitEvent = new EventEmitter<OnSubmitEditorPropsType>();
  @Output() private readonly openChangeRequestModal = new EventEmitter();
  @Output() private readonly updateFloorPlan = new EventEmitter<IFloorVersionList[]>();
  @Output() private readonly changeSendAlsoSMSEvent = new EventEmitter<boolean>();

  public threadId!: number;
  public form!: FormGroup;
  public droppedFiles?: File[];
  public editorInitConfig!: ITextEditorInitConfig;
  public isButtonDisabled = false;
  public floorPlanItems!: IFloorVersionList[];
  public readonly apiKey!: string;
  public readonly acceptedExtensions!: string;
  public readonly maxUploadedFiles = 10;
  public readonly maxUploadedFilesSize = 3e7;
  public readonly threadSendIcon = threadSendIcon;
  public readonly mentionUsersPerView = 5;
  private readonly formChangeDelay = 100;
  private readonly mobileSize = 768;
  private isProjectContractor = false;
  public scopeTypes = EThreadScope;
  public loadingEditor = false;
  public isMac = false;

  constructor(
    @Inject('ENVIRONMENT') readonly env: IEnvironment,
    @Inject(WEB_PLATFORM_TYPE) private readonly portal: EPlatform,
    private fb: FormBuilder,
    private textEditorService: TextEditorService,
    private threadsHelperService: ThreadsHelperService,
    private translateService: TranslateService,
    public cd: ChangeDetectorRef,
    private readonly projectService: ProjectService,
    private readonly treadMessageSaveService: TreadMessageSaveService,
    private readonly modalHelpersService: ModalHelpersService,
  ) {
    this.apiKey = env.tinyMceApiKey;
    this.acceptedExtensions = [acceptedGlobalExtensions, acceptedVideoExtensions].join(', ');
    this.correctAcceptedMimeTypes(correctMimeTypes);
    this.isMac = window?.navigator.userAgent.includes('Mac');
  }

  ngOnInit(): void {
    this.getProjectContractor();
    this.initForm();
    this.initEditorToolbarCallbacks();
    this.initFlorView();
    void this.treadMessageSaveService.initializeDB();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scope) {
      this.scope = changes.scope.currentValue;
    }
    if (changes.thread) {
      if (!!changes.thread?.currentValue?.changeRequestId !== !!changes.thread?.previousValue?.changeRequestId) {
        this.thread = changes.thread.currentValue;
        this.threadId = this.thread!.id;
        if (this.thread) this.initEditorToolbarCallbacks();

        if (this.editorComponent) {
          this.loadingEditor = true;
          this.cd.detectChanges();
          this.editorComponent.editor?.editorManager.activeEditor?.remove();

          //@ts-ignore
          this.editorComponent.tinymceEditorComponent.init = this.editorInitConfig;
          try {
            this.editorComponent?.tinymceEditorComponent?.initialise();
          } catch (error) {
            this.loadingEditor = false;
          }
          this.editorComponent?.tinymceEditorComponent?.onLoadContent.pipe(take(1)).subscribe((res) => {
            this.loadingEditor = false;
          });
        }
        this.cd.detectChanges();
      }
      if (this.thread) {
        this.threadId = this.thread.id;
        this.getMessageFromDB();
      }
    }
  }

  setSendSMS(val: boolean): void {
    this.changeSendAlsoSMSEvent.emit(val);
  }

  public get showSendSMS(): boolean {
    return (
      !this.isClient &&
      (this.scope === this.scopeTypes.Reclamation ||
        this.scope === this.scopeTypes.ChangeRequest ||
        this.scope === this.scopeTypes.ChangeRequestGroups ||
        this.scope === this.scopeTypes.ChangeRequestMessagesView ||
        this.scope === this.scopeTypes.Unit ||
        this.scope === this.scopeTypes.Global ||
        this.scope === this.scopeTypes.Project ||
        this.scope === this.scopeTypes.ReclamationThreadView)
    );
  }

  private getProjectContractor(): void {
    this.projectService.adminProject$.pipe(take(1)).subscribe((res) => {
      if (res) {
        this.isProjectContractor = res.currentRole === EProjectRoles.Contractor;
      }
    });
  }

  private initForm(): void {
    this.form = this.fb.group({
      body: [''],
      document: [[]],
    });

    this.form
      .get('body')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateDoc();
        this.saveMessage();
        this.cd.detectChanges();
      });

    this.form
      .get('document')
      ?.valueChanges.pipe(untilDestroyed(this), delay(this.formChangeDelay))
      .subscribe((v) => {
        this.updateDoc();
        this.checkIsButtonDisabled(!this.form?.controls?.document?.value?.length);
        this.saveMessage();
        this.cd.detectChanges();
      });
  }

  private saveMessage(): void {
    of(null)
      .pipe(take(1), delay(500))
      .subscribe(() => {
        if (this.thread!.id !== this.threadId) return;
        // eslint-disable-next-line prefer-const
        let { body, document } = this.form.value;
        if (body || document?.length) {
          document = document?.filter(Boolean);
          void this.treadMessageSaveService.saveMessages(this.threadId, { body, document });
        } else if (!body && !document?.length) {
          void this.treadMessageSaveService.deleteMessages(this.threadId);
        }
      });
  }

  private getMessageFromDB(): void {
    this.treadMessageSaveService.getMessages(this.threadId).then((value) => {
      if (value) {
        this.form.patchValue(value, { emitEvent: false, onlySelf: true });
      } else {
        this.form.reset({ body: '', document: null }, { emitEvent: false, onlySelf: true });
      }

      of(null)
        .pipe(delay(100), take(1))
        .subscribe(() => {
          this.updateDoc();
          this.checkIsButtonDisabled(false);
          this.updateIframeFiles();
          this.cd.markForCheck();
        });
    });
  }

  private removeMessageFromDB(): void {
    this.form.reset({ body: null, document: null }, { emitEvent: false, onlySelf: true });
    void this.treadMessageSaveService.deleteMessages(this.threadId);
    of(null)
      .pipe(delay(0), take(1))
      .subscribe(() => this.updateIframeFiles());
  }

  public setFilesToDocuments(files: File[]): void {
    this.fileComponent.loadValidFiles(files);
  }

  private updateDoc(): void {
    of(true)
      .pipe(delay(this.formChangeDelay))
      .subscribe(() => {
        if (this.editorElementRef && this.fileRef) {
          this.textEditorService.updateIframeFiles(this.editorElementRef, this.fileRef);
        }
      });
  }

  public checkIsButtonDisabled(disabled: boolean): void {
    this.isButtonDisabled = disabled;
  }

  /**
   * @Cypress
   * Access in the exact order is used due to missing tooltips for some custom buttons.
   * @todo need to consider adding tooltips for custom buttons to avoid exact-order accessing
   */
  private initEditorToolbarCallbacks(): void {
    const props: IThreadEditorInitCustomButtonsProps = {
      customAttachmentsButton: {
        onAction: this.triggerFileControl.bind(this),
        setEnabled: this.checkIfSetEnabledForCustomAttachmentsButton.bind(this),
        toolbar: 'toolbar2',
        icon: uploadIcon,
      },
      ...(this.portal !== EPlatform.WEB_CLIENT &&
        !this.isProjectContractor && {
          customMentionsButton: {
            onAction: this.triggerMentionKeyup.bind(this),
            toolbar: 'toolbar2',
            icon: mentionIcon,
          },
        }),
      ...(this.thread?.changeRequestId &&
        this.portal === EPlatform.WEB_ADMIN && {
          customAttachmentsChangeRequest: {
            onAction: this.onClickChangeRequestAttachments.bind(this),
            toolbar: 'toolbar2',
            icon: floorPlanSvg,
            text: this.translateService.instant('Shared.Entity.Add_floor_plan'),
          },
        }),
    };

    const config = cloneDeep(TextEditorData);
    if (window.innerWidth <= this.mobileSize) {
      const mobileStyle = config.fileStyleCardRemoveMobile;
      config.editorInitConfigThreads.content_style += mobileStyle;
    }

    this.editorInitConfig = initEditorCustomButtons({ ...config.editorInitConfigThreads }, props);
    this.editorInitConfig.placeholder = this.translateService.instant('Shared.Entity.Write_message_no_dots');
  }

  private triggerFileControl(): void {
    this.fileRef.nativeElement.querySelector('[data-cy="cy-input-file-browse"]').click();
  }

  private checkIfSetEnabledForCustomAttachmentsButton(): boolean {
    return (this.form.controls.document.value || []).filter(Boolean).length < this.maxUploadedFiles;
  }

  private toggleStyleSettingsToolbar(): void {
    this.editorComponent.isVisibleStyleSettingsToolbar = !this.editorComponent.isVisibleStyleSettingsToolbar;
    this.editorComponent.cd.detectChanges();
    this.cd.detectChanges();
  }

  private initFlorView(): void {
    this.floorPlanItems$
      .pipe(
        untilDestroyed(this),
        tap((value) => {
          if (value) {
            this.floorPlanItems = value;
            this.cd.markForCheck();
          }
        }),
        delay(0),
      )
      .subscribe((value) => {
        if (value) {
          this.textEditorService.updateIFrameFloorPlan(
            this.editorElementRef,
            this.floorPlan,
            this.editorComponent.editor,
          );
          this.cd.markForCheck();
        }
      });
  }

  private onClickChangeRequestAttachments(): void {
    this.openChangeRequestModal.emit();
  }

  removeFloor(floorsVersion: IFloorVersionList[]): void {
    this.updateFloorPlan.emit(floorsVersion);
  }

  private triggerMentionKeyup(): void {
    this.editorComponent.triggerMentionKeyup();
  }

  public submit(e?: Event): void {
    e?.stopImmediatePropagation();
    e?.stopPropagation();
    e?.preventDefault();
    if (!this.isSubmitEnabled && this.floorPlanSendDisabled) {
      return;
    }

    const documents = this.form.get('document')?.value || [];
    const noWhitespacesMessage = this.threadsHelperService.removeWhitespaces(this.form.get('body')?.value);
    const message = this.threadsHelperService.removeEditorBookmarksFromMessage(noWhitespacesMessage);

    const mentions = this.threadsHelperService.formatMentions(
      this.form.value.body,
      this.editorComponent.users,
      this.editorComponent.taggingParams,
    );

    this.submitEvent.next({ message, documents, mentions });
    this.removeMessageFromDB();
  }

  public get floorPlanSendDisabled(): boolean {
    return !selectedFloors(this.floorPlanItems).length;
  }

  public get isSubmitEnabled(): boolean {
    return (
      !this.disabled &&
      this.form.valid &&
      !!this.thread?.units.length &&
      !this.isLoading &&
      (!this.hasMessageOnlyWhiteSpace || this.form.controls.document.value?.length)
    );
  }

  private get hasMessageOnlyWhiteSpace(): boolean {
    return !this.form.controls.body.value
      ?.replace(/&(nbsp|amp|quot|lt|gt);/g, '')
      ?.replace(/(<([^>]+)>)/gi, '')
      ?.trim()?.length;
  }

  public get isMobileView(): boolean {
    return window.innerWidth <= this.mobileSize;
  }

  private correctAcceptedMimeTypes(correctMime: { mimeType: string; extension: string[] }[]): void {
    correctMime.forEach((mimeItem) => {
      mime.define({ [mimeItem.mimeType]: mimeItem.extension }, true);
    });
  }

  public updateIframeFiles(): void {
    queueMicrotask(() => {
      this.textEditorService.updateIframeFiles<Editor>(
        this.editorElementRef,
        this.fileRef,
        this.editorComponent.editor,
      );
    });
    setTimeout(() => {
      this.editorComponent.editor?.fire(CUSTOM_FILE_LIST_CHANGE_EDITOR_EVENT_NAME);
    });
  }

  public setBodyValueAfterMention(html: string): void {
    this.form.controls.body.setValue(html, { emitEvent: false });
  }

  public resetUsers(): void {
    this.editorComponent.users = undefined;
  }

  public previewImageHandler(props: { src: string; name: string; extension: string }): void {
    const file = new FileModel();
    file.name = props.name;
    file.fileExtension = props.extension;
    file.fileName = new FileNameModel(props.src);

    this.modalHelpersService.previewImages([file], 0, false);
  }
}
