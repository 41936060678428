import { Pipe, PipeTransform } from '@angular/core';
import { EMeetingKind, MeetingsModel } from '@atlas-workspace/shared/models';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'unitsFreeSlot',
  standalone: true,
})
export class UnitsFreeSlotPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(meeting: MeetingsModel): string {
    const bookedLabel = this.translateService.instant('Shared.Meeting.Status.Booked').toLowerCase();

    const unitsBookedCount = meeting.units?.length || 0;

    switch (meeting.kind) {
      case EMeetingKind.Single:
        if (unitsBookedCount === 0) {
          return `0/1 ${bookedLabel}`;
        } else {
          return meeting.units[0].unit.identifier;
        }

      case EMeetingKind.Group:
        if (unitsBookedCount === 0) {
          return meeting.maxUnits
            ? `0/${meeting.maxUnits} ${bookedLabel}`
            : `0 ${bookedLabel}`;
        } else if (unitsBookedCount === 1) {
          return meeting.units[0].unit.identifier;
        } else {
          const additionalUnits = unitsBookedCount - 1;
          return `${meeting.units[0].unit.identifier} +${additionalUnits}`;
        }

      default:
        return `0 ${bookedLabel}`;
    }
  }
}
