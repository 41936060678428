import {Directive, OnDestroy, OnInit} from "@angular/core";
import {CableChannelPdfMessageReportStatus} from "@atlas-workspace/shared/models";
import {CableService, GlobalLoadingMessageService, ReportsService} from "@atlas-workspace/shared/service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ToastrService} from "ngx-toastr";
import {firstValueFrom} from "rxjs";

@UntilDestroy()
@Directive({
  standalone: true,
})
export class ReportsDirective implements OnInit, OnDestroy {

  constructor(
    private readonly reportsService: ReportsService,
    private readonly cableService: CableService,
    private readonly globalLoadingMessageService: GlobalLoadingMessageService,
    private readonly toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.initGenerationDependencies();
  }

  private initGenerationDependencies(): void {
    this.reportsService.pdfMessageReportGeneration$.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data.toggle) {
        this.globalLoadingMessageService.showTooltip('Option.Newest_Downloading_process');
      }
    });

    this.cableService.pdfMessageReport$.pipe(untilDestroyed(this)).subscribe(async (result) => {
      const generation = await firstValueFrom(this.reportsService.pdfMessageReportGeneration$);
      if (result.jid !== generation.key) {
        return;
      }
      if (result.status === CableChannelPdfMessageReportStatus.Finished) {
        window.open(result.downloadUrl, '_self');
      } else if (result.errorMessage) {
        this.toastrService.error(result.errorMessage, undefined, {
          positionClass: 'toast-bottom-center',
        });
      }
      this.resetGenerationDependencies();
    });
  }

  private resetGenerationDependencies(): void {
    this.reportsService.markPdfMessageReportGenerationAsFinished();
    this.globalLoadingMessageService.closeTooltip();
  }

  ngOnDestroy(): void {
    this.resetGenerationDependencies();
  }
}
