import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import {FirmIconComponent} from "@atlas-workspace/shared/ui";
import { EditorModule } from '@tinymce/tinymce-angular';

import { MentionsTextEditorComponent } from './mentions-text-editor.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [CommonModule, EditorModule, ReactiveFormsModule, SharedDirectivesModule, FirmIconComponent, TranslateModule],
  declarations: [MentionsTextEditorComponent],
  exports: [MentionsTextEditorComponent],
})
export class MentionsTextEditorModule {}
