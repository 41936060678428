<atl-ngb-modal-header
  [title]="title"
  (closeModalEvent)="closeModal()"
>
  <ng-container *ngIf="editMode">
    <atl-header-icon
      (click)="removeChangeRequest()"
      icon="assets/trash-gray.svg"
      [ngbTooltip]="'Shared.Button.Delete' | translate"
      [openDelay]="tooltipDelay"
      [container]="'body'"
      triggers="hover touchstart"
    ></atl-header-icon>
  </ng-container>
</atl-ngb-modal-header>

<div class="group-change-request v-scrollbar p-24" tabindex="0">
  <form class="group-change-request__form" [formGroup]="form" tabindex="0">
    <!-- Unit -->
    <div class="group-change-request__control">
      <div class="group-change-request__control-title">
        <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{ 'Shared.Reclamation.Input.Unit.Label' | translate }}
      </div>
      <div class="d-flex align-items-center w-100 pl-8 min-w-0">
        <span class="fz-14 lh-32 d-block ellipsis">{{ unit.identifier }}</span>
        <ng-container *ngIf="viewerUsers?.length">
          <span class="ml-8 mr-8">·</span>
          <atl-users-viewer [users]="viewerUsers" [icon]="false"></atl-users-viewer>
        </ng-container>
      </div>
    </div>

    <!-- Description -->
    <div class="group-change-request__control align-items-start">
      <div class="group-change-request__control-title position-relative">
        <img [src]="'assets/text-align-left-gray.svg'" alt="icon" />{{ 'Entity.Description' | translate }}
      </div>
      <div data-cy="cy-add-description-change-requests" class="group-change-request__control-input">
        <atl-textarea-autoresize
          formControlName="description"
          [stringMode]="true"
          [maxLength]="descriptionMaxLength"
          [placeholder]="'Shared.Entity.Add_description' | translate"
          [absolutePosition]="true"
          [autoresizeOffset]="2"
          class="newest-textarea"
        >
        </atl-textarea-autoresize>
      </div>
    </div>

    <!-- Assignee -->
    <div class="group-change-request__control">
      <div class="group-change-request__control-title">
        <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{ 'Shared.Entity.Responsible' | translate }}
      </div>
      <div class="group-change-request__control-input min-w-0">
        <atl-drop-down-v2
          #assigneeSelect
          class="multiple-select-component"
          data-cy="cy-select-responsible-change-requests"
          formControlName="responsible"
          [listOfItems]="users"
          [bindLabel]="'name'"
          [searchParams]="['name', 'email']"
          [placeholder]="
            assigneeSelect.isOpen
              ? ('Entity.Search_for' | translate: { value: ('Entity.Assignee' | translate) })
              : ('Input.Select.Responsible' | translate)
          "
          [notFoundText]="'Shared.Entity.Not_found' | translate"
          [useCustomItemView]="true"
          [useCustomLabelView]="true"
        >
          <ng-template atlInputLabelTemplate let-item="item">
            <div class="multiple-select-user-card-wrapper min-w-0">
              <div class="min-w-0">
                <div
                  class="multiple-select-user-card-icon flex-shrink-0"
                  [ngStyle]="{ 'background-color': item.color, border: '1px solid ' + item.borderColor }"
                >
                  <span>{{ item?.name | firmPrefix }}</span>
                </div>
                <span class="d-block ellipsis">{{ item?.name | truncate: nameTruncate}}</span>
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem(changeRequestFormKey.Responsible, item)"
                />
              </div>
              <img
                class="multiple-select-user-card-checkbox"
                src="/assets/multiple_select_checkbox.svg"
                alt="Check state"
              />
            </div>
          </ng-template>
          <ng-template atlInputTemplate let-item="item" let-selected="selected">
            <div class="multiple-select-user-card-wrapper">
              <div>
                <div
                  class="multiple-select-user-card-icon flex-shrink-0"
                  [ngStyle]="{ 'background-color': item.color, border: '1px solid ' + item.borderColor }"
                >
                  <span class="d-block ellipsis">{{ item?.name | firmPrefix }}</span>
                </div>
                <div class="d-flex flex-column min-w-0">
                  <p class="request-create-dropdown-user-name d-block ellipsis fz-14 lh-24 fw-400" [class.selected]="selected">
                    {{ item?.name | truncate: nameTruncate}}
                  </p>
                  <p class="request-create-dropdown-user-email d-block ellipsis fz-12 lh-16 fw-400">{{ item?.email }}</p>
                </div>
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem(changeRequestFormKey.Responsible, item)"
                />
              </div>
              <img
                class="multiple-select-user-card-checkbox"
                src="/assets/multiple_select_checkbox.svg"
                alt="Check state"
              />
            </div>
          </ng-template>
        </atl-drop-down-v2>
      </div>
    </div>

    <!-- Attachments -->
    <div class="group-change-request__control">
      <div class="group-change-request__control-title">
        <img [src]="'assets/attachment_gray.svg'" alt="icon" />{{
          'Shared.Reclamation.Input.Attachments.Label' | translate
        }}
      </div>
      <div class="group-change-request__control-input lh-32 fz-14">
        <atl-dropdown-input-file
          [imagePreview]="true"
          id="file-input"
          formControlName="attachments"
          dragGroup="attachments"
          [newOnly]="true"
          [autoSave]="true"
          [format]="acceptedExtensions"
          (updateFilePositionsEmitter)="updateFilePositions($event)"
          [placeholder]="'Shared.Entity.Add_attachments' | translate"
          [setFileForWriteValue]="true"
          (tempPreview)="previewImageHandler($event)"
        ></atl-dropdown-input-file>
      </div>
    </div>

    <div class="mt-24">
      <atl-plan-drawing
        [displayFloors]="true"
        [floorPlan]="floorPlanData"
        [unitId]="unit.id"
        [savedDrawings]="drawingData"
        [previewPlan]="previewPlan"
        [floorType]="unit.hasOwnFloorPlans ? floorType.UnitFloor : floorType.Floor"
        (updateFloorPlanDraw)="updateFloorPlanDraw($event)"
        [isEditFlow]="true"
        [editClickPreview]="true"
        [activeId]="activeFloorId"
        [isGroupCreationScope]="true"
      ></atl-plan-drawing>
    </div>
  </form>
</div>

<atl-ngb-modal-footer>
  <div class="w-100 d-flex justify-content-end">
    <button
      data-cy="cy-cancel-change-request-btn"
      (click)="closeModal()"
      class="newest-button button-gray fz-16 mr-16 br-32"
    >
      {{ 'Button.Cancel' | translate }}
    </button>
    <ng-container *ngIf="!editMode">
      <atl-button-loader
        data-cy="cy-create-change-request-btn"
        (clickButton)="createRequest()"
        [disabled]="this.form.invalid || fileLoading"
        [newest]="true"
        label="Button.Create_change_request"
      ></atl-button-loader>
    </ng-container>
    <ng-container *ngIf="editMode">
      <atl-button-loader
        data-cy="cy-create-change-request-btn"
        (clickButton)="saveRequest()"
        [disabled]="this.form.invalid || disabledSaveButton"
        [newest]="true"
        label="Shared.Title.Save_changes"
      ></atl-button-loader>
    </ng-container>
  </div>
</atl-ngb-modal-footer>
