<!--
  ******************************************************
  @Cypress
  - interaction with simple-tabs
  ******************************************************
-->
<div class="request__wrapper" (atlCloseModalEsc)="closeModal()" atlWindowInnerHeight>
  <div *ngIf="modalView" class="request__header">
    <div class="request__header-title">
      <ng-container *ngIf="isChangeRequestUnitDetailScope; else defaultTitleTemplate">
        {{ 'Title.Change_request' | translate }}
        - {{ 'Entity.Unit' | translate }} {{ unit?.identifier }}
      </ng-container>
      <ng-template #defaultTitleTemplate>
        {{ 'Title.Change_request_detail' | translate }}
        -
        {{
          advanceMode
            ? (changeRequest?.identifier | noProjectSegmentInChangeRequestIdentifier)
            : changeRequest?.identifier
        }}
      </ng-template>
    </div>
    <div class="request__header-action">
      <atl-dropdown-reports
        *ngIf="!isChangeRequestUnitDetailScope && !unitDetailsScope && showDownload"
        [dropdownToggleTranslateKey]="'Shared.Entity.Download'"
        [items]="dropdownReports"
        (reportOn)="reportOn($event)"
      ></atl-dropdown-reports>
      <div *ngIf="!isChangeRequestUnitDetailScope" class="request__header-action-divider"></div>
      <div class="d-flex">
        <atl-header-icon
          (click)="activityPage()"
          [class.active]="activeTab === requestTab.Activities"
          icon="/assets/analytics-icon.svg"
          [ngbTooltip]="'Shared.Entity.Activities' | translate"
          triggers="hover touchstart"
        ></atl-header-icon>
        <atl-header-icon
          (click)="closeModal()"
          icon="/assets/unit_close.svg"
          [ngbTooltip]="'Alt.Close' | translate"
          triggers="hover touchstart"
        ></atl-header-icon>
      </div>
    </div>
  </div>

  <div *ngIf="isChangeRequestUnitDetailScope" class="request__header-backtoall pt-24 pb-16 pl-24 pr-24">
    <button (click)="modalRef.dismiss()" class="d-flex align-items-center gap-4 p-0">
      <img src="assets/arrow-left-newest.svg" alt="Back" />
      <span>{{ 'Change_request.Units.All_change_requests' | translate }}</span>
    </button>
  </div>

  <atl-simple-tabs
    class="tabs newest-tabs"
    [activeTab]="activeTab"
    [tabs]="navigationMenu"
    [withId]="true"
    [routeNavigation]="false"
    [useTooltip]="true"
    (selectingTab)="selectAnotherTab($event)"
    [scrollControl]="true"
  ></atl-simple-tabs>

  <div
    class="request__container v-scrollbar"
    [ngClass]="{
      'archived-change-req': changeRequest?.archived,
      'floor-plan-view': floorPlanView
    }"
  >
    <ng-container *ngIf="changeRequest?.archived && activeTab !== requestTab.Activities" [ngTemplateOutlet]="warningBlock"></ng-container>
    <ng-container [ngSwitch]="activeTab">
      <ng-container *ngSwitchCase="requestTab.General">
        <ng-container [ngTemplateOutlet]="generalContent"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="requestTab.Customer">
        <ng-container [ngTemplateOutlet]="CustomerNotes"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="requestTab.Internal">
        <ng-container [ngTemplateOutlet]="internalNotes"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="requestTab.Activities">
        <ng-container [ngTemplateOutlet]="activity"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="requestTab.Notes">
        <div
          *ngIf="formNotes" class="request__notes" [class.archived-change-req]="changeRequest?.archived"
        >
          <atl-text-editor-wide
            [class.pointer-events-none]="changeRequest?.archived"
            [valControl]="formNotes.controls['notes']"
            [apiKey]="apiKey"
            [config]="editorInitConfig"
            [disabled]="changeRequest.archived"
          ></atl-text-editor-wide>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container [ngTemplateOutlet]="offerContent"></ng-container>
      </ng-container>
    </ng-container>
    <atl-action-message-banner
      class="action-message-banner_component change-request"
      *ngIf="isShowMessageBanner"
      [messageText]="messageBannerText"
      [closeButton]="false"
      [endCloseButton]="true"
      [spinner]="true"
      (closeEvent)="hideMessageBanner()"
    ></atl-action-message-banner>
  </div>

  <atl-ngb-modal-footer
    *ngIf="
      changeRequest && activeTab === requestTab.General &&
        (requestStatus || isChangeRequestUnitDetailScope || unitDetailsScope)
    "
  >
    <div class="w-100 d-flex justify-content-end">
      <atl-dropdown-reports
        *ngIf="isChangeRequestUnitDetailScope || unitDetailsScope"
        class="dropdown-reports"
        [placement]="'top-left'"
        [dropdownToggleTranslateKey]="'Shared.Entity.Download'"
        [items]="dropdownReports"
        (reportOn)="reportOn($event)"
      ></atl-dropdown-reports>

      <ng-container *ngIf="requestStatus">
        <button
          *ngIf="changeRequest.status === statusKeys.RequestDeclined"
          class="newest-button newest-button-spinner"
          [ngClass]="{ 'loading pointer-events-none': isLoading }"
          (click)="reopenChangeRequest()"
        >
          {{ 'Button.Re-open_request' | translate }}
        </button>
        <button
          *ngIf="changeRequest.status !== statusKeys.RequestDeclined"
          class="button-red newest-button-spinner"
          [ngClass]="{ 'loading pointer-events-none': isLoading }"
          (click)="declineModal()"
        >
          {{ 'Shared.Reclamation.Button.Decline' | translate }}
        </button>
        <button
          *ngIf="changeRequest.status === statusKeys.New"
          (click)="changeInProgress()"
          class="newest-button newest-button-spinner ml-16"
          [ngClass]="{ 'loading pointer-events-none': isLoading }"
          type="button"
        >
          {{ 'Button.Move_to_In_progress' | translate }}
        </button>
        <ng-container *ngIf="!advanceMode">
          <button
            *ngIf="changeRequest.status === statusKeys.InProgress || changeRequest.status === statusKeys.OfferCanceled"
            (click)="makeOffer()"
            class="newest-button newest-button-spinner ml-16"
            type="button"
            [disabled]="!unit"
          >
            {{ 'Button.Make_offer' | translate }}
          </button>
        </ng-container>
      </ng-container>
    </div>
  </atl-ngb-modal-footer>

  <ng-container *ngIf="loadingDocument">
    <atl-progress-loader
      (onclose)="closeDocumentLoading()"
      [title]="'Shared.Title.Generating_document' | translate"
    ></atl-progress-loader>
  </ng-container>
</div>

<ng-template #generalContent>
  <div class="request__info" *ngIf="changeRequest" tabindex="0" [class.archived]="changeRequest.archived">
    <!-- Project -->
    <div *ngIf="projectName" class="request__row">
      <div class="request__field">
        <img [src]="'assets/dropdown-circle.svg'" alt="icon" />
        <span>{{ 'Entity.Project_Name' | translate }}</span>
      </div>
      <div class="request__value d-flex align-items-center pl-8 lh-24 fz-14">
        {{ projectName }}
      </div>
    </div>
    <!-- Unit -->
    <ng-container *ngIf="!order; else orderView">
      <div *ngIf="modalView" class="request__row">
        <div class="request__field">
          <img [src]="'assets/dropdown-circle.svg'" alt="icon" />
          <span>{{ 'Shared.Reclamation.Input.Unit.Label' | translate }}</span>
        </div>
        <div
          class="request__value d-flex align-items-center pl-8 lh-24 fz-14"
          [class.empty-val]="!changeRequest.unit?.identifier"
        >
          {{ changeRequest.unit?.identifier || ('Shared.Reclamation.Input.Unit.Placeholder' | translate) }}
        </div>
      </div>
    </ng-container>

    <ng-template #orderView>
      <div class="request__row">
        <div class="request__field">
          <img [src]="'assets/text-align-left-gray.svg'" alt="icon" />
          <span class="uppercase">{{ 'Shared.Entity.Id' | translate }}</span>
        </div>
        <div class="request__value d-flex align-items-center pl-8 lh-24 fz-14">
          {{ order | number: '2.0-0' }}
        </div>
      </div>
    </ng-template>

    <!--Status-->
    <div class="request__row">
      <div class="request__field">
        <img [src]="'assets/spinner-gray.svg'" alt="icon" />
        <span>{{ 'Shared.Reclamation.Input.Status.Label' | translate }}</span>
      </div>
      <div class="request__value d-flex align-items-center pl-8">
        <div
          *ngIf="changeRequest?.eventLogs?.length"
          class="fz-14 lh-24 pl-8 text-black-960 ellipsis status"
          [ngClass]="'status__' + statusList[changeRequest.status]?.style"
        >
          {{ changeRequest.localizedStatus }}
        </div>

        <span
          *ngIf="changeRequest.status === statusKeys.RequestDeclined"
          (click)="showComment()"
          class="request__details cursor-pointer fz-14 lh-24 fw-400 text-black-810 ml-8"
        >
          {{ 'Shared.Reclamation.Details' | translate }}
          <img src="assets/arrow-newest-gray-down.svg" alt="arrow" />
        </span>
      </div>
    </div>
    <form *ngIf="form" [formGroup]="form">
      <!-- Description -->
      <div class="request__row">
        <div class="request__field">
          <img [src]="'assets/text-align-left-gray.svg'" alt="icon" />
          <span>{{ 'Entity.Description' | translate }}</span>
        </div>
        <div class="request__value description" [class.empty-val]="!changeRequest.description">
          <atl-textarea-autoresize
            formControlName="description"
            [stringMode]="true"
            [maxLength]="descriptionMaxLength"
            [placeholder]="'Shared.Entity.Add_description' | translate"
            [absolutePosition]="true"
            [autoresizeOffset]="2"
            class="newest-textarea"
            [disabled]="!!changeRequest.archived"
            [class.disabled]="!!changeRequest.archived"
          >
          </atl-textarea-autoresize>
        </div>
      </div>

      <!-- Assignee -->
      <div class="request__row">
        <div class="request__field">
          <img [src]="'assets/dropdown-circle.svg'" alt="icon" />
          <span>{{ 'Shared.Entity.Responsible' | translate }}</span>
        </div>
        <div class="request__value min-w-0">
          <atl-drop-down-v2
            [tabIndex]="-1"
            #assigneeSelect
            class="multiple-select-component"
            formControlName="responsible"
            [listOfItems]="users"
            [bindLabel]="'name'"
            [searchParams]="['name', 'email']"
            [placeholder]="
              assigneeSelect.isOpen
                ? ('Entity.Search_for' | translate: { value: ('Shared.Entity.Responsible' | translate) })
                : ('Input.Select.Responsible' | translate)
            "
            [notFoundText]="'Shared.Entity.Not_found' | translate"
            [useCustomItemView]="true"
            [useCustomLabelView]="true"
          >
            <ng-template atlInputLabelTemplate let-item="item">
              <div class="multiple-select-user-card-wrapper min-w-0">
                <div class="min-w-0">
                  <div
                    class="multiple-select-user-card-icon flex-shrink-0"
                    [ngStyle]="{ 'background-color': item.color, border: '1px solid ' + item.borderColor }"
                  >
                    <span>{{ item?.name | firmPrefix }}</span>
                  </div>
                  <span class="d-block ellipsis">{{ item?.name | truncate: nameTruncate }}</span>
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (click)="onRemoveSelectedItem('responsible', item)"
                  />
                </div>
                <img
                  class="multiple-select-user-card-checkbox"
                  src="/assets/multiple_select_checkbox.svg"
                  alt="Check state"
                />
              </div>
            </ng-template>
            <ng-template atlInputTemplate let-item="item" let-selected="selected">
              <div class="multiple-select-user-card-wrapper">
                <div class="d-flex min-w-0 flex-shrink-1">
                  <div
                    class="multiple-select-user-card-icon flex-shrink-0"
                    [ngStyle]="{ 'background-color': item.color, border: '1px solid ' + item.borderColor }"
                  >
                    <span>{{ item?.name | firmPrefix }}</span>
                  </div>
                  <div class="d-flex flex-column min-w-0">
                    <p
                      class="request-create-dropdown-user-name d-block ellipsis fz-14 lh-24 fw-400"
                      [class.selected]="selected"
                    >
                      {{ item?.name | truncate: nameTruncate }}
                    </p>
                    <p class="request-create-dropdown-user-email d-block ellipsis fz-12 lh-16 fw-400">
                      {{ item?.email }}
                    </p>
                  </div>
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (click)="onRemoveSelectedItem('responsible', item)"
                  />
                </div>
                <img
                  class="multiple-select-user-card-checkbox"
                  src="/assets/multiple_select_checkbox.svg"
                  alt="Check state"
                />
              </div>
            </ng-template>
          </atl-drop-down-v2>
        </div>
      </div>

      <!--Attachments-->
      <div class="request__row">
        <div class="request__field">
          <img [src]="'assets/attachment_gray.svg'" alt="icon" />
          <span>{{ 'Shared.Reclamation.Input.Attachments.Label' | translate }}</span>
        </div>
        <div class="request__value" [class.empty-val]="!changeRequest.fileResources?.length">
          <atl-dropdown-input-file
            formControlName="fileResources"
            class="request-detail-dropdown"
            [format]="acceptedExtensions"
            [placeholder]="'Shared.Entity.Add_attachments' | translate"
            (viewTempFilesEmitter)="openPreview($event)"
            [disableChangePosition]="true"
            (addNewFilesToExisting)="addNewFiles($event)"
            (deleteExistingFile)="deleteExistingFile($event)"
            [autoSave]="true"
            [isLoading]="fileLoading"
          ></atl-dropdown-input-file>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="advanceMode && changeRequest.status !== statusKeys.New" class="request__info">
    <form *ngIf="prefillForm" [formGroup]="prefillForm" tabindex="0">
      <div class="request__row">
        <div class="request__field">
          <img [src]="'assets/hash.svg'" alt="icon" />
          <span>
            {{ 'Shared.Title.Offer_price' | translate }}
          </span>
          <img
            class="ml-8 cursor-pointer"
            [src]="'assets/reclamation-info.svg'"
            alt="info"
            [ngbTooltip]="'Change_request.Advance.Detail_tooltip' | translate"
            triggers="hover touchstart"
            placement="bottom-left"
          />
        </div>
        <div class="request__value description">
          <atl-input
            class="newest-input--number"
            placeholder="Title.Type_price"
            formControlName="price"
            mask="separator.0"
            separatorLimit="100000000000"
            thousandSeparator=" "
          ></atl-input>
        </div>
      </div>

      <div class="request__row">
        <div class="request__field">
          <img [src]="'assets/text-align-left-gray.svg'" alt="icon" />
          <span
            #offerDescription
            class="d-block ellipsis"
            [ngbTooltip]="'Shared.Title.Offer_description' | translate"
            [disableTooltip]="offerDescription.offsetWidth >= offerDescription.scrollWidth"
            triggers="hover touchstart"
          >
            {{ 'Shared.Title.Offer_description' | translate }}
          </span>
        </div>
        <div class="request__value description">
          <atl-textarea-autoresize
            formControlName="description"
            [stringMode]="true"
            [maxLength]="descriptionMaxLength"
            [placeholder]="'Shared.Entity.Add_description' | translate"
            [absolutePosition]="true"
            [autoresizeOffset]="2"
            class="newest-textarea"
          >
          </atl-textarea-autoresize>
        </div>
      </div>

      <div class="request__row">
        <div class="request__field">
          <img [src]="'assets/dropdown-circle.svg'" alt="icon" />
          <span>{{ 'Shared.Change_request.Combine_Offer.Label.Floor_plan_version' | translate }}</span>
        </div>
        <div class="request__value description">
          <atl-drop-down-v2
            class="multiple-select-component"
            formControlName="versionNumber"
            [listOfItems]="versionList"
            [bindLabel]="'version'"
            [placeholder]="'Shared.Entity.Select_version'"
            [notFoundText]="'Shared.Entity.Not_found' | translate"
            [useCustomItemView]="true"
            [useCustomLabelView]="true"
            [selectOnBlur]="true"
          >
            <ng-template atlInputTemplate let-item="item">
              <div class="multiple-select-user-card-wrapper">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <span class="w-50 d-flex align-items-center text-black-960">
                    {{ 'Shared.Entity.Version' | translate }} {{ item.version }}
                  </span>
                  <span *ngIf="item.date" class="w-50 d-block ellipsis text-gray-890">
                    {{ 'Shared.Entity.Created' | translate }} {{ item.date | date: 'dd.MM.yyyy' }}
                  </span>
                  <img
                    class="multiple-select-user-card-checkbox"
                    src="/assets/multiple_select_checkbox.svg"
                    alt="Check state"
                  />
                </div>
              </div>
            </ng-template>
            <ng-template atlInputLabelTemplate let-item="item">
              <div class="multiple-select-user-card-wrapper">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="d-flex align-items-center text-black-960">
                    {{ 'Shared.Entity.Version' | translate }} {{ item.version }}
                  </span>
                </div>
              </div>
            </ng-template>
          </atl-drop-down-v2>
        </div>
      </div>

      <div class="request__row">
        <div class="request__field">
          <img [src]="'assets/attachment_gray.svg'" alt="icon" />
          <span>{{ 'Shared.Reclamation.Input.Attachments.Label' | translate }}</span>
        </div>
        <div class="request__value">
          <atl-dropdown-input-file
            formControlName="fileResources"
            class="request-detail-dropdown"
            [format]="acceptedExtensions"
            [placeholder]="'Shared.Entity.Add_attachments' | translate"
            (viewTempFilesEmitter)="openPreview($event)"
            [disableChangePosition]="true"
            (addNewFilesToExisting)="addNewPrefillFiles($event)"
            (deleteExistingFile)="deleteExistingPrefillFile($event)"
            [autoSave]="true"
            [isLoading]="fileLoading"
            dragGroup="advanceMode"
          ></atl-dropdown-input-file>
        </div>
      </div>
    </form>
  </div>

  <div class="request__floor mt-24" *ngIf="floorPlanData && unitId">
    <atl-plan-drawing
      [displayFloors]="true"
      [unitId]="unitId"
      [isEditFlow]="false"
      [drawVersion]="true"
      [floorPlan]="floorPlanData"
      [floorType]="unit?.hasOwnFloorPlans ? floorType.UnitFloor : floorType.Floor"
      [floorDrawVersions]="changeRequest.floorDrawVersions"
      (updateFloorPlanDraw)="updateFloorPlanDraw($event)"
      [isAlternative]="isAlternative"
    ></atl-plan-drawing>
  </div>
</ng-template>

<ng-template #offerContent>
  <atl-offers-list
    [offers]="offers"
    [projectId]="projectId"
    [changeRequest]="changeRequest"
    (changeStatus)="changeStatus()"
    [makeOfferModal]="makeOfferModal$"
    (updateOfferList)="updateOfferList($event)"
    [isDeclined]="changeRequest.status === statusKeys.RequestDeclined"
    [floorPlanData]="floorPlanData"
    [unit]="unit"
    [unitId]="unitId"
    [class.unit-details-offers]="!modalView"
  ></atl-offers-list>
</ng-template>

<ng-template #CustomerNotes>
  <atl-change-request-customer-notes
    [advanceMode]="advanceMode"
    [threadViewComponent]="threadViewComponent"
    [changeRequest]="changeRequest"
    [unit]="unit"
  ></atl-change-request-customer-notes>
</ng-template>

<ng-template #internalNotes>
  <atl-change-request-internal-notes
    [advanceMode]="advanceMode"
    [threadViewComponent]="threadViewComponent"
    [changeRequest]="changeRequest"
  ></atl-change-request-internal-notes>
</ng-template>

<ng-template #activity>
  <atl-change-request-activity
    [identifier]="changeRequest.identifier!"
    [logActivity]="changeRequest.eventLogs"
  ></atl-change-request-activity>
</ng-template>

<ng-template #warningBlock>
  <div class="request__warning mt-24 mr-24 ml-24">
    <div>
      <atl-custom-icon [icon]="warningIcon"></atl-custom-icon>
    </div>
    <div class="request__warning-text fz-14 lh-24">
      <span class="fw-600">{{ 'Entity.Warning' | translate }}: </span>
      {{ 'Change_request.Warning.Unit_is_transfered' | translate }}
    </div>
  </div>
</ng-template>
