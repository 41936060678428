import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Expose, Transform, Type } from 'class-transformer';
import dayjs from 'dayjs';
import { round } from 'lodash';

import { IUnitItem } from '../interface/chat-filter.interface';
import { IFloorDrawToSave, IKonvaObject } from '../interface/konva.interface';
import { AdminModel, AdminProjectModel } from './admin.model';
import {FileModel, PreloadedFile} from './file.model';
import { ImageModel } from './image.model';
import { IFloorType, IMark } from './reclamations.model';
import { ITablePagination } from './table-pagination.interface';
import { TermsConditionsDataModel } from './terms-conditions.model';
import { IThreadState } from './thread.model';
import { FloorModel, IUnitMainBuyer, UnitFloorModel, UnitUserModel } from './unit.model';
import { IUser } from './user-model';

export enum EChangeRequestTab {
  General = 'Shared.Button.Details',
  Internal = 'Reclamation.Tab.Discussion',
  Offer = 'Shared.Title.Offer',
  Customer = 'Title.Customer_communication',
  ClientCustomer = 'Shared.Title.Messages',
  Activities = 'Shared.Entity.Activities',
  Notes = 'Shared.Title.Internal_notes',
}

export enum EChangeRequestTabId {
  General = 'general',
  Internal = 'internal',
  Offer = 'offers',
  Customer = 'customer',
  ClientCustomer = 'customer',
  Activities = 'activities',
  Notes = 'notes',
}

export interface IChangeRequestCount {
  [Key: string]: { count: number; localizedStatus: string };
}

export enum EChangeRequestStatus {
  New = 'pending',
  InProgress = 'in_progress',
  Offered = 'offer_sent',
  OfferExpired = 'offer_expired',
  OfferApproved = 'offer_approved',
  OfferDeclined = 'offer_declined',
  OfferCanceled = 'offer_cancelled',
  Approved = 'approved',
  RequestDeclined = 'request_declined',
  Draft = 'draft',
}

export const statusClasses = {
  [EChangeRequestStatus.New]: 'status__new',
  [EChangeRequestStatus.InProgress]: 'status__in-progress',
  [EChangeRequestStatus.Approved]: 'status__in-progress',
  [EChangeRequestStatus.RequestDeclined]: 'status__request-declined',
  [EChangeRequestStatus.Offered]: 'status__offered',
  [EChangeRequestStatus.OfferExpired]: 'status__offer-expired',
  [EChangeRequestStatus.OfferApproved]: 'status__offer-approved',
  [EChangeRequestStatus.OfferDeclined]: 'status__offer-declined',
  [EChangeRequestStatus.OfferCanceled]: 'status__offer-canceled',
};

export enum EChangeRequestStatusTitle {
  New = 'Shared.Change_request.Status.New',
  Approved = 'Shared.Change_request.Status.Offer_accepted',
  Declined = 'Shared.Change_request.Status.Declined',
  InProgress = 'Shared.Change_request.Status.In_progress',
  Offered = 'Shared.Change_request.Offer_sent',
  OfferExpired = 'Shared.Change_request.Offer_expired',
  OfferApproved = 'Shared.Change_request.Status.Offer_accepted',
  OfferDeclined = 'Shared.Change_request.Offer_declined',
  OfferCanceled = 'Shared.Change_request.Offer_canceled',
}

export enum EOfferStatusTitle {
  Offered = 'Shared.Change_request.Offer.Sent',
  OfferExpired = 'Shared.Change_request.Offer.Expired',
  OfferApproved = 'Shared.Change_request.Offer.Approved',
  OfferDeclined = 'Shared.Change_request.Offer.Declined',
  OfferCanceled = 'Shared.Change_request.Offer.Canceled',
  Draft = 'Shared.Reclamation.Status.Draft',
}

export const changeReqStatusList = {
  [EChangeRequestStatus.New]: {
    style: 'new',
    title: EChangeRequestStatusTitle.New,
  },
  [EChangeRequestStatus.InProgress]: {
    style: 'in-progress',
    title: EChangeRequestStatusTitle.InProgress,
  },
  [EChangeRequestStatus.Approved]: {
    style: 'approved',
    title: EChangeRequestStatusTitle.Approved,
  },
  [EChangeRequestStatus.Offered]: {
    style: 'offered',
    title: EChangeRequestStatusTitle.Offered,
  },
  [EChangeRequestStatus.RequestDeclined]: {
    style: 'request-declined',
    title: EChangeRequestStatusTitle.Declined,
  },
  [EChangeRequestStatus.OfferExpired]: {
    style: 'offer-expired',
    title: EChangeRequestStatusTitle.OfferExpired,
  },
  [EChangeRequestStatus.OfferApproved]: {
    style: 'offer-approved',
    title: EChangeRequestStatusTitle.OfferApproved,
  },
  [EChangeRequestStatus.OfferDeclined]: {
    style: 'offer-declined',
    title: EChangeRequestStatusTitle.OfferDeclined,
  },
  [EChangeRequestStatus.OfferCanceled]: {
    style: 'offer-canceled',
    title: EChangeRequestStatusTitle.OfferCanceled,
  },
  [EChangeRequestStatus.Draft]: {
    style: 'offer-expired',
    title: EOfferStatusTitle.Draft,
  },
};

export const offerStatusList = {
  [EChangeRequestStatus.Offered]: {
    style: 'offered',
    title: EOfferStatusTitle.Offered,
  },
  [EChangeRequestStatus.OfferExpired]: {
    style: 'offer-expired',
    title: EOfferStatusTitle.OfferExpired,
  },
  [EChangeRequestStatus.OfferApproved]: {
    style: 'offer-approved',
    title: EOfferStatusTitle.OfferApproved,
  },
  [EChangeRequestStatus.OfferDeclined]: {
    style: 'offer-declined',
    title: EOfferStatusTitle.OfferDeclined,
  },
  [EChangeRequestStatus.OfferCanceled]: {
    style: 'offer-canceled',
    title: EOfferStatusTitle.OfferCanceled,
  },
  [EChangeRequestStatus.Draft]: {
    style: 'offer-expired',
    title: EOfferStatusTitle.Draft,
  },
};

export class ChangeRequestOfferModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'expiration_date' })
  @Transform(({ value }) => (value ? dayjs(value).format('D.MM.YYYY') : ''))
  expirationDate!: string;
  @Expose({ name: 'price' })
  price!: number;
  @Expose({ name: 'title' })
  title!: string;
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources?: (FileModel | ImageModel)[];
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'version_number' })
  versionNumber!: number;

  selected: boolean;

  constructor() {
    this.selected = false;
  }
}

export class FloorDrawVersionModel {
  @Expose({ name: 'change_request_id' })
  changeRequestId!: number;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'creator' })
  creator!: {
    id: number;
    name: string;
    type: string;
  };
  @Expose({ name: 'draw_data' })
  drawData!: IKonvaObject;

  @Expose({ name: 'filename' })
  filename!: {
    name: string | null;
    url: string | null;
  };
  @Expose({ name: 'filename_remote_url' })
  filenameRemoteUrl!: string;

  @Expose({ name: 'floor' })
  @Type(() => FloorModel)
  floor?: FloorModel;
  @Expose({ name: 'floor_type' }) floorType!: string;
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'version_number' }) versionNumber!: number;
  @Expose({ name: 'floor_name' }) floorName!: string;
  @Expose({ name: 'floor_number' }) floorNumber!: number;
}

export class ChangeRequestEventLogModal {
  @Expose({ name: 'change_request_offer_id' })
  changeRequestOfferId!: number | null;
  @Expose({ name: 'comment' })
  comment!: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'creator' })
  creator!: { id: number; name: string };
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'new_responsible' })
  newResponsible!: { id: number; name: string };
}

export class ChangeRequestTypeModal {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'kind' })
  kind!: string;
  @Expose({ name: 'firm_id' })
  firmId!: number;
}

export class ChangeRequestResponseModel {
  @Expose({ name: 'change_requests' })
  @Type(() => ChangeRequestListModel)
  changeRequests!: ChangeRequestListModel[];
  @Expose({ name: 'status_stats' })
  @Transform(({ value }) => {
    return value.reduce((acc: IChangeRequestCount, val: any) => {
      acc[val.name] = { count: val.count, localizedStatus: val.localized_status };
      return acc;
    }, {});
  })
  statusStats!: IChangeRequestCount;
  @Expose({ name: 'total_count' })
  totalCount!: number;
}

export class UnitChangeRequestModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'address' })
  address!: string | null;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'primary_alphabetically_owner' })
  primaryOwner!: {
    email: string;
    id: number;
    name: string;
    phone: string;
  };
  @Expose({ name: 'property_type' })
  propertyType!: string;
  @Expose({ name: 'sale_status' })
  saleStatus!: string;
}

export class ChangeRequestListModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier?: string;
  @Expose({ name: 'unit' })
  @Type(() => UnitChangeRequestModel)
  unit!: UnitChangeRequestModel;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'responsible' })
  @Transform(({ value }) => value || null)
  responsible?: Partial<IUser> | null;
  @Expose({ name: 'description' })
  description?: string;
  @Expose({ name: 'created_at' })
  createdAt?: string;
  @Expose({ name: 'active_offer_price' })
  activeOfferPrice!: number | null;
  @Expose({ name: 'message_thread_id' })
  messageThreadId?: number;
  @Expose({ name: 'message_thread_unread_count' })
  messageThreadUnreadCount?: number;
  @Expose({ name: 'notes_message_thread_unread_count' })
  notesMessageThreadUnreadCount?: number;
  @Expose({ name: 'message_thread_state' })
  messageThreadState!: IThreadState;
  @Expose({ name: 'message_thread_has_messages' })
  messageThreadHasMessages!: boolean;
  @Expose({ name: 'viewed' })
  viewed!: boolean;

  selected?: boolean;
}

export class PrefillOfferInfo {
  @Expose({ name: 'id' }) id?: number;
  @Transform(({ value }) => value || 0)
  @Expose({ name: 'price' })
  price!: number;
  @Transform(({ value }) => value || '')
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'version_number' }) versionNumber!: number;
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources!: (FileModel | ImageModel | File)[];
}

export interface IPrefillOfferForm {
  id: number;
  price: string | number;
  description: string;
  versionNumber: IVersionList[];
  fileResources: (FileModel | ImageModel | File)[];
}

export class ChangeRequestModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier?: string;
  @Expose({ name: 'unit' })
  unit!: Partial<UnitUserModel>;
  @Expose({ name: 'event_logs' })
  @Type(() => ChangeRequestEventLogModal)
  eventLogs!: ChangeRequestEventLogModal[];
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'responsible' })
  @Transform(({ value }) => value || null)
  responsible?: Partial<IUser> | null;
  @Expose({ name: 'description' })
  description?: string;
  @Expose({ name: 'created_at' })
  createdAt?: string;
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources?: (FileModel | ImageModel)[];
  @Expose({ name: 'floor_id' })
  floorId?: number;
  @Expose({ name: 'floor_type' })
  floorType?: string;
  @Expose({ name: 'message_thread_id' })
  messageThreadId?: number;
  @Expose({ name: 'message_thread_unread_count' })
  messageThreadUnreadCount?: number;
  @Expose({ name: 'notes_message_thread_id' })
  notesMessageThreadId?: number;
  @Expose({ name: 'notes_message_thread_unread_count' })
  notesMessageThreadUnreadCount?: number;
  @Expose({ name: 'message_thread_state' })
  messageThreadState!: IThreadState;
  @Expose({ name: 'message_thread_has_messages' })
  messageThreadHasMessages!: boolean;
  @Expose({ name: 'active_offer_id' })
  activeOfferId!: null | number;
  @Expose({ name: 'active_offer_price' })
  activeOfferPrice!: number | null;
  @Expose({ name: 'floor_draw_versions' })
  @Type(() => FloorDrawVersionModel)
  floorDrawVersions!: FloorDrawVersionModel[];
  @Expose({ name: 'viewed' })
  viewed!: boolean;
  @Expose({ name: 'custom_note_id' })
  customNoteId!: number | null;
  @Expose({ name: 'archived' })
  archived!: boolean;
  @Expose({ name: 'prefill_offer_info' })
  @Type(() => PrefillOfferInfo)
  prefillOfferInfo!: PrefillOfferInfo | null;

  selected?: boolean;
}

export class GlobalChangeRequestResponseModel {
  @Expose({ name: 'change_requests' })
  @Type(() => GlobalChangeRequestModel)
  changeRequests!: GlobalChangeRequestModel[];
  @Expose({ name: 'status_stats' })
  @Transform(({ value }) => {
    return value.reduce((acc: IChangeRequestCount, val: any) => {
      acc[val.name] = { count: val.count, localizedStatus: val.localized_status };
      return acc;
    }, {});
  })
  statusStats!: IChangeRequestCount;
  @Expose({ name: 'project_data' })
  projectsWithAmounts!: IGlobalChangeRequestProject[];
  @Expose({ name: 'total_count' })
  totalCount!: number;
}

export interface IGlobalChangeRequestProject {
  id: number;
  name: string;
  amount?: number;
  selected?: boolean;
}

export class GlobalChangeRequestModel {
  @Expose({ name: 'active_offer_price' })
  activeOfferPrice!: number;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'id' })
  id!: string;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'unit' })
  unit!: Partial<UnitUserModel>;
  @Expose({ name: 'project' })
  project!: IGlobalChangeRequestProject;
  @Expose({ name: 'message_thread_id' })
  messageThreadId?: number;
  @Expose({ name: 'message_thread_unread_count' })
  messageThreadUnreadCount?: number;
  @Expose({ name: 'notes_message_thread_id' })
  notesMessageThreadId?: number;
  @Expose({ name: 'notes_message_thread_unread_count' })
  notesMessageThreadUnreadCount?: number;
  @Expose({ name: 'message_thread_state' })
  messageThreadState!: IThreadState;
  @Expose({ name: 'message_thread_has_message' })
  messageThreadHasMessages!: boolean;
}

export class ClientChangeRequestListModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier?: string;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'description' })
  description?: string;
  @Expose({ name: 'created_at' })
  createdAt?: string;
  @Expose({ name: 'message_thread_id' })
  messageThreadId?: number;
  @Expose({ name: 'message_thread_unread_count' })
  messageThreadUnreadCount?: number;
  @Expose({ name: 'active_offer_id' })
  activeOfferId?: number;
  @Expose({ name: 'message_thread_state' })
  messageThreadState!: IThreadState;
  @Expose({ name: 'message_thread_has_messages' })
  messageThreadHasMessages!: boolean;
  @Expose({ name: 'active_offer_price' })
  activeOfferPrice!: number | null;
  @Expose({ name: 'active_offer_viewed_by_user' })
  viewed!: boolean;
}

export class ClientChangeRequestModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier?: string;
  @Expose({ name: 'event_logs' })
  @Type(() => ChangeRequestEventLogModal)
  eventLogs!: ChangeRequestEventLogModal[];
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'description' })
  description?: string;
  @Expose({ name: 'created_at' })
  createdAt?: string;
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources?: (FileModel | ImageModel)[];
  @Expose({ name: 'floor_id' })
  floorId?: number;
  @Expose({ name: 'floor_type' })
  floorType?: string;
  @Expose({ name: 'message_thread_id' })
  messageThreadId?: number;
  @Expose({ name: 'message_thread_unread_count' })
  messageThreadUnreadCount?: number;
  @Expose({ name: 'active_offer_id' })
  activeOfferId?: number;
  @Expose({ name: 'message_thread_state' })
  messageThreadState!: IThreadState;
  @Expose({ name: 'message_thread_has_messages' })
  messageThreadHasMessages!: boolean;
  @Expose({ name: 'floor_draw_versions' })
  @Type(() => FloorDrawVersionModel)
  floorDrawVersions!: FloorDrawVersionModel[];
  @Expose({ name: 'active_offer_price' })
  activeOfferPrice!: number | null;
  @Expose({ name: 'active_offer_viewed_by_user' })
  viewed!: boolean;
}

export class ClientChangeRequestResponseModel {
  @Expose({ name: 'change_requests' })
  @Type(() => ClientChangeRequestListModel)
  changeRequests!: ClientChangeRequestListModel[];
  @Expose({ name: 'status_stats' })
  @Transform(({ value }) => {
    return value.reduce((acc: IChangeRequestCount, val: any) => {
      acc[val.name] = { count: val.count, localizedStatus: val.localized_status };
      return acc;
    }, {});
  })
  statusStats!: IChangeRequestCount;
  pagination!: ITablePagination;
}

export class PdfDocumentUrlModel {
  @Expose({ name: 'url' })
  url!: string;
  @Expose({ name: 'download_url' })
  downloadUrl!: string;
}

export class DocumentPdfModel {
  @Expose({ name: 'entity_type' })
  entityType!: string;
  @Expose({ name: 'entity_id' })
  entityId!: number;
  @Expose({ name: 'file_size' })
  fileSize!: number | null;
  @Expose({ name: 'file_extension' })
  fileExtension!: string | null;
  @Expose({ name: 'document' })
  @Type(() => PdfDocumentUrlModel)
  document!: PdfDocumentUrlModel;
}

export class RemoveOfferModel {
  @Expose({ name: 'entity_type' })
  message!: string;
  @Expose({ name: 'new_change_request_status' })
  newStatus!: EChangeRequestStatus;
}

export enum EChangeRequestStatusFilter {
  All = '',
  Pending = 'pending',
  OfferSent = 'offer_sent',
  OfferExpired = 'offer_expired',
  OfferDeclined = 'offer_declined',
  OfferCancelled = 'offer_cancelled',
  OfferApproved = 'offer_approved',
  RequestDeclined = 'request_declined',
  InProgress = 'in_progress',
}

export interface IStatusArray {
  status: EChangeRequestStatusFilter;
  title: EChangeRequestStatusTitle;
  active: boolean;
}

export const changeRequestStatusList: IStatusArray[] = [
  { status: EChangeRequestStatusFilter.Pending, title: EChangeRequestStatusTitle.New, active: false },
  { status: EChangeRequestStatusFilter.InProgress, title: EChangeRequestStatusTitle.InProgress, active: false },
  { status: EChangeRequestStatusFilter.OfferSent, title: EChangeRequestStatusTitle.Offered, active: false },
  { status: EChangeRequestStatusFilter.OfferApproved, title: EChangeRequestStatusTitle.Approved, active: false },
  { status: EChangeRequestStatusFilter.OfferExpired, title: EChangeRequestStatusTitle.OfferExpired, active: false },
  { status: EChangeRequestStatusFilter.RequestDeclined, title: EChangeRequestStatusTitle.Declined, active: false },
  { status: EChangeRequestStatusFilter.OfferDeclined, title: EChangeRequestStatusTitle.OfferDeclined, active: false },
  { status: EChangeRequestStatusFilter.OfferCancelled, title: EChangeRequestStatusTitle.OfferCanceled, active: false },
];

export interface IChangeRequestFilter {
  projects?: number[];
  units: number[];
  status: string[];
  responsible: number[];
  date: { from: string; to: string | null } | null;
}

export interface IChangeRequestUnitsViewFilter {
  only: boolean;
}

export interface IChangeRequestListsForFilter {
  units: IUnitItem[];
  projects: IGlobalChangeRequestProject[];
  users: AdminModel[];
}

export enum EChangeRequestType {
  Simple = 'simple',
  Advanced = 'advanced',
}

export class ChangeRequestSettingUnitConfigModel {
  id!: number;
  @Expose({ name: 'member_id' })
  unitId!: number;
  @Expose({ name: 'member_type' })
  unitType!: 'Unit' | 'LayoutType';
  @Expose({ name: 'closed_date' })
  closedDate!: string | null;
  @Expose({ name: 'responsible' })
  responsible!: any | null;
}

export class ChangeRequestSettingModel {
  @Expose({ name: 'project_id' })
  projectId!: number;
  @Expose({ name: 'allow_bank_id' })
  allowBankId!: boolean;
  @Expose({ name: 'layout_option_sync' })
  layoutOptionSync!: boolean;
  @Expose({ name: 'terms_conditions' })
  termsConditions!: boolean;
  @Expose({ name: 'open_date' })
  openDate!: string | null;
  @Expose({ name: 'closed_date' })
  closedDate!: string | null;
  @Expose({ name: 'terms_conditions_data' })
  @Type(() => TermsConditionsDataModel)
  termsConditionsData!: TermsConditionsDataModel;
  @Expose({ name: 'is_now_open_date' })
  startOpenDate!: boolean;
  @Expose({ name: 'is_now_closed_date' })
  startCloseDate!: boolean;
  @Expose({ name: 'units_configs' })
  @Type(() => ChangeRequestSettingUnitConfigModel)
  unitsConfigs!: ChangeRequestSettingUnitConfigModel[];
  @Expose({ name: 'layout_types_configs' })
  @Type(() => ChangeRequestSettingUnitConfigModel)
  unitGroupsConfigs!: ChangeRequestSettingUnitConfigModel[];
  @Expose({ name: 'request_type' })
  requestType!: EChangeRequestType;
  @Expose({ name: 'description' })
  @Transform(({ value }) => value || '')
  description!: string;
  @Expose({ name: 'is_change_requests_present' }) isChangeRequestsPresent!: boolean;
}

export interface IReqChangeRequestUnitConfigSetting {
  id?: number;
  member_id?: number;
  closed_date?: string | null;
  responsible_id?: number | null;
  _destroy?: boolean;
}

export interface IReqChangeRequestSetting {
  allow_bank_id: boolean;
  layout_option_sync: boolean;
  terms_conditions: boolean;
  closed_date?: string;
  units_configs_attributes: IReqChangeRequestUnitConfigSetting[];
  layout_types_configs_attributes: IReqChangeRequestUnitConfigSetting[];
}

export class OptionChangeRequestModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'status' })
  status!: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'price' })
  price!: number;
  @Expose({ name: 'due_date' })
  dueDate!: string;
}

export class OptionCombinedOffersModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'requests_group' })
  requestsGroup!: { id: number; identifier: string };
  @Expose({ name: 'status' })
  status!: string;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'price' })
  price!: number;
  @Expose({ name: 'expiration_date' })
  dueDate!: string;
  @Expose({ name: 'title' })
  title!: string;
}

export interface IFloorItemVersionList {
  plan: FloorDrawVersionModel | FloorModel | UnitFloorModel | undefined;
  selected: boolean;
}

export interface IFloorVersionList {
  version: number;
  creator: string;
  createAt: string;
  floor: IFloorItemVersionList[];
}

export enum EChangeRequestsView {
  List = 1,
  Unit,
  Message,
}

export enum EGroupView {
  List = 1,
  Floor,
}

class ChangeRequestsUnitStatsModel {
  total!: number;
  accepted!: number;
  @Expose({ name: 'processed_requests' }) processedRequests!: number;
  @Expose({ name: 'progress_percentage' })
  progressPercentage!: number;
  @Expose({ name: 'total_price' }) totalPrice!: number | null;
}

export class ChangeRequestsUnitModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier!: string;

  @Expose({ name: 'main_primary_owner' })
  mainPrimaryOwner!: IUnitMainBuyer | null;

  primaryOwners?: IUnitMainBuyer[];
  @Expose({ name: 'co_owners' })
  coOwners!: IUnitMainBuyer[];

  @Expose({ name: 'change_requests_stats' })
  @Type(() => ChangeRequestsUnitStatsModel)
  changeRequestsStats!: ChangeRequestsUnitStatsModel;

  @Expose({ name: 'unread_messages_count' })
  unreadMessagesCount!: number;
  @Expose({ name: 'open_date' })
  openDate!: string | null;
  @Expose({ name: 'closed_date' })
  closedDate!: string | null;

  @Expose({ name: 'unit_config' })
  unitConfig!: null | unknown; //TODO: to be announced later
  @Expose({ name: 'total_combined_offers_price' }) totalCombineOffersPrice!: number | null;
}

export class GlobalChangeRequestsUnitModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'identifier' }) identifier!: string;
  @Expose({ name: 'main_primary_owner' }) mainPrimaryOwner!: IUnitMainBuyer | null;
  @Expose({ name: 'co_owners' }) coOwners!: IUnitMainBuyer[];
  @Expose({ name: 'change_requests_stats' })
  @Type(() => ChangeRequestsUnitStatsModel)
  changeRequestsStats!: ChangeRequestsUnitStatsModel;
  @Expose({ name: 'unread_messages_count' }) unreadMessagesCount!: number;
  @Expose({ name: 'open_date' }) openDate!: string | null;
  @Expose({ name: 'closed_date' }) closedDate!: string | null;
  @Expose({ name: 'project' }) project!: {id: number; name: string};
}

export class ChangeRequestsUnitResponseModel {
  @Expose({ name: 'units' })
  @Type(() => ChangeRequestsUnitModel)
  units!: ChangeRequestsUnitModel[];
}

export class PricingDetails {
  @Expose({ name: 'value' }) value: number | null = null;
  @Expose({ name: 'include_in_total' }) includeInTotal = true;

  constructor(value?: number, includeInTotal?: boolean) {
    if (value !== undefined) {
      this.value = value;
    }

    if (includeInTotal !== undefined) {
      this.includeInTotal = includeInTotal;
    }
  }
}

export class PricingVatDetails extends PricingDetails {
  @Expose({ name: 'value' }) value = 25;
}

export class PricingMarkupDetails extends PricingDetails {
  @Expose({ name: 'value' }) value = 12;
}

export class AdditionalCostsDetail {
  @Expose({ name: 'value' }) value: string | number | null = null;
  @Expose({ name: 'name' }) name = '';
}

export class PricingComponents {
  @Expose({ name: 'vat' })
  @Type(() => PricingVatDetails)
  vat!: PricingVatDetails;
  @Expose({ name: 'markup' })
  @Type(() => PricingMarkupDetails)
  markup!: PricingMarkupDetails;
  @Expose({ name: 'calculation_cost' })
  @Type(() => PricingDetails)
  @Transform(({ value }) => value || new PricingDetails(0, false))
  calculationCost!: PricingDetails;
  @Expose({ name: 'administration_cost' })
  @Type(() => PricingDetails)
  @Transform(({ value }) => value || new PricingDetails(0, false))
  administrationCost!: PricingDetails;
  @Expose({ name: 'additional_costs' })
  @Type(() => AdditionalCostsDetail)
  additionalCosts: AdditionalCostsDetail[] = [];

  constructor() {
    this.vat = new PricingVatDetails();
    this.markup = new PricingMarkupDetails();
    this.calculationCost = new PricingDetails();
    this.administrationCost = new PricingDetails();
  }

  get calculationCostValidate(): boolean {
    return this.calculationCost.includeInTotal ? typeof this.calculationCost.value === 'number' : true;
  }

  get administrationCostValidate(): boolean {
    return this.administrationCost.includeInTotal ? typeof this.administrationCost.value === 'number' : true;
  }

  addAdditionalCosts(name: string, price: number): void {
    this.additionalCosts.push({ name: name, value: price });
  }

  updateAdditionalCosts(name: string, price: number, index: number): void {
    this.additionalCosts[index] = { name: name, value: price };
  }

  removeAdditionalCosts(index: number): void {
    this.additionalCosts.splice(index, 1);
  }
}

// Admin models!
export interface ICreationList {
  floorPlanId: number;
  floorPlanData: UnitFloorModel | FloorModel;
  markData: IMark;
}

export interface IGroupChangeRequestsList {
  order: number;
  floorId: number;
  data: {
    description: string;
    responsible: AdminProjectModel[];
    attachments: File[];
  };
  drawingData: IFloorDrawToSave[];
}

export interface IGroupCreationChangeRequestsList {
  description: string;
  responsible_id: number;
  unit_id: number;
  file_resources_attributes: File[];
  floor_draw_versions_attributes: IFloorDrawToSave[];
  floor_id: number;
  floor_type: IFloorType;
  point_x: number;
  point_y: number;
}

export class ChangeRequestGroupListModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'identifier' }) identifier!: string;
  @Expose({ name: 'description' }) description!: string;
  @Expose({ name: 'status' }) status!: EChangeRequestStatus;
  @Expose({ name: 'point_x' }) pointX!: number;
  @Expose({ name: 'point_y' }) pointY!: number;
  @Expose({ name: 'message_thread_unread_count' }) messageThreadUnreadCount!: number;
  @Expose({ name: 'message_thread_id' }) messageThreadId!: number;
  @Expose({ name: 'message_thread_state' }) messageThreadState!: IThreadState;
  @Expose({ name: 'message_thread_has_messages' }) messageThreadHasMessages!: boolean;
  @Expose({ name: 'notes_message_thread_id' }) notesMessageThreadId!: number;
  @Expose({ name: 'notes_message_thread_unread_count' }) notesMessageThreadUnreadCount!: number;
  @Expose({ name: 'floor_id' }) floorId!: number;
  @Expose({ name: 'floor_type' }) floorType!: string;


  order?: number;
}

export class ActiveOfferGroup {
   @Expose({ name: 'id' }) id!: number;
   @Expose({ name: 'localized_status' }) localizedStatus!: string;
   @Expose({ name: 'price' }) price!: number;
   @Expose({ name: 'status' }) status!: EChangeRequestStatus;
}

export class ChangeRequestGroupModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'archived' }) archived!: boolean;
  @Expose({ name: 'unit_id' }) unitId!: number;
  @Expose({ name: 'identifier' }) identifier!: string | null;
  @Expose({ name: 'change_requests' })
  @Type(() => ChangeRequestGroupListModel)
  changeRequests!: ChangeRequestGroupListModel[];
  @Expose({ name: 'active_offer' })
  @Type(() => ActiveOfferGroup)
  activeOffer!: ActiveOfferGroup | null;
}

export interface IVersionList {
  version: number;
  date: string;
  creator: string;
  url?: string | null;
}

export class CombinedOffersListModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'title' }) title!: string;
  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'price' }) price!: number;
  @Expose({ name: 'description' }) description!: string | null;
  @Expose({ name: 'expiration_date' }) expirationDate!: string;
  @Expose({ name: 'localized_status' }) localizedStatus!: string;
  @Expose({ name: 'requests_group' }) requestsGroup!: {
    id: number;
    identifier: string;
  };
}

export class SubOffersAttributes {
  changeRequestId!: number;
  price: string | number | null = null;
  description!: string;
  versionNumber: number | null = null;
  fileResourcesAttributes: (FileModel | ImageModel | File)[] = [];
  declined = false;
  crDescription!: string;

  identifier!: string;
  open = false;
  versionList: IVersionList[] = [];

  constructor(request: GroupChangeRequestData) {
    this.changeRequestId = request.id;
    this.identifier = request.identifier;
    this.description = request.prefillOfferInfo?.description || '';
    this.crDescription = request.description || '';
    this.versionNumber = request.prefillOfferInfo?.versionNumber || null;
    this.price = request.prefillOfferInfo?.price || null;
    this.fileResourcesAttributes = request.prefillOfferInfo?.fileResources || [];

    const versions = request.floorDrawVersions.map((f) => f.versionNumber);
    if (!versions.includes(1)) {
      versions.push(1);
    }
    this.versionList = Array.from(new Set(versions))
      .sort()
      .map<IVersionList>((item) => {
        const obj: IVersionList = {
          version: item,
          creator: '',
          date: '',
        };
        const v = request.floorDrawVersions.filter((f) => f.versionNumber === item);
        if (v?.length) {
          obj.creator = v[0].creator.name;
          obj.date = v[0].createdAt;
          obj.url = v[0].filename?.url;
        }
        return obj;
      });
  }

  toggle(): void {
    this.open = !this.open;
  }

  get validator(): boolean {
    return !this.declined && typeof this.price === 'number' && !!this.versionNumber;
  }
}

export class CombinedOffer {
  status = 'draft';
  expirationDate: string | NgbDateStruct = '';
  offerDescription = '';
  fileResourcesAttributes: File[] = [];
  pricingComponents!: PricingComponents;
  subOffersAttributes: SubOffersAttributes[] = [];

  constructor(group: GroupChangeRequestData[]) {
    group?.forEach((item) => {
      this.subOffersAttributes.push(new SubOffersAttributes(item));
    });
    this.pricingComponents = new PricingComponents();
  }

  get offersValidator(): boolean {
    return this.subOffersAttributes.filter((item) => !item.declined).every((item) => item.validator);
  }

  get priceCostValidator(): boolean {
    return this.pricingComponents.calculationCostValidate && this.pricingComponents.administrationCostValidate;
  }

  get combineValidators(): boolean {
    return this.offersValidator && this.priceCostValidator;
  }

  get totalOffers(): number {
    return round(
      this.subOffersAttributes
        .filter((item) => !item.declined)
        .reduce((acc, item) => (acc += parseFloat(item.price as string) || 0), 0),
    );
  }

  get calcVat(): number {
    return round((this.totalOffers * this.pricingComponents.vat.value) / 100);
  }

  get calcMarkup(): number {
    return round((this.totalOffers * this.pricingComponents.markup.value) / 100);
  }

  get total(): number {
    return (
      this.totalOffers +
      (this.pricingComponents.vat.includeInTotal ? this.calcVat : 0) +
      (this.pricingComponents.markup.includeInTotal ? this.calcMarkup : 0) +
      (this.pricingComponents.calculationCost.includeInTotal ? this.pricingComponents.calculationCost.value || 0 : 0) +
      (this.pricingComponents.administrationCost.includeInTotal
        ? this.pricingComponents.administrationCost.value || 0
        : 0) +
      this.additionalCostsCalc
    );
  }

  get additionalCostsCalc(): number {
    return round(
      this.pricingComponents.additionalCosts.reduce((acc, item) => (acc += parseFloat(item.value as string) || 0), 0),
    );
  }
}

export class CreateCombinedOffer {
  requestsGroupId!: number;
  combinedOffer!: CombinedOffer;

  constructor(id: number, group: GroupChangeRequestData[]) {
    this.requestsGroupId = id;
    this.combinedOffer = new CombinedOffer(group);
  }
}

export class SubOfferChangeRequestModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'identifier' }) identifier!: string;
  @Expose({ name: 'description' }) description!: string | null;
  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'localized_status' }) localizedStatus!: string;
  @Expose({ name: 'floor_draw_versions' })
  @Type(() => FloorDrawVersionModel)
  floorDrawVersions!: FloorDrawVersionModel[];
}

export class SubOfferModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'price' }) price!: number;
  @Expose({ name: 'description' }) description!: string | null;
  @Expose({ name: 'file_resources' }) @Type(() => FileModel) fileResources!: (FileModel | ImageModel)[];
  @Expose({ name: 'version_number' }) versionNumber!: number;
  @Expose({ name: 'change_request' })
  @Type(() => SubOfferChangeRequestModel)
  changeRequest!: SubOfferChangeRequestModel;

  open = false;

  toggle(): void {
    this.open = !this.open;
  }
}

export class CreatedCombinedOfferModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'title' }) title!: string;
  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'price' }) price!: number;
  @Expose({ name: 'requests_group_id' }) groupId!: number;
  @Transform(({ value }) => (value ? dayjs(value).format('D.MM.YYYY') : ''))
  @Expose({ name: 'expiration_date' })
  expirationDate!: string;
  @Expose({ name: 'description' }) description!: string | null;
  @Expose({ name: 'decline_reason' }) declineReason!: string | null;
  @Expose({ name: 'pricing_components' }) @Type(() => PricingComponents) pricingComponents!: PricingComponents;
  @Expose({ name: 'localized_status' }) localizedStatus!: string;
  @Expose({ name: 'file_resources' }) @Type(() => FileModel) fileResources!: (FileModel | ImageModel)[];
  @Expose({ name: 'computed_pricing_components' }) computedPricingComponents!: { vat: number; markup: number };
  @Expose({ name: 'sub_offers_total_price' }) subOffersTotalPrice!: number;
  @Expose({ name: 'sub_offers' }) @Type(() => SubOfferModel) subOffers!: SubOfferModel[];
}

export class GroupChangeRequestData {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'identifier' }) identifier!: string;
  @Expose({ name: 'description' }) description!: string;
  @Expose({ name: 'floor_draw_versions' })
  @Type(() => FloorDrawVersionModel)
  floorDrawVersions!: FloorDrawVersionModel[];
  @Expose({ name: 'prefill_offer_info' })
  @Type(() => PrefillOfferInfo)
  prefillOfferInfo!: PrefillOfferInfo | null;
}

// Client models!
export class ChangeRequestGroupItemModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'floor_id' })
  floorPlanId!: number;
  @Expose({ name: 'floor_type' })
  floorType!: IFloorType;
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'point_x' })
  pointX!: number;
  @Expose({ name: 'point_y' })
  pointY!: number;
  @Expose({ name: 'message_thread_id' })
  messageThreadId?: number;
  @Expose({ name: 'message_thread_has_messages' })
  messageThreadHasMessages!: boolean;
  @Expose({ name: 'message_thread_state' })
  messageThreadState!: IThreadState;
  @Expose({ name: 'message_thread_unread_count' })
  messageThreadUnreadCount!: number;

  index?: number;
  groupId?: number;
}

export class ChangeRequestGroupOfferModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'price' })
  price!: number;
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
}

export class ChangeRequestGroupClientModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'unit_id' })
  unitId!: number;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'active_offer' })
  @Type(() => ChangeRequestGroupOfferModel)
  activeOffer!: ChangeRequestGroupOfferModel | null;
  @Expose({ name: 'change_requests' })
  @Type(() => ChangeRequestGroupItemModel)
  changeRequests!: ChangeRequestGroupItemModel[];
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
}

export class TempChangeRequestGroupModel {
  description!: string;
  attachments: (FileModel | ImageModel | PreloadedFile)[] = [];
  drawingData: IFloorDrawToSave[] = [];
  floorPlanId!: number;
  floorType!: IFloorType;
  pointX!: number;
  pointY!: number;
  index!: number;
  flattenIndex!: number;
  id: number | '' = '';
  groupId: number | null = null;
  identifier: string | null = null;
  isAttachmentsPositionsChanged = false;
  removedAttachments: (FileModel | ImageModel)[] = [];
}

export class ClientSubOfferChangeRequestModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'description' })
  description: string | null = null;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'status' })
  status: EChangeRequestStatus = EChangeRequestStatus.Offered;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
}

export class ClientSubOfferModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'price' })
  price!: number;
  @Expose({ name: 'description' })
  description: string | null = null;
  @Expose({ name: 'version_number' })
  versionNumber!: number;
  // todo: to be announced
  // @Expose({ name: 'floor_id' })
  // floorId!: number;
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources!: FileModel[];
  @Expose({ name: 'change_request' })
  @Type(() => ClientSubOfferChangeRequestModel)
  changeRequest!: ClientSubOfferChangeRequestModel;
}

export class ClientCombinedOfferModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'title' })
  title!: string;
  @Expose({ name: 'status' })
  status: EChangeRequestStatus = EChangeRequestStatus.Offered;
  @Expose({ name: 'price' })
  price!: number;
  @Expose({ name: 'sub_offers_total_price' })
  subOffersTotalPrice!: number;
  @Expose({ name: 'expiration_date' })
  expirationDate!: number;
  @Expose({ name: 'description' })
  description: string | null = null;
  @Expose({ name: 'pricing_components' })
  @Type(() => PricingComponents)
  pricingComponents!: PricingComponents;
  @Expose({ name: 'computed_pricing_components' })
  computedPricingComponents!: { markup: number; vat: number };
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources!: FileModel[];
  @Expose({ name: 'sub_offers' })
  @Type(() => ClientSubOfferModel)
  subOffers!: ClientSubOfferModel[];
  @Expose({ name: 'decline_reason' })
  declineReason!: string | null;
}

export enum EClientChangeRequestGroupMobileView {
  Table,
  FloorPlan,
}
