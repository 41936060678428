import { Expose, Transform, Type } from 'class-transformer';
import {ImageModel} from "./image.model";

export const messagesFileExtensions = [
  'pptx',
  'ppt',
  'doc',
  'docx',
  'pdf',
  'xls',
  'xlsx',
  'png',
  'avi',
  'jpg',
  'jpeg',
  'txt',
  'xlsm',
  'pages',
  'ai',
  'psd',
  'tiff',
  'dxf',
  'svg',
  'zip',
  'rar',
  'mp4',
  'mov',
  'm4v',
];

export const fileExtentions = ['pptx', 'ppt', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'png', 'avi', 'mp4', 'mov', 'm4v'];

export enum EDocumentType {
  Folder = 'folder',
  File = 'file',
}

export enum EFileType {
  File = 'file',
  Image = 'image',
  Video = 'video',
  Unknown = 'unknown',
}

export interface IFile {
  id: string;
  name: string;
  updated_at: string;
  created_at: string;
  file_extension: string;
  file_size: string;
  filename: IFileName;
}

export interface IFileName {
  url: string;
  download_url: string;
}

export interface IVisibleTo {
  id: number;
  title: string;
}

export class FileNameModel {
  @Expose({ name: 'url' })
  url: string;
  @Expose({ name: 'download_url' })
  downloadUrl: string;
  w260?: FileNameModel;

  constructor(src?: string) {
    this.url = src || '';
    this.downloadUrl = '';
  }
}

export class FileModel {
  @Expose({ name: 'id' })
  id: string;
  @Expose({ name: 'name' })
  name: string;
  @Expose({ name: 'title' })
  title: string;
  @Expose({ name: 'visible_to' })
  visibleTo!: IVisibleTo[];
  @Expose({ name: 'updated_at' })
  updatedAt: string;
  @Expose({ name: 'created_at' })
  createdAt: string;
  @Expose({ name: 'file_extension' })
  fileExtension: string;
  @Expose({ name: 'file_size' })
  fileSize: number;
  @Expose({ name: 'upload_date' })
  uploadDate?: string;
  @Expose({ name: 'filename' })
  @Type(() => FileNameModel)
  fileName!: FileNameModel;
  @Expose({ name: 'full_image' })
  @Type(() => FileNameModel)
  fullImage?: FileNameModel;

  position?: number;

  @Expose({ name: 'type' })
  @Type(() => String)
  @Transform(() => 'file', { toClassOnly: true })
  type = 'file';

  @Expose()
  @Type(() => FileNameModel)
  preview?: FileNameModel;

  @Expose()
  @Type(() => FileNameModel)
  w2532?: FileNameModel;

  constructor() {
    this.createdAt = '';
    this.id = '';
    this.name = '';
    this.title = '';
    this.updatedAt = '';
    this.fileExtension = '';
    this.fileSize = 0;
    this.uploadDate = '';
  }
}

export class PositionedFile extends File {
  position?: number;
}

export class PreloadedFile {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'format' }) format!: string;
  @Expose({ name: 'expired_at' }) expiredAt!: string;
  @Expose({ name: 'link' }) link!: string;
  @Expose({ name: 'url' }) url!: string;
  @Expose({ name: 'size' }) size!: number;
  @Expose({ name: 'title' }) title!: string;
  @Expose({ name: 'position' }) position!: number;

  originalFile?: File;
}

class UrlDownloadModel {
  @Expose({ name: 'download_url' })
  downloadUrl!: string;
  @Expose({ name: 'url' })
  url!: string;
}

export class FileDownloadModel {
  @Expose({ name: 'document' })
  @Type(() => UrlDownloadModel)
  document!: UrlDownloadModel;
  @Expose({ name: 'file_extension' })
  fileExtension!: string;
  @Expose({ name: 'file_size' })
  fileSize!: number;
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'last_data_update_border' })
  lastDataUpdateBorder!: string;
  @Expose({ name: 'unit_id' })
  unitId!: number;
}

export interface IWrapFile {
  filename: FileModel | ImageModel;
  position: number;
}
