import { Pipe, PipeTransform } from '@angular/core';
import { CustomDecimalPipe } from '@atl-libs-shared-pipes/custom-decimal-pipe/custom-decimal.pipe';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'priceLabel',
  standalone: true
})
export class PriceLabelPipe implements PipeTransform {
  constructor(
    private readonly translate: TranslateService,
    private readonly customDecimal: CustomDecimalPipe
  ) {}

  transform(fullPrice: number, fixed = 2, nonZeroFixed = false): string {
    if (fullPrice !== 0) {
      const formattedPrice = this.customDecimal.transform(fullPrice, fixed, nonZeroFixed);
      return `${this.translate.instant('Shared.Entity.Option_v2')} · ${formattedPrice},-`;
    }

    return `${this.translate.instant('Title.Cost_free_option_v2')} · 0,-`;
  }
}
