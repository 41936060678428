import { HttpClient, HttpParams } from '@angular/common/http';
import { ETablePagination } from '@atlas-workspace/shared/models';
import { forkJoin, Observable, of, shareReplay, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

export function getChunksConcurrently<T>(
  http: HttpClient,
  url: string,
  initialParams = new HttpParams(),
  plainToClassCb?: (body: any) => T[],
  perPage = 50,
): Observable<T[]> {
  const firstParams = initialParams.set('per_page', perPage).set('page', 1);

  return http.get<T[]>(url, { observe: 'response', params: firstParams }).pipe(
    switchMap((response) => {
      const totalPages = Number(response.headers.get(ETablePagination.TotalPages)) || 1;
      const toDataFn = (body: T[]): T[] => (plainToClassCb ? plainToClassCb(body) : body);
      const chunk1st = toDataFn(response.body || []);

      if (totalPages <= 1) {
        return of(chunk1st);
      }

      const requests: Observable<T[]>[] = [];
      for (let page = 2; page <= totalPages; page++) {
        const params = initialParams.set('per_page', perPage).set('page', page);
        requests.push(http.get<T[]>(url, { params }));
      }

      return forkJoin(requests).pipe(
        map((bodies) => {
          const formattedChunks = bodies.map((body) => toDataFn(body || []));
          return chunk1st.concat(...formattedChunks.flat());
        }),
      );
    }),
    shareReplay(1),
  );
}
