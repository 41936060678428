/* eslint-disable */
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';


@Injectable({
  providedIn: 'root',
})
export class PicarioService {
  private preloadedImages: Map<string, Promise<HTMLImageElement>> = new Map();
  private hiddenCanvas: HTMLCanvasElement;

  private fluentXpoUrlFactory: any;
  private w = window as any;

  private readonly defaultWidth = 3508;
  private readonly defaultHeight = 2480;
  constructor(@Inject('ENVIRONMENT') private environment: IEnvironment) {
    this.initializeSdk();
    this.hiddenCanvas = document.createElement('canvas');
  }
  private readonly sceneUrl = this.environment.picarioSceneUrl;
  // ToDO: add typescrip typing from SDK instead of window usage.
  private initializeSdk(): void {
    if (typeof this.w.FluentXpoUrlFactory !== 'undefined') {
      this.fluentXpoUrlFactory = new this.w.FluentXpoUrlFactory();
    } else {
      console.error('Picario SDK not loaded');
    }
  }

  public generateImageUrl(options: {
    scene: string;
    colors?: { id: string, colorNr: number }[];
    width?: number;
    height?: number;
    format?: string;
    designArr?: any[];
    colorNr?: number
  }): string | null {
    if (!this.fluentXpoUrlFactory) {
      console.error('Picario SDK not initialized');
      return null;
    }

    const generator = new this.w.XpoUrlGenerator();
    const fluentUrlGenerator = this.fluentXpoUrlFactory.createFluentUrlGenerator(
      generator,
      this.w.UrlGeneratorModule.FluentXpoUrlType.Image
    ).setAbsoluteUrl(this.sceneUrl);

    fluentUrlGenerator
      .setPrimaryKey(options.scene)
      .setImageType(this.w.UrlGeneratorModule.XpoUrlImageTypes[options.format || 'Jpg'])
      .setEntityType(this.w.UrlGeneratorModule.XpoUrlFileTypes.Scene)
      .setWidth(this.defaultWidth)
      .setHeight(this.defaultHeight)
    if (options.colors?.length) {
      options.colors.forEach((color) => {
        fluentUrlGenerator.addObject((colorObg: any) => {
          colorObg.color(color.id);
          colorObg.setIndex(color.colorNr);
        });
      });
    }

    if (options.designArr) {
      options.designArr.forEach((design) => {
        if (!design.related_scene_id) {
          return;
        }
        fluentUrlGenerator.addObject((designObj: any) => {
          designObj
            .design(design.referenceId)
            .setWidth(design.designOptions.width)
            .setHeight(design.designOptions.height)
            .setRepeat(design.designOptions.repeat)
            .setPlacingPointX(design.designOptions.placingPointX)
            .setPlacingPointY(design.designOptions.placingPointY);
          designObj.setIndex(design.nr);
        });
      });
    }

    const url = fluentUrlGenerator.getUrl();
    return url;
  }

  
  public generateImageOverlayUrl(
    options: {
      width?: number;
      height?: number;
      format?: string;
      overlay?: string;
      designArr?: any[]
    }, subsceneId?: number,
    color?: { id: string, nr: number }
  ): string | null {
    if (!this.fluentXpoUrlFactory) {
      console.error('Picario SDK not initialized');
      return null;
    }
    const generator = new this.w.XpoUrlGenerator();
    const fluentUrlGenerator = this.fluentXpoUrlFactory.createFluentUrlGenerator(
      generator,
      this.w.UrlGeneratorModule.FluentXpoUrlType.Image
    ).setAbsoluteUrl(this.sceneUrl);

    if (options.designArr) {
      options.designArr.forEach((design) => {
        if (!design.id || design.related_scene_id !== subsceneId) {
          return;
        }
        fluentUrlGenerator.addObject((designObj: any) => {
          designObj
            .design(design.referenceId)
            .setWidth(design.designOptions.width)
            .setHeight(design.designOptions.height)
            .setRepeat(design.designOptions.repeat)
            .setPlacingPointX(design.designOptions.placingPointX)
            .setPlacingPointY(design.designOptions.placingPointY);
        });
      });
    }

    if (color) {
      fluentUrlGenerator.addObject((colorObg: any) => {
        colorObg.color(color.id);
        colorObg.setIndex(color.nr);
      });
    }
    const url = fluentUrlGenerator
      .setPrimaryKey(options.overlay)
      .setImageType(options.format)
      .setEntityType(this.w.UrlGeneratorModule.XpoUrlFileTypes.Scene)
      .setWidth(options.width || 1024)
      .getUrl();

    return url;
  }

 /**
   * Pre-cache images by preloading them in memory and forcing GPU caching.
   * @param urls Array of image URLs to preload
   */
 public preloadImages(urls: string[]): void {
  urls.forEach((url) => {
    if (!this.preloadedImages.has(url)) {
      const preloadPromise = this.loadImage(url).then((img) => {
        this.cacheInGPU(img);
        return img;
      });
      this.preloadedImages.set(url, preloadPromise);
    }
  });
}

/**
 * Loads an image and ensures it's fully decoded before using it.
 * @param url Image URL
 */
private loadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.crossOrigin = 'anonymous';

    img.onload = async () => {
      try {
        await img.decode();
        resolve(img);
      } catch (err) {
        console.warn(`Image decoding failed for: ${url}`);
        resolve(img);
      }
    };

    img.onerror = () => {
      console.error(`Failed to load image: ${url}`);
      reject();
    };
  });
}

/**
 * Cache the image in GPU memory using a hidden canvas.
 * This ensures the image does not blink when first displayed.
 */
private cacheInGPU(image: HTMLImageElement): void {
  const ctx = this.hiddenCanvas.getContext('2d');
  if (!ctx) return;

  this.hiddenCanvas.width = image.width;
  this.hiddenCanvas.height = image.height;
  ctx.drawImage(image, 0, 0);
}

/**
 * Returns a preloaded image if available, or loads it if not.
 * Ensures smooth transitions without blinking.
 */
public async getPreloadedImage(url: string, currentSrc: string): Promise<string> {
  if (this.preloadedImages.has(url)) {
    return this.preloadedImages.get(url)!
      .then((img) => img.src);
  }

  return this.loadImage(url).then((img) => {
    this.cacheInGPU(img);
    this.preloadedImages.set(url, Promise.resolve(img));
    return img.src;
  }).catch(() => currentSrc);
}



}