import { Directive } from '@angular/core';

@Directive({
  selector: '[atlVirtualScrollTableTbodyRow]',
})
export class SharedVirtualScrollTableTbodyRowDirective {}

@Directive({
  selector: '[atlVirtualScrollTableTheadRow]',
})
export class SharedVirtualScrollTableTheadRowDirective {}
