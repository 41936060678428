import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  AdminUnitModel,
  ChangeRequestsUnitModel,
  DualViewURLModel,
  ELang,
  ETablePagination,
  EUnitStatus,
  FileDownloadModel,
  FloorModel,
  IGetUnitsLiteProps,
  INewestRoom,
  IRoomType,
  IStoreModalGeneral,
  ITablePagination,
  IUnit,
  IUnitMainBuyer,
  IUnitStatusUpdate,
  IUnitType,
  IUnitTypeUnits,
  IUnitUser,
  OptionUnitModel,
  OptionWishlistModel,
  ReclamationUnitModel,
  RoomModel,
  ThreadUnitsWithGroupsModel,
  TUnitCreationBody,
  UnitEventLogModel,
  UnitModel,
  UnitPhaseDefails,
  UnitTypeDetailsModel,
  UnitTypeModel,
  UnitTypeTemplateListModel,
  UnitTypeTemplateModel,
  UnitTypeUnitsModel,
  UnitUserModel,
} from '@atlas-workspace/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { PaginationUtil } from '../../helpers/pagination.util';
import { getChunksConcurrently } from '../../helpers/request-chunks.util';
import { AuthAdminService } from '../auth/auth-admin.service';
import { DataTableHelperService } from '../data-table-helper/data-table-helper.service';

@Injectable({
  providedIn: 'root',
})
export class UnitsService {
  unit$ = new ReplaySubject<UnitModel>(1);
  unitTypesPagination$ = new BehaviorSubject<ITablePagination | null>(null);
  unitsLogsPagination$ = new BehaviorSubject<ITablePagination | null>(null);
  private unitsPagination$ = new BehaviorSubject<ITablePagination | null>(null);
  private unitType$ = new BehaviorSubject<UnitTypeDetailsModel | undefined>(undefined);
  private _updateCategoriesAfterDetach$ = new Subject<number>();

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private http: HttpClient,
    private authAdminService: AuthAdminService,
    private tableService: DataTableHelperService,
    private readonly translateService: TranslateService,
  ) {}

  updateCategoriesEvent(unitTypeId: number): void {
    this._updateCategoriesAfterDetach$.next(unitTypeId);
  }

  get updateCategoriesAfterDetach$(): Observable<number> {
    return this._updateCategoriesAfterDetach$.asObservable();
  }

  public detachLaoutType(projectId: number, unitId: number, typeId: number): Observable<UnitModel> {
    const params: HttpParams = new HttpParams().set('layout_type_id', String(typeId));
    return this.http
      .post(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}/detach`, null, { params })
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(UnitModel, data)),
      );
  }

  public getUnits(
    projectId: string,
    sort = 'default',
    search = '',
    paginate?: ITablePagination,
  ): Observable<UnitModel[]> {
    const params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units`, {
        params: params,
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(res.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(res.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalPages),
          };
          this.unitsPagination$.next(pagination);
        }),
        map((res) => res.body.data.units),
        map((data: IUnit[]) => plainToClass(UnitModel, data)),
      );
  }

  /**
   * Get Units for Project
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Units-Users/paths/~1api~1ext~1v1~1projects~1%7Bproject_id%7D~1units_users/get
   */
  public getAllUnitsForProject(projectId: number): Observable<UnitUserModel[]> {
    const url = `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units_users`;
    return getChunksConcurrently<UnitUserModel>(this.http, url, undefined, (body) => {
      return plainToClass(UnitUserModel, <UnitUserModel[]>body.data.units);
    });
  }

  /**
   * Get Units
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Units-Users/paths/~1api~1ext~1v1~1projects~1%7Bproject_id%7D~1units_users/get
   */
  public getUnitsLite({
    projectId,
    sort,
    search,
    paginate,
    updateId,
    practicalId,
    filter,
    layoutTypeIds,
  }: IGetUnitsLiteProps): Observable<UnitUserModel[]> {
    let params: HttpParams = this.tableService.paramsHandler(search || '', sort || 'default', paginate);
    if (updateId) params = params.set('update_id', updateId.toString());
    if (practicalId) params = params.set('practical_info_id', practicalId.toString());
    if (filter?.length) filter.forEach((v) => (params = params.append('sale_statuses[]', v)));
    if (layoutTypeIds?.length)
      layoutTypeIds.forEach((v) => (params = params.append('layout_type_ids[]', v.toString())));
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units_users`, {
        params: params,
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(res.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(res.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalPages),
          };
          this.unitsPagination$.next(pagination);
        }),
        map((res: any) => res.body.data.units),
        map((data: IUnitUser[]) => {
          return plainToClass(UnitUserModel, data);
        }),
      );
  }

  public getUnitsLiteByChunksConcurrently(
    projectId: number,
    search?: string,
    sort?: string,
  ): Observable<UnitUserModel[]> {
    const url = `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units_users`;
    const params: HttpParams = this.tableService.paramsHandler(search || '', sort || 'default');
    return getChunksConcurrently<UnitUserModel>(
      this.http,
      url,
      params,
      (body) => {
        return plainToClass(UnitUserModel, <UnitUserModel[]>body.data.units);
      },
      100,
    );
  }

  public getUnitsUserState(
    projectId: string,
    sort = 'default',
    search = '',
    paginate?: ITablePagination,
  ): Observable<any> {
    const params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    return this.http.get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units_users`, {
      params: params,
      observe: 'response',
    });
  }

  get pagination$(): Observable<ITablePagination | null> {
    return this.unitsPagination$.asObservable();
  }

  get logsPagination$(): Observable<ITablePagination | null> {
    return this.unitsLogsPagination$.asObservable();
  }

  /**
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Units/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1units~1%7Bid%7D~1layout_option_phases/get
   */
  public getUnitPhases(projectId: number, unitId: number): Observable<UnitPhaseDefails> {
    return this.http
      .get(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}/layout_option_phases`)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(UnitPhaseDefails, data)),
      );
  }

  /**
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Units/paths/~1api~1v1~1projects~1{project_id}~1units/post
   */
  public createUnit(projectId: string, unitInfos: TUnitCreationBody): Observable<UnitModel> {
    const fd = this.buildUnitFormData(unitInfos);
    return this.http.post<{ data: IUnit }>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units`, fd).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(UnitModel, data)),
    );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private buildUnitFormData(unitInfos: TUnitCreationBody): FormData {
    const fd = new FormData();

    fd.append('unit[identifier]', unitInfos.identifier);
    fd.append('unit[property_type]', unitInfos.propertyType);
    fd.append('unit[sale_status]', unitInfos.status);
    fd.append('unit[budgeted_price]', (unitInfos.budgetPrice ?? 0).toString());

    if (unitInfos.bra) {
      fd.append('unit[bra]', String(unitInfos.bra));
    }
    if (unitInfos.roomsCount) {
      fd.append('unit[rooms_count]', String(unitInfos.roomsCount));
    }
    if (unitInfos.parkingPlaceNumber) {
      fd.append('unit[parking_place_number]', String(unitInfos.parkingPlaceNumber));
    }
    if (unitInfos.storageUnitNumber) {
      fd.append('unit[storage_unit_number]', String(unitInfos.storageUnitNumber));
    }
    if (unitInfos.storm) {
      fd.append('unit[storm]', String(unitInfos.storm));
    }
    if (unitInfos.status !== EUnitStatus.Unsold) {
      fd.append('unit[actual_price]', unitInfos.actualPrice ? (unitInfos.actualPrice ?? 0).toString() : '');
    }

    if ((unitInfos.companyName ?? []).length) {
      const [company] = unitInfos.companyName;
      fd.append('unit[company_id]', company.id.toString());
    }

    if ((unitInfos.documents ?? unitInfos.documents_electro ?? []).length) {
      fd.append('unit[has_own_floor_plans]', 'true');
    }

    (unitInfos.unitType ?? []).forEach((item) => {
      fd.append('unit[layout_type_ids][]', item.id.toString());
    });

    (unitInfos ?? []).documents.forEach((item: { name: string; file: File[] }, index) => {
      const [file] = item.file ?? [];
      const [elFile] = (unitInfos.documents_electro ?? [])[index]?.file ?? [];
      fd.append(`unit[unit_floors_attributes][][floor_number]`, (index + 1).toString());
      fd.append(`unit[unit_floors_attributes][][plan]`, file ?? null);
      fd.append(`unit[unit_floors_attributes][][electro_plan]`, elFile ?? null);
      if (item?.name) {
        fd.append(`unit[unit_floors_attributes][][name]`, item.name);
      }
    });

    (unitInfos?.unitAttributes ?? []).forEach((item, index) => {
      fd.append(`unit[custom_fields_attributes][${index}][field_type]`, item.type);
      fd.append(`unit[custom_fields_attributes][${index}][value]`, `${item.value}`);
      fd.append(`unit[custom_fields_attributes][${index}][name]`, item.name);
      fd.append(`unit[custom_fields_attributes][${index}][for_all_project_units]`, `${item.forAll}`);
    });

    return fd;
  }

  public deleteUnits(projectId: string, ids: number[]): Observable<string> {
    let params: HttpParams = new HttpParams();
    if (this.authAdminService.firm?.firmId)
      params = params.set('firm_id', this.authAdminService.firm?.firmId.toString());
    const httpOptions = {
      params: params,
      body: { ids: ids },
    };
    return this.http.delete(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units`, httpOptions).pipe(
      map((res: any) => res?.message),
      map((data) => <string>data),
    );
  }

  /**
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Units/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1units~1%7Bid%7D/put
   */
  public updateUnit(projectId: string, unitId: string, unit: Partial<IUnitStatusUpdate>): Observable<UnitModel> {
    return this.http
      .put<{ data: IUnit }>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}`, unit)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(UnitModel, data)),
        tap((x: UnitModel) => (this.activeUnit = x)),
      );
  }

  /**
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Units/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1units~1%7Bid%7D/get
   */
  public getUnit(projectId: string, unitId: string): Observable<UnitModel> {
    return this.http
      .get<{ data: IUnit }>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}`)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(UnitModel, data)),
        tap((unit: UnitModel) => (this.activeUnit = unit)),
      );
  }

  public set activeUnit(unit: UnitModel) {
    this.unit$.next(unit);
  }

  public clearActiveUnit(): void {
    this.unit$ = new ReplaySubject<UnitModel>(1);
  }

  public getUnitsTypes(
    projectId: number,
    sort = '',
    search = '',
    paginate?: ITablePagination,
    onlyWithLayoutOption = false,
    onlyWithoutLayoutOption = false,
    onlyWithShowingApartment = false,
    onlyWithoutShowingApartment = false,
  ): Observable<UnitTypeModel[]> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (onlyWithLayoutOption) {
      params = params.append('only_with_layout_option', String(onlyWithLayoutOption));
    }
    if (onlyWithoutLayoutOption) {
      params = params.append('only_without_layout_option', String(onlyWithoutLayoutOption));
    }
    if (onlyWithShowingApartment) {
      params = params.append('only_with_showing_apartment', String(onlyWithShowingApartment));
    }
    if (onlyWithoutShowingApartment) {
      params = params.append('only_without_showing_apartment', String(onlyWithoutShowingApartment));
    }
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types`, {
        params: params,
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(res.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(res.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalPages),
          };
          this.unitTypesPagination$.next(pagination);
        }),
        map((res: any) => res.body.data.layout_types),
        map((data: IUnitType[]) => plainToClass(UnitTypeModel, data)),
      );
  }

  public getUnitsGroupsByChunksConcurrently(
    projectId: number,
    sort = '',
    search = '',
    onlyWithLayoutOption = false,
    onlyWithoutLayoutOption = false,
    onlyWithShowingApartment = false,
    onlyWithoutShowingApartment = false,
  ): Observable<UnitTypeModel[]> {
    const url = `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        search: String(search),
        sort_by: String(sort),
        ...(onlyWithLayoutOption && { only_with_layout_option: String(true) }),
        ...(onlyWithoutLayoutOption && { only_without_layout_option: String(true) }),
        ...(onlyWithShowingApartment && { only_with_showing_apartment: String(true) }),
        ...(onlyWithoutShowingApartment && { only_without_showing_apartment: String(true) }),
      },
    });
    return getChunksConcurrently<UnitTypeModel>(this.http, url, params, (body) => {
      return plainToClass(UnitTypeModel, <UnitTypeModel[]>body.data.layout_types);
    });
  }

  public deleteUnitTypes(projectId: number, ids: number[]): Observable<string> {
    let params: HttpParams = new HttpParams();
    if (this.authAdminService.firm?.firmId)
      params = params.set('firm_id', this.authAdminService.firm?.firmId.toString());
    const httpOptions = {
      params,
      body: { ids: ids },
    };
    return this.http
      .delete(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types/batch_destroy`, httpOptions)
      .pipe(
        map((res: any) => res?.message),
        map((data) => <string>data),
      );
  }

  public getConnectedUnits(
    projectId: string,
    typeId: string,
    search?: string,
    sortQuery?: string,
  ): Observable<IUnitTypeUnits[]> {
    let params: HttpParams = new HttpParams();
    if (search) params = params.set('search', search);
    if (sortQuery) params = params.set('sort_by', sortQuery);
    return this.http
      .get(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types/${typeId}/connected_units`, {
        params,
      })
      .pipe(
        map((res: any) => res.data.units),
        map((data) => <IUnitTypeUnits[]>data),
      );
  }

  public getUnitTypeUnits(
    projectId: string,
    search?: string,
    sortQuery?: string,
    layoutTypeId?: number,
  ): Observable<UnitTypeUnitsModel[]> {
    let params: HttpParams = new HttpParams();
    if (search) params = params.set('search', search);
    if (sortQuery) params = params.set('sort_by', sortQuery);
    if (layoutTypeId) params = params.set('layout_type_id', layoutTypeId.toString());
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/unit_type_units/`, { params })
      .pipe(
        map((res: any) => res.data.units),
        map((data: IUnitTypeUnits[]) => plainToClass(UnitTypeUnitsModel, data)),
      );
  }

  public createUnitType(projectId: string, data: FormData): Observable<UnitTypeDetailsModel> {
    return this.http.post(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types/`, data).pipe(
      map((res: any) => res.data),
      map((result) => plainToClass(UnitTypeDetailsModel, result)),
    );
  }

  public getRoomTypesByFirm(): Observable<IRoomType[]> {
    return this.http
      .get(`${this.environment.apiBaseUrl}api/v1/room_types`)
      .pipe(map((res: any) => <IRoomType[]>res.data.room_types));
  }

  public createRoomType(type: string): Observable<IRoomType> {
    return this.http
      .post(`${this.environment.apiBaseUrl}api/v1/room_types`, { room_type: { title: type } })
      .pipe(map((res: any) => <IRoomType>res.data));
  }

  public deleteRoom(roomId: number): Observable<string> {
    return this.http
      .delete(`${this.environment.apiBaseUrl}api/v1/rooms/${roomId}`)
      .pipe(map((res: any) => <string>res?.message));
  }

  public get currentUnitType$(): Observable<UnitTypeDetailsModel | undefined> {
    return this.unitType$.asObservable();
  }

  public getUnitType(projectId: number, unitTypeId: number): Observable<UnitTypeDetailsModel> {
    return this.http.get(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types/${unitTypeId}`).pipe(
      map((res: any) => res.data),
      tap((type) => {
        this.unitType$.next(type as UnitTypeDetailsModel);
      }),
      map((data) => plainToClass(UnitTypeDetailsModel, data)),
    );
  }

  public deleteUnitType(projectId: number, unitTypeId: number): Observable<string> {
    return this.http
      .delete(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types/${unitTypeId}`)
      .pipe(
        map((res: any) => res?.message),
        map((data) => <string>data),
      );
  }

  public deleteLayoutOption(projectId: number, unitTypeId: number): Observable<string> {
    return this.http
      .delete(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types/${unitTypeId}/layout_option`)
      .pipe(
        map((res: any) => res?.message),
        map((data) => <string>data),
      );
  }

  public updateUnitType(projectId: number, unitTypeId: number, data: FormData): Observable<UnitTypeDetailsModel> {
    return this.http
      .put(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types/${unitTypeId}`, data)
      .pipe(
        map((res: any) => res.data),
        map((x) => plainToClass(UnitTypeDetailsModel, x)),
      );
  }

  public updateUnitFloorPlan(floorId: number, data: FormData, unitId: number | null): Observable<FloorModel> {
    let params = {};
    if (unitId) {
      params = Object.assign(
        {},
        {
          unit_id: unitId.toString(),
        },
      );
    }

    return this.http.put(`${this.environment.apiBaseUrl}api/v1/unit_floors/${floorId}`, data, { params }).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(FloorModel, x)),
    );
  }

  public updateUnitFloorName(
    floorId: number,
    data: FormData,
    unitId: number | null,
    hasOwnFloorPlans: boolean | undefined,
  ): Observable<FloorModel> {
    let params = {};
    if (unitId && !hasOwnFloorPlans) {
      params = Object.assign(
        {},
        {
          unit_id: unitId.toString(),
        },
      );
    }
    return this.http.put(`${this.environment.apiBaseUrl}api/v1/unit_floors/${floorId}`, data, { params }).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(FloorModel, x)),
    );
  }

  public createdUnitFloorPlan(projectId: string, unitId: string, data: FormData): Observable<UnitModel> {
    return this.http.put(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}`, data).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(UnitModel, x)),
    );
  }

  public updateFloorPlan(floorId: number, data: FormData): Observable<FloorModel> {
    return this.http.put(`${this.environment.apiBaseUrl}api/v1/floors/${floorId}`, data).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(FloorModel, x)),
    );
  }

  public removeFloor(floorId: number): Observable<string> {
    return this.http.delete(`${this.environment.apiBaseUrl}api/v1/floors/${floorId}`).pipe(
      map((res: any) => res?.message),
      map((data) => <string>data),
    );
  }

  public createRoom(room: FormData): Observable<RoomModel> {
    return this.http.post(`${this.environment.apiBaseUrl}api/v1/rooms/`, room).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(RoomModel, x)),
    );
  }

  public updateRoom(room: FormData, roomId: number): Observable<RoomModel> {
    return this.http.put(`${this.environment.apiBaseUrl}api/v1/rooms/${roomId}`, room).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(RoomModel, x)),
    );
  }

  public removeRoom(roomId: number): Observable<unknown> {
    return this.http.delete(this.environment.apiBaseUrl + 'api/v1/rooms/' + roomId);
  }

  public getRoom(roomId: number): Observable<RoomModel> {
    return this.http.get(`${this.environment.apiBaseUrl}api/v1/rooms/${roomId}`).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(RoomModel, x)),
    );
  }

  public generateRoomFormData(room: INewestRoom, floorId: number | null, addFloorId = true): FormData {
    const fd = new FormData();
    fd.append('room[name]', room.name);
    fd.append('room[size]', (room.size ?? '').toString());
    fd.append('room[room_type_id]', (room.type ?? '').toString());
    if (addFloorId && floorId) {
      fd.append('room[floor_id]', floorId.toString());
    }
    return fd;
  }

  public putUpdateUnitType(
    projectId: number,
    layoutTypeId: number,
    unitType: IStoreModalGeneral,
    saveAsTemplate = false,
  ): Observable<UnitTypeDetailsModel> {
    const formData = new FormData();
    formData.append('layout_type[name]', unitType.name);
    formData.append('layout_type[floors_amount]', unitType.numOfFloors);
    formData.append('layout_type[show_rooms]', `${unitType.showRooms}`);
    if (saveAsTemplate) {
      formData.append('layout_type[save_as_template]', `true`);
    }

    return this.http
      .put(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/layout_types/${layoutTypeId}`, formData)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(UnitTypeDetailsModel, data)),
      );
  }

  public putUpdateUnitTypeFloors(
    projectId: number,
    layoutTypeId: number,
    numOfFloors: string,
  ): Observable<UnitTypeDetailsModel> {
    const formData = new FormData();
    formData.append('layout_type[floors_amount]', numOfFloors);
    return this.http
      .put(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/layout_types/${layoutTypeId}`, formData)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(UnitTypeDetailsModel, data)),
      );
  }

  /**
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Layout-Types/paths/~1api~1v1~1projects~1{project_id}~1layout_types~1{id}/delete
   */
  public deleteLayoutTypeFloor(
    projectId: number,
    layoutTypeId: number,
    floorId: number,
  ): Observable<UnitTypeDetailsModel> {
    const fd = new FormData();
    fd.append(`layout_type[floors_attributes][][id]`, floorId.toString());
    fd.append(`layout_type[floors_attributes][][_destroy]`, 'true');
    return this.http
      .put(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/layout_types/${layoutTypeId}`, fd)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(UnitTypeDetailsModel, data)),
      );
  }

  /**
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Units/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1units~1%7Bid%7D/put
   */
  public addLayoutTypeFloor(projectId: number, layoutTypeId: number, fd: FormData): Observable<UnitTypeDetailsModel> {
    return this.http
      .put(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/layout_types/${layoutTypeId}`, fd)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(UnitTypeDetailsModel, data)),
      );
  }

  getUnitTypeTemplates(search: string, sort = 'default'): Observable<UnitTypeTemplateListModel[]> {
    const params: HttpParams = this.tableService.paramsHandler(search, sort);
    const firmId = this.authAdminService.firm?.firmId.toString();
    return this.http
      .get<any>(this.environment.apiBaseUrl + `api/v1/firms/${firmId}/layout_type_templates`, {
        params,
      })
      .pipe(
        map((res: any) => res.data.layout_type_templates),
        map((data: UnitTypeTemplateListModel[]) => plainToClass(UnitTypeTemplateListModel, data)),
      );
  }

  getUnitTypeTemplate(id: number): Observable<UnitTypeTemplateModel> {
    const firmId = this.authAdminService.firm?.firmId.toString();
    return this.http.get(this.environment.apiBaseUrl + `api/v1/firms/${firmId}/layout_type_templates/${id}`).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(UnitTypeTemplateModel, data)),
    );
  }

  createUnitTypeTemplate(projectId: number, name: string, id: number): Observable<UnitTypeDetailsModel> {
    const layout_type = {
      name: name,
      layout_type_template_id: id,
    };
    return this.http
      .post(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/layout_types/create_from_template`, {
        layout_type,
      })
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(UnitTypeDetailsModel, data)),
      );
  }

  removeUnitFloor(floorId: number, unitId: number | null): Observable<unknown> {
    let params = {};
    if (unitId) {
      params = Object.assign({}, { unit_id: unitId.toString() });
    }
    return this.http.delete(this.environment.apiBaseUrl + 'api/v1/unit_floors/' + floorId, {
      params,
    });
  }

  public getUnitEventLogs(
    unitId: number,
    sort = 'default',
    search = '',
    paginate?: ITablePagination,
    filters?: { type: string[]; date?: { from: string; to: string | null } },
  ): Observable<UnitEventLogModel[]> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);

    if (filters && filters.type.length) filters.type.forEach((v) => (params = params.append('activity_types[]', v)));
    if (filters && filters.date && filters.date.from) params = params.set('min_created_date', filters.date.from);
    if (filters && filters.date && filters.date.to) params = params.set('max_created_date', filters.date.to);

    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/units/${unitId}/event_logs`, {
        observe: 'response',
        params,
      })
      .pipe(
        tap((res) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(res.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(res.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalPages),
          };
          this.unitsLogsPagination$.next(pagination);
        }),
        map((res: any) => res.body.data.unit_event_logs),
        map((data: UnitEventLogModel[]) => plainToClass(UnitEventLogModel, data)),
      );
  }

  generateDualViewUrl(projectId: number, unitId: number): Observable<DualViewURLModel> {
    return this.http
      .post<{
        data: DualViewURLModel;
      }>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}/generate_sign_in_token`, {})
      .pipe(
        map((res: any) => res.data),
        map((data: DualViewURLModel) => plainToClass(DualViewURLModel, data)),
      );
  }

  downloadXlsUnitOption(projectId: number, layoutTypeId: number): Observable<FileDownloadModel> {
    return this.http
      .get(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types/${layoutTypeId}/layout_option/xls_document`,
      )
      .pipe(map((value) => plainToClass(FileDownloadModel, value)));
  }

  findMainOwner(
    units: (
      | UnitUserModel
      | OptionUnitModel
      | AdminUnitModel
      | ReclamationUnitModel
      | OptionWishlistModel
      | ChangeRequestsUnitModel
    )[],
    isException = false,
    withRole = false,
  ): (UnitUserModel | OptionUnitModel | AdminUnitModel | ReclamationUnitModel | any)[] {
    if (isException) {
      return units.map((unit) => {
        const noMainPrimaryOwnerCase = unit.coOwners.length ? unit.coOwners[0] : null;
        unit.mainPrimaryOwner = unit.mainPrimaryOwner ? unit.mainPrimaryOwner : noMainPrimaryOwnerCase;

        if (unit.mainPrimaryOwner && unit.mainPrimaryOwner.id) {
          if (withRole) {
            unit.mainPrimaryOwner.role = this.translateService.instant('Entity.Main_Owner_Buyer');
            unit.coOwners.forEach((coOwner) => {
              coOwner.role = this.translateService.instant('Entity.Owner');
            });
          }
          if (unit.coOwners.length) {
            unit.primaryOwners = [
              unit.mainPrimaryOwner,
              ...this.removeMainPrimaryOwner(unit.mainPrimaryOwner.id, unit.coOwners),
            ];
          } else {
            unit.primaryOwners = [unit.mainPrimaryOwner];
          }
        }
        return unit;
      });
    } else {
      return units.map((unit) => {
        const noMainPrimaryOwnerCase = unit?.coOwners?.length ? unit.coOwners[0] : null;
        unit.mainPrimaryOwner = unit.mainPrimaryOwner ? unit.mainPrimaryOwner : noMainPrimaryOwnerCase;

        if (unit.mainPrimaryOwner && unit.mainPrimaryOwner.id) {
          unit.primaryOwners = this.removeMainPrimaryOwner(unit.mainPrimaryOwner.id, unit.coOwners);
        }

        return unit;
      });
    }
  }

  removeMainPrimaryOwner(id: number | undefined, list: IUnitMainBuyer[]): IUnitMainBuyer[] {
    const foundIndex = list.findIndex((target) => target.id === id);
    if (foundIndex !== -1) {
      list.splice(foundIndex, 1);
    }
    return list;
  }

  clearUnitTypePagination(): void {
    this.unitTypesPagination$.next(null);
  }

  public importUnits(projectId: number, file: File, opt: string): Observable<any> {
    const params: HttpParams = new HttpParams().set('with_update', opt);

    const formData = new FormData();
    formData.append('filename', file);

    return this.http.post<{ data: any }>(
      `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/import`,
      formData,
      {
        params,
        reportProgress: true,
        observe: 'events',
      },
    );
  }

  public downloadSample(): Observable<void> {
    const lang = this.translateService.currentLang;

    let url = '';
    let filename = '';
    switch (lang) {
      case ELang.English:
        url = 'files/import_units_template_eng.xlsx';
        filename = 'import_units_template_eng.xlsx';
        break;
      case ELang.Norwegian:
        url = 'files/import_units_template_nor.xlsx';
        filename = 'import_units_template_nor.xlsx';
        break;
      case ELang.Swedish:
        url = 'files/import_units_template_sv.xlsx';
        filename = 'import_units_template_sv.xlsx';
        break;
    }
    return this.http.get(this.environment.apiBaseUrl + url, { responseType: 'blob' }).pipe(
      map((blob: Blob) => {
        const fileURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(fileURL);
      }),
    );
  }

  /**
   * Get Layout Types or Units
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Layout-Types/paths/~1api~1v1~1projects~1{project_id}~1layout_types~1units_groups_and_units/get
   */
  public getUnitsWithGroups(
    projectId: number,
    paginate: ITablePagination,
    search = '',
  ): Observable<ThreadUnitsWithGroupsModel[]> {
    const sort = 'default';
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (projectId) params = params.set('project_id', String(projectId));
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types/units_groups_and_units`, {
        params,
      })
      .pipe(
        map((res) => {
          return plainToClass(
            ThreadUnitsWithGroupsModel,
            <ThreadUnitsWithGroupsModel[]>res.data.layout_groups_or_units,
          );
        }),
      );
  }
}
