<div class="action-message-banner">
  <div *ngIf="closeButton" class="action-message-banner__close-wr" (click)="closeBanner()">
    <img width="16" height="16" src="/assets/action_message_close.svg" alt="close icon">
  </div>
  <div *ngIf="spinner" class="action-message-banner__close-wr">
    <span class="custom-spinner custom-spinner-12"></span>
  </div>
  <div *ngIf="icon" class="action-message-banner__close-wr">
    <img width="24" height="24" src="/assets/success-white.svg" alt="close icon">
  </div>
  <div class="action-message-banner__text-wr">
    {{messageText | translate}}
  </div>
  <div *ngIf="endCloseButton" class="action-message-banner__close-wr ml-4" (click)="closeBanner()">
    <img width="16" height="16" src="/assets/action_message_close.svg" alt="close icon">
  </div>
</div>
