import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  AdminProjectModel,
  FloorModel,
  ICreationList,
  IFloorType,
  IGroupChangeRequestsList,
  IMark,
  IMarkItems,
  ISettingsMenu,
  UnitFloorModel,
  UnitUserModel
} from "@atlas-workspace/shared/models";
import {
  ChangeRequestService,
  ModalFacadeService,
  ReclamationAdminService,
} from '@atlas-workspace/shared/service';
import {NgbModalRef, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep} from "lodash";
import {finalize, take} from "rxjs/operators";

import {GroupChangeRequestModalComponent} from "../group-change-request-modal/group-change-request-modal.component";

@UntilDestroy()
@Component({
  selector: 'atl-group-creator-modal',
  templateUrl: './group-creator-modal.component.html',
  styleUrls: ['./group-creator-modal.component.scss', '../../../styles/status.component.scss'],
  providers: [ReclamationAdminService, ChangeRequestService]
})
export class GroupCreatorModalComponent implements OnInit {
  @Input() modalRef!: NgbModalRef;
  @Input() projectId!: number;
  @Input() units: UnitUserModel[] = [];
  @Input() users: AdminProjectModel[] = [];
  @Input() selectedUnit?: UnitUserModel;
  @Input() unit?: UnitUserModel;

  public floorPlanData?: UnitFloorModel[] | FloorModel[];
  public unitSearch = '';
  public floorTabActiveId!: number;
  public navList!: ISettingsMenu[];
  public mark!: IMark;
  public previewPlan?: string;
  public lastOrder = 0;
  public changeRequestList: IGroupChangeRequestsList[] = [];
  public createLoading = false;
  public readonly tooltipDelay = 500;
  public floorLoading = false;

  private creationList: ICreationList[] = [];
  public isRedirect = false;
  public firstOnboarding = true;
  public isOnboarding = false;

  @ViewChild('ngbTooltip') ngbTooltipPin?: NgbTooltip;

  constructor(
    private readonly modalFacadeService: ModalFacadeService,
    private readonly translateService: TranslateService,
    private readonly reclamationService: ReclamationAdminService,
    private readonly changeRequestService: ChangeRequestService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.selectedUnit) {
      this.initFloorPlanData();
    }
  }

  public closeModal(): void {
    this.modalRef.dismiss();
  }

  public get titleModal(): string {
    return this.translateService.instant('Shared.Change_request.Creator.Title') + (this.selectedUnit ? ` - ${this.selectedUnit.identifier}` : '')
  }

  public selectUnit(unit: UnitUserModel): void {
    this.selectedUnit = unit;
    this.getUnitDetails(this.selectedUnit.id);
  }

  private getUnitDetails(id: number): void {
    this.reclamationService
      .getUnitDetails(this.projectId.toString(), id)
      .pipe(take(1))
      .subscribe((unit) => {
        this.unit = unit;
        this.initFloorPlanData();
      });
  }

  private initFloorPlanData(): void {
    if (this.unit!.hasOwnFloorPlans) {
      this.floorPlanData = this.unit!.unitFloors;
    } else {
      this.floorPlanData = this.unit!.floors;
    }
    this.initNavList();
  }

  public initOnboarding(): void {
    if (!this.firstOnboarding) return;
    this.firstOnboarding = false;
    this.isOnboarding = true;
    this.cdr.detectChanges();
    if (this.ngbTooltipPin) {
      this.ngbTooltipPin.open();
    }
  }

  public closeOnboarding(): void {
    this.ngbTooltipPin?.close();
    this.isOnboarding = false;
  }

  public get hasFloorPlans(): boolean {
    return !!this.floorPlanData?.filter((floor: UnitFloorModel) => !!floor?.plan).length;
  }

  public get unitFilter(): UnitUserModel[] {
    return this.units.filter(unit => unit.identifier.toLowerCase().includes(this.unitSearch.toLowerCase()));
  }

  public unitRedirect(): void {
    this.isRedirect = true;
    this.modalRef.dismiss();
  }

  public selectTab(event: { itemId: number }): void {
    this.floorTabActiveId = event.itemId;
    this.closeOnboarding();
    this.activePreviewData();
  }

  private initNavList(): void {
    this.navList = (this.floorPlanData || [])!.map(floor => {
      this.creationList.push({
        floorPlanId: floor.id,
        floorPlanData: cloneDeep(floor),
        markData: {
          floorId: floor.id,
          floorNumber: floor.floorNumber,
          floorType: floor,
          mark: []
        }
      })
      return {
        id: floor.id,
        name: floor.name,
        route: '',
        disabled: false,
        hasPlan: !!floor.plan,
      };
    });

    if (this.navList.length) {
      this.floorTabActiveId = this.navList[0].id as number;
      this.activePreviewData();
    }
    this.floorLoading = true;
  }

  private activePreviewData(): void {
    const floor = this.creationList.find(item => item.floorPlanId === this.floorTabActiveId);
    if (floor) {
      this.previewPlan = floor.floorPlanData.plan?.fullImage?.url;
      this.mark = {...floor.markData};
    }
  }

  public setMark(mark: IMark): void {
    this.isOnboarding = false;
    const floor = this.creationList.find(item => item.floorPlanId === this.floorTabActiveId);
    if (floor) {
      floor.markData.mark = floor.markData.mark.concat(mark.mark);
      this.lastOrderCalc();
      this.mark = {...floor.markData};
    }
    this.openCreateModal();
  }

  private lastOrderCalc(): void {
    this.lastOrder = this.creationList.reduce((acc, item) => {
      return acc += item.markData.mark.length;
    }, 0);
  }

  public remove(item: IGroupChangeRequestsList, e?: Event): void {
    e?.stopPropagation();
    this.changeRequestList = this.changeRequestList.filter(c => c.order !== item.order);
    this.changeRequestList.forEach(c => {
      if (c.order > item.order) {
        c.order--;
      }
    });
    this.creationList.forEach(floor => {
      floor.markData.mark = floor.markData.mark.filter(m => m.order !== item.order);
      floor.markData.mark.forEach(m => {
        if (m.order! > item.order) {
          m.order!--;
        }
      });
    });
    this.activePreviewData();
    this.lastOrderCalc();
  }

  private openCreateModal(): void {
    const floor = this.creationList.find(item => item.floorPlanId === this.floorTabActiveId);
    const modalRef = this.modalFacadeService.openModal(GroupChangeRequestModalComponent, {
      windowClass: 'change-request-group',
      backdropClass: 'transparent-overlay'
    });
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.projectId = this.projectId;
    modalRef.componentInstance.unit = cloneDeep(this.unit);
    modalRef.componentInstance.users = this.users;
    modalRef.componentInstance.activeFloorId = floor?.floorPlanData.floorNumber;

    modalRef.dismissed.pipe(untilDestroyed(this)).subscribe(value => {
      if (!value || value === 1) {
        if (floor) {
          floor.markData.mark.pop();
          this.lastOrderCalc();
          this.mark = {...floor.markData};
        }
        return;
      }
      this.changeRequestList.push({
        order: this.lastOrder,
        floorId: this.floorTabActiveId,
        ...cloneDeep(value)
      });
    });
  }

  openChangeRequestDetails(data: IGroupChangeRequestsList): void {
    this.openDetailModal(data);
  }

  public detailMark(mark: IMarkItems): void {
    const data = this.changeRequestList.find(item => item.order === mark.order);
    if (data) {
      this.openDetailModal(data);
    }
  }

  private openDetailModal(data: IGroupChangeRequestsList): void {
    const floor = this.creationList.find(item => item.floorPlanId === data.floorId);
    const modalRef = this.modalFacadeService.openModal(GroupChangeRequestModalComponent, {
      windowClass: 'change-request-group',
      backdropClass: 'transparent-overlay'
    });
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.projectId = this.projectId;
    modalRef.componentInstance.unit = cloneDeep(this.unit);
    modalRef.componentInstance.users = this.users;
    modalRef.componentInstance.editMode = true;
    modalRef.componentInstance.editData = data;
    modalRef.componentInstance.activeFloorId = floor?.floorPlanData.floorNumber;


    modalRef.dismissed.pipe(untilDestroyed(this)).subscribe(value => {
      if (value) {
        data = value;
      }
    });

    modalRef.componentInstance.remove.pipe(untilDestroyed(this)).subscribe(() => {
      modalRef.dismiss();
      this.remove(data);
    });
  }

  createRequest(): void {
    this.createLoading = true;
    const body = new FormData();

    this.changeRequestList.forEach((c) => {
      const markData = this.creationList.find(l => l.floorPlanId === c.floorId)?.markData;
      const { x, y } = markData?.mark.find(m => m.order === c.order) || {};

      body.append(`change_requests[][unit_id]`, String(this.unit?.id || ''));
      body.append(`change_requests[][description]`, c.data.description || '');
      body.append(`change_requests[][responsible_id]`, String(c.data.responsible[0]?.id || ''));
      body.append(`change_requests[][floor_id]`, String(markData?.floorId || ''));
      body.append(`change_requests[][floor_type]`, this.unit?.hasOwnFloorPlans ? IFloorType.UnitFloor : IFloorType.Floor);
      body.append(`change_requests[][point_x]`, String(x || ''));
      body.append(`change_requests[][point_y]`, String(y || ''));

      if (c.data.attachments.length) {
        c.data.attachments.forEach((file) => {
          body.append(`change_requests[][file_resources_attributes][][filename]`, file);
        });
      }

      if (c.drawingData.length) {
        body.append(`change_requests[][floor_draw_versions_attributes]`, JSON.stringify(c.drawingData));
      }
    });

    this.changeRequestService.groupCreation(this.projectId.toString(), body, this.unit!.id)
      .pipe(
        take(1),
        finalize(() => this.createLoading = false)
      )
      .subscribe(value => {
      this.changeRequestList = [];
      this.modalRef.dismiss(value);
    })
  }

  public clearSearch(): void {
    this.unitSearch = '';
  }
}
