import { Expose, Type } from 'class-transformer';

import { ENotificationStatus, ENotificationType } from './notification.enum';

export interface INotificationRequest {
  id: number;
  text: string;
  status: ENotificationStatus;
  type: ENotificationType;
  createdAt: string | Date;
  activitiableId: number;
  action: string;
}

export class NotificationModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'text' })
  text: string;
  @Expose({ name: 'status' })
  status: ENotificationStatus;
  @Expose({ name: 'type' })
  type: ENotificationType;
  @Expose({ name: 'created_at' })
  createdAt: string | Date;
  @Expose({ name: 'activitiable_id' })
  activitiableId: number;
  @Expose({ name: 'action' })
  action!: string;

  constructor() {
    this.id = 0;
    this.text = '';
    this.createdAt = new Date();
    this.status = ENotificationStatus.Read;
    this.type = ENotificationType.Document;
    this.activitiableId = 0;
  }
}

export type LinkRedirectType = {
  [key in ENotificationType]: string;
};

export const linkRedirect: Partial<LinkRedirectType> = {
  [ENotificationType.Illustration]: 'home',
  [ENotificationType.Phase]: 'home',
  [ENotificationType.Update]: 'update',
  [ENotificationType.Task]: 'tasks',
  [ENotificationType.Visual]: 'visuals',
  [ENotificationType.Document]: 'documents',
  [ENotificationType.PracticalInfo]: 'practical_info',
  [ENotificationType.Survey]: 'surveys',
  [ENotificationType.PriceOffer]: 'options',
  [ENotificationType.Meeting]: 'meetings',
  [ENotificationType.Reclamation]: 'reclamations',
  [ENotificationType.UpdatesUnit]: 'update',
  [ENotificationType.TasksUnit]: 'tasks',
  [ENotificationType.DocumentsUnit]: 'documents',
  [ENotificationType.ChangeRequest]: 'change-request',
  [ENotificationType.ChangeRequestGroup]: 'change-request',
  [ENotificationType.ChangeRequestCombinedOffer]: 'change-request',
};

export interface IActivityItem {
  type: ENotificationType;
  id: number;
  tab?: string;
}

export enum ENotificationChangeRequestAction {
  Created = 'create',
  NewNessage = 'new_message',
  RequestDeclined = 'request_declined',
  OfferCreated = 'offer_created',
  OfferStatusChanged = 'offer_status_changed',
  OfferExpirationDateChanged = 'offer_expiration_date_changed',
}

export class UnitSettingsModel {
  @Expose({ name: 'enable' })
  enable!: boolean;
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier!: string;
}

export class ClientNotifyResponseModel {
  @Expose({ name: 'allow_notifications_management' })
  allowNotifications!: boolean;
  @Expose({ name: 'mnt_notify_settings' })
  @Type(() => ClientNotifySettingsModel)
  notifySettings!: ClientNotifySettingsModel[];
}

export class ClientNotifySettingsModel {
  @Expose({ name: 'event_type' })
  eventType!: string;
  @Expose({ name: 'send_email' })
  sendEmail!: boolean;
  @Expose({ name: 'send_in_app' })
  sendInApp!: boolean;
  @Expose({ name: 'localized_event_type' })
  localizedEventType!: string;
  @Expose({ name: 'units' })
  @Type(() => UnitSettingsModel)
  units!: UnitSettingsModel[];
}
