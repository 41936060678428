import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EAdminUnitStatus } from '@atlas-workspace/shared/models';
import { TranslateModule } from '@ngx-translate/core';

import { DropdownItem } from '../../interface/dropdown-item';

@Component({
  selector: 'atl-custom-select-dropdown',
  templateUrl: './custom-select-dropdown.component.html',
  styleUrls: ['./custom-select-dropdown.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectDropdownComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSelectDropdownComponent implements OnChanges, ControlValueAccessor {
  @Input() label = 'Shared.Entity.Select_item';
  @Input() isWithoutBorder = false;
  @Input() isShowIcon = true;
  @Input() listOfValues!: DropdownItem[];
  @Input() disabled = false;
  @Input() placement: 'top' | 'bottom' = 'bottom';

  @ViewChild('containerValuesDropdown') private containerValuesDropdown!: ElementRef;

  isOpen = false;
  formValue!: string | number | DropdownItem | null;
  public onChange!: (value: string | number | DropdownItem | null) => void;
  public onTouched!: () => void;

  onCloseModal(): void {
    this.isOpen = false;
  }

  onOpenModal(): void {
    if (this.disabled) return;
    this.isOpen = !this.isOpen;
  }

  onChangeValue(newValue: DropdownItem): void {
    this.changeValue(newValue);
    this.onCloseModal();
  }

  changeValue(value: DropdownItem): void {
    this.label = value.title;
    this.formValue = value.name;
    this.onChange(value.name);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.formValue = value;
    this.label = this.formValue == EAdminUnitStatus.Completed ? 'Entity.Completed' : 'Entity.Not_completed';
  }

  ngOnChanges({ formValue }: SimpleChanges): void {
    if (formValue && formValue.currentValue) {
      const index = this.listOfValues.findIndex((item) => item.name === formValue.currentValue);
      this.formValue = index > -1 ? this.listOfValues[index].name : this.formValue;
    }
  }

  @HostListener('window:click', ['$event'])
  onListenClick(e: MouseEvent): void {
    if (this.containerValuesDropdown) {
      const target = e.target as Element;
      const classListLanguage = this.containerValuesDropdown.nativeElement.classList.value;
      if (
        /open/.test(classListLanguage) &&
        !target.classList.contains('custom-select-dropdown__item') &&
        !target.classList.contains('custom-select-dropdown__list')
      ) {
        this.onCloseModal();
      }
    }
  }
}
