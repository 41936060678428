import {HttpClient} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {IEnvironment} from "@atlas-workspace/shared/environments";
import {
  WhiteLabelSettingsFormKeys,
  WhiteLabelSettingsFormRawValue,
  WhiteLabelSettingsModel
} from '@atlas-workspace/shared/models';
import {plainToClass} from "class-transformer";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class WhiteLabelService {
  constructor(
    @Inject('ENVIRONMENT') private env: IEnvironment,
    private http: HttpClient
  ) {}


  /**
   * Get white label details
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Firm-White-Labels/paths/~1api~1v1~1firms~1%7Bfirm_id%7D~1white_label/get
   */
  public getWhiteLabelSettings(firmId: number): Observable<WhiteLabelSettingsModel | null> {
    return this.http
      .get<any>(`${this.env.apiBaseUrl}/api/v1/firms/${firmId}/white_label`)
      .pipe(
        map(res => res.data),
        map(data => plainToClass(WhiteLabelSettingsModel, data)),
      );
  }

  /**
   * Update white labels
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Firm-White-Labels/paths/~1api~1v1~1firms~1%7Bfirm_id%7D~1white_label/patch
   */
  updateWhiteLabelSettings(firmId: any, formRawValue: WhiteLabelSettingsFormRawValue): Observable<WhiteLabelSettingsModel> {
    const fd = new FormData();
    const setPrimitiveFormDataValue = (key: WhiteLabelSettingsFormKeys): void  =>
      fd.append(`white_label[${key}]`, String(formRawValue[key])
    );
    setPrimitiveFormDataValue(WhiteLabelSettingsFormKeys.AccentColor);
    setPrimitiveFormDataValue(WhiteLabelSettingsFormKeys.BorderColor);
    setPrimitiveFormDataValue(WhiteLabelSettingsFormKeys.TextColor);
    setPrimitiveFormDataValue(WhiteLabelSettingsFormKeys.BackgroundColor);
    setPrimitiveFormDataValue(WhiteLabelSettingsFormKeys.ButtonTextColor);
    setPrimitiveFormDataValue(WhiteLabelSettingsFormKeys.ButtonCornerRadius);
    if (formRawValue[WhiteLabelSettingsFormKeys.LogoFile]) {
      fd.append('white_label[logo_attributes][filename]', formRawValue[WhiteLabelSettingsFormKeys.LogoFile]);
    }
    return this.http
      .patch<any>(`${this.env.apiBaseUrl}/api/v1/firms/${firmId}/white_label`, fd)
      .pipe(
        map(res => res.data),
        map(data => plainToClass(WhiteLabelSettingsModel, data)),
      );
  }
}
