<div class="color__picker-wr position-relative">
  <svg class="color__picker-preview position-absolute" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="12" r="11" [attr.fill]="previewColor" [attr.stroke]="previewBorderColor"/>
  </svg>

  <input
    #input
    class="color__picker-input w-100 global_input br-8 fz-16 lh-24 fw-400"
    type="text"
    spellcheck="false"
    [ngModel]="value"
    [disabled]="disabled"
    [mask]="mask"
    [patterns]="maskPattern"
    [dropSpecialCharacters]="false"
    (blur)="onBlur()"
    (input)="changeValue(input.value)"
  />

<!--  TODO: to be announced -->
<!--  TODO: https://www.npmjs.com/package/ngx-color-picker/v/16.0.0 -->
</div>
