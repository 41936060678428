<atl-ngb-modal-header
  [title]="titleModal"
  (closeModalEvent)="closeModal()"
>
</atl-ngb-modal-header>

<div class="group-creator">
  <ng-container *ngIf="selectedUnit">
    <ng-container *ngIf="floorLoading; else spinner">
      <ng-container *ngIf="hasFloorPlans">
        <ng-container *ngTemplateOutlet="creator"></ng-container>
      </ng-container>
      <ng-container *ngIf="!hasFloorPlans">
        <ng-container *ngTemplateOutlet="noFloors"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!selectedUnit">
    <ng-container *ngTemplateOutlet="startView"></ng-container>
  </ng-container>
</div>

<ng-template #creator>
  <div class="group-creator__creation">
    <div class="group-creator__creation-floors">
      <atl-simple-tabs
        *ngIf="navList.length > 1"
        [withId]="true"
        [activeId]="floorTabActiveId"
        [tabs]="navList"
        [routeNavigation]="false"
        (selectingTab)="selectTab($event)"
        [scrollControl]="true"
        class="newest-tabs"
      ></atl-simple-tabs>
      <atl-image-mark
        *ngIf="previewPlan; else noUnitFloors"
        [mark]="mark"
        [imageUrl]="previewPlan"
        [multiplePins]="true"
        [floorId]="floorTabActiveId"
        [lastOrder]="lastOrder"
        (setMark)="setMark($event)"
        (existingMarkClick)="detailMark($event)"
        (imageLoad)="initOnboarding()"
        class="v-scrollbar h-scrollbar"
      ></atl-image-mark>
      <img
        *ngIf="isOnboarding"
        #ngbTooltip='ngbTooltip'
        src="assets/onboarding-pin.svg"
        class="onboarding"
        alt="pin"
        [ngbTooltip]="pinBanner"
        placement="top"
        triggers="click touchstart"
        [autoClose]="false"
        tooltipClass="onboarding"
      />
    </div>
    <div class="group-creator__creation-table">
      <div class="group-creator__list mb-24">
        <span class="d-flex align-items-center fz-20 lh-24 fw-500 mb-24">
          {{'Title.Change_requests' | translate}}
          <span *ngIf="changeRequestList.length" class="group-creator__count">{{changeRequestList.length}}</span>
        </span>
        <atl-shared-table *ngIf="changeRequestList.length; else emptyList" class="gl-shared-table--lv requests__table table-action">
          <ng-container ngProjectAs="shared-table-header">
            <th
              atlColumnDef="unit_identifier"
              class="shared-table__header-cell w87"
              atl-sort-header
            >
              <div>ID</div>
            </th>
          </ng-container>
          <ng-container ngProjectAs="shared-table-body">
            <tr
              *ngFor="let item of changeRequestList; index as i"
              (click)="openChangeRequestDetails(item)"
            >
              <td class="shared-table__body-cell">
                <div class="gl-ellipsis-table-wr">
                  <div class="d-flex align-items-center gap-16 w-100">
                    <div class="d-flex align-items-center gap-16 ellipsis w-100">
                      <span class="fz-14 lh-24 fw-400">{{ item.order | number: '2.0-0' }}</span>
                      <span
                        #identifierAndDescription
                        class="description fz-14 lh-24 fw-400 ellipsis text-gray-890"
                        [ngbTooltip]="item.data.description"
                        [openDelay]="tooltipDelay"
                        [disableTooltip]="identifierAndDescription.offsetWidth >= identifierAndDescription.scrollWidth"
                        triggers="hover touchstart"
                      >
                        {{ item.data.description }}
                      </span>
                    </div>
                    <span
                      (click)="remove(item, $event)"
                      class="remove-button"
                      [ngbTooltip]="'Shared.Button.Delete' | translate"
                      [openDelay]="tooltipDelay"
                      [container]="'body'"
                      triggers="hover touchstart"
                    >
                      <img src="assets/trash-gray.svg" alt="remove">
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </atl-shared-table>
      </div>
      <atl-ngb-modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <button
            data-cy="cy-cancel-change-request-btn"
            (click)="closeModal()"
            class="newest-button button-gray fz-16 mr-16 br-32"
          >
            {{ 'Button.Cancel' | translate }}
          </button>
          <atl-button-loader
            data-cy="cy-create-change-request-btn"
            (clickButton)="createRequest()"
            [disabled]="!changeRequestList.length"
            [newest]="true"
            [loading]="createLoading"
            label="Button.Create_change_requests"
          ></atl-button-loader>
        </div>
      </atl-ngb-modal-footer>
    </div>
  </div>
</ng-template>

<ng-template #noFloors>
  <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
    <h3 class="fz-20 lh-24 fw-500 mb-8">{{'Shared.Entity.Floor_plan' | translate}}</h3>
    <p class="fz-16 lh-24 text-gray-890 text-align-center max-w-555">
      {{ 'Shared.Mark.Message.No_floor_plan' | translate }}
      <span (click)="unitRedirect()" class="cursor-pointer text-blue-980">{{ 'Shared.Mark.Message.Unit_details_page' | translate }}</span>
    </p>
  </div>
</ng-template>

<ng-template #noUnitFloors>
  <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
    <h3 class="fz-20 lh-24 fw-500 mb-8">{{'Shared.Mark.Message.No_floor_plan_available' | translate}}</h3>
    <p class="fz-16 lh-24 text-gray-890 text-align-center max-w-555">
      {{ 'Shared.Mark.Message.No_floor_plan' | translate }}
      <span (click)="unitRedirect()" class="cursor-pointer text-blue-980">{{ 'Shared.Mark.Message.Unit_details_page' | translate }}</span>
    </p>
  </div>
</ng-template>

<ng-template #startView>
  <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
    <img class="mb-16" src="/assets/group-creator.svg" alt="icon">
    <h3 class="fz-20 lh-24 fw-500 mb-8">{{'Shared.Title.No_unit_selected' | translate}}</h3>
    <p class="fz-16 lh-24 text-gray-890 text-align-center mb-32">
      {{'Shared.Change_request.Creator.Start.Description' | translate}}
    </p>
    <atl-round-dropdown class="group-creator__dropdown">
      <ng-container ngProjectAs="[value]">
        <ng-container *ngIf="selectedUnit; else emptyValue">
          {{ selectedUnit.identifier }}
        </ng-container>
        <ng-template #emptyValue>
          <span class="fz-14 lh-24 text-gray-890">{{'Shared.Placeholder.Select_unit' | translate}}</span>
        </ng-template>
      </ng-container>
      <ng-container ngProjectAs="[search]">
        <div class="search mb-8">
          <img src="/assets/search.svg" alt="icon" class="search-icon">
          <input [(ngModel)]="unitSearch" class="search-input" [placeholder]="'Shared.Entity.Search' | translate">
          <img *ngIf="unitSearch" (click)="clearSearch()" src="/assets/input_search_clear.svg" alt="icon" class="search-clear">
        </div>
      </ng-container>
      <ng-container *ngFor="let unit of unitFilter" ngProjectAs="[item]">
        <div ngbDropdownItem (click)="selectUnit(unit)" [class.active]="selectedUnit?.id === unit.id">
          <span>{{ unit.identifier }}</span>
          <img src="/assets/multiple_select_checkbox.svg" alt="Check state" />
        </div>
      </ng-container>
      <ng-container ngProjectAs="[empty]">
        <div *ngIf="!unitFilter.length" class="search">
          <span class="fz-14 lh-32 text-gray-890 ml-8">{{'Shared.Entity.Not_found' | translate}}</span>
        </div>
      </ng-container>
    </atl-round-dropdown>
  </div>
</ng-template>

<ng-template #emptyList>
  <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
    <img class="mb-16" src="assets/empty-change-request.svg" alt="icon">
    <h3 class="fz-20 lh-24 fw-500 mb-8">{{'Title.No_change_requests' | translate}}</h3>
    <p class="fz-16 lh-24 text-gray-890 text-align-center mb-32">
      {{'Desription.Add_first_change_request' | translate}}
    </p>
  </div>
</ng-template>

<ng-template #spinner>
  <div class="spinner-wrapper project-wrapper">
    <span class="custom-spinner custom-spinner-48"></span>
  </div>
</ng-template>

<ng-template #pinBanner>
  <div class="onboarding-banner d-flex flex-column min-h-0">
    <atl-ngb-modal-header
      [title]="'Shared.Change_request.Onboarding.Creator.Title' | translate"
      (closeModalEvent)="closeOnboarding()"
    ></atl-ngb-modal-header>
    <div class="onboarding-banner__main">
      {{'Shared.Change_request.Onboarding.Creator.Description' | translate}}
    </div>
  </div>
</ng-template>
