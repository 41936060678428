import { Expose, Transform, Type } from 'class-transformer';

import { FileModel } from './file.model';
import { PreviewModel } from './product.model';


export enum EPriceOfferItemStatus {
  Offered = 'offered',
  Accepted = 'accepted',
  Requested  = 'requested',
  Declined = 'declined',
  Expired = 'expired',
  Cancelled = 'cancelled'
}

interface Owner {
  id: number;
  name: string;
  email: string;
  type: string;
}

class LayoutOptionCategory {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
}

 class LayoutProductTypeItem {
  @Expose({ name: 'id' })
  id!: number;

  @Expose({ name: 'name' })
  name!: string;

  @Expose({ name: 'layout_option_category' })
  @Type(() => LayoutOptionCategory)
  layoutOptionCategory!: LayoutOptionCategory;
}

export class LayoutProductTypeItemModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'free_text' }) freeText!: string | null;
  @Expose({ name: 'title' }) title!: string;

  @Expose()
  @Type(() => PreviewModel)
  @Transform(({ value }) => value?.filename?.versions?.w260?.url || 'assets/no_images.png', { toClassOnly: true })
  preview!: string;

  @Expose({ name: 'layout_product_type' })
  @Type(() => LayoutProductTypeItem)
  layoutProductType?: LayoutProductTypeItem;
}

export class PriceOfferItemModel {
  @Expose({ name: 'layout_product_type_item' })
  @Type(() => LayoutProductTypeItemModel)
  layoutProductTypeItem!: LayoutProductTypeItemModel;

  @Expose({ name: 'wishlist_item_id' })
  wishlistItemId!: number;
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'visible_for_client' }) visibleForClient!: boolean;
  @Expose({ name: 'active_offer' }) activeOffer!: boolean;
  @Expose({ name: 'title' })
  title!: string;
  @Expose({ name: 'product_type_name' })
  productTypeName!: string;
  @Expose({ name: 'unit_identifier' })
  unitIdentifier!: string;
  @Expose({ name: 'allow_free_text' })
  allowFreeText!: boolean;
  @Expose({ name: 'free_text' })
  freeText!: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'due_date' })
  dueDate!: string;
  @Expose({ name: 'price' })
  price!: number | null;
  @Expose({ name: 'currency' })
  currency!: string;
  @Type(() => FileModel)
  @Expose({ name: 'preview' })
  preview!: FileModel;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'primary_owner' })
  owner!: Owner;

  @Expose({ name: 'layout_product_type_item_id' }) layoutProductTypeItemId!: number;

  @Expose({ name: 'unit' })
  unit!: {
    id: number,
    identifier: string
  };

  @Expose({ name: 'localized_status' }) localizedStatus!: string;

  @Expose({ name: 'price_requested' }) priceRequested!: boolean;

  @Expose({ name: 'status' }) status!: EPriceOfferItemStatus;
  statusClass!: string;
  statusText!: string;


}
